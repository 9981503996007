import { makeAutoObservable } from "mobx";
import { fetchFilings } from "../services/api";

class RegistrationsStore {
  registrationsData = [];
  page = 1;
  total = 0;
  filter = { year: "", month: "", formType: "" };
  loading = false;
  paginating = false;
  hasMoreData = true;
  currentlyOpenedFilter = "";
  cik = "";

  constructor() {
    makeAutoObservable(this);
  }

  setCik(cik) {
    this.cik = cik;
    this.resetState();
  }

  resetState() {
    this.registrationsData = [];
    this.page = 1;
    this.total = 0;
    this.filter = { year: "", month: "", formType: "" };
    this.hasMoreData = true;
    this.fetchRegistrationsData();
  }

  setCurrentlyOpenedFilter(name) {
    this.currentlyOpenedFilter = name;
  }

  async fetchRegistrationsData() {
    this.loading = true;
    let combinedData = [];
    let total = 0;

    try {
      for (let i = 1; i <= this.page; i++) {
        const registrations = await fetchFilings(
          this.cik,
          "registrations",
          i,
          this.filter.year,
          this.filter.month,
          this.filter.formType
        );
        if (registrations && registrations.data) {
          combinedData = [...combinedData, ...registrations.data];
          total = registrations.total;
        }
      }
      this.registrationsData = combinedData;
      this.total = total;
      this.hasMoreData = combinedData.length < total;
    } catch (error) {
      console.error("Error fetching registrations data:", error);
      this.registrationsData = [];
      this.total = 0;
      this.hasMoreData = false;
    } finally {
      this.loading = false;
    }
  }

  async handleFilterChange(name, value) {
    this.loading = true;
    const updatedFilter = { ...this.filter, [name]: value };
    if (name === "year" && value === "") {
      updatedFilter.month = "";
    }
    this.filter = updatedFilter;
    this.page = 1;

    try {
      const registrations = await fetchFilings(
        this.cik,
        "registrations",
        1,
        this.filter.year,
        this.filter.month,
        this.filter.formType
      );
      if (registrations && registrations.data) {
        this.registrationsData = registrations.data;
        this.total = registrations.total;
        this.hasMoreData = registrations.data.length < registrations.total;
      } else {
        this.registrationsData = [];
        this.total = 0;
        this.hasMoreData = false;
      }
    } catch (error) {
      console.error("Error fetching filtered registrations data:", error);
      this.registrationsData = [];
      this.total = 0;
      this.hasMoreData = false;
    } finally {
      this.loading = false;
    }
  }

  async loadMore() {
    this.paginating = true;
    const nextPage = this.page + 1;
    try {
      const registrations = await fetchFilings(
        this.cik,
        "registrations",
        nextPage,
        this.filter.year,
        this.filter.month,
        this.filter.formType
      );
      if (registrations && registrations.data.length > 0) {
        this.registrationsData = [
          ...this.registrationsData,
          ...registrations.data,
        ];
        this.page = nextPage;
        this.hasMoreData = registrations.data.length < registrations.total;
      } else {
        this.hasMoreData = false;
      }
    } catch (error) {
      console.error("Error loading more registrations data:", error);
      this.hasMoreData = false;
    } finally {
      this.paginating = false;
    }
  }
}

const registrationsStore = new RegistrationsStore();
export default registrationsStore;
