import React from "react";
import { useParams, useOutletContext } from "react-router-dom";
import "../styles/financialData.css";
import SearchBar from "./SearchBar";
import NewsCard from "./NewsCard";
import LogoWithMenuToggle from "./LogoWithMenuToggle";
import { observer } from "mobx-react";

const NewsPage = observer(() => {
  const { cik } = useParams(); // Get CIK from URL
  const [financialData, navigationBarStore] = useOutletContext();

  return (
    <>
      <div>
        <span className="flex items-center gap-4 mb-2">
          <LogoWithMenuToggle navigationBarStore={navigationBarStore} />
          <div className="search-bar-container medium-large:flex block justify-end w-full">
            <SearchBar />
          </div>
        </span>
        <NewsCard cik={cik} financialData={financialData} />
      </div>
    </>
  );
});

export default NewsPage;
