import React, { useState, useEffect, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { fetchData } from "../services/api";
import SearchBarResults from "./SearchBarResults";
import useDebounce from "./useDebounce"; // Import the debounce hook
import "../styles/searchbar.css";
import LoadingBar from "./LoadingBar";

const SearchBar = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const location = useLocation();
  const debouncedQuery = useDebounce(query, 300); // Use the debounce hook
  const [isLoading, setIsLoading] = useState(false);
  const [searchBarOpened, setSearchBarOpened] = useState(false);
  const searchBarRef = useRef();

  useEffect(() => {
    console.log(debouncedQuery);
    if (debouncedQuery) {
      fetchData(debouncedQuery).then((data) => {
        setResults(data);
        setIsLoading(false);
      });
    } else {
      setResults([]);
      setIsLoading(false);
    }
  }, [debouncedQuery]);

  console.log(isLoading);

  // Clear search bar and results when navigating to a new page
  useEffect(() => {
    setQuery("");
    setResults([]);
    setSearchBarOpened(false);
  }, [location]);

  useEffect(() => {
    // Handle clicking outside the dropdown contents to close dropdown
    const handleClickOutsideDropdown = (e) => {
      if (searchBarRef.current && !searchBarRef.current.contains(e.target)) {
        setSearchBarOpened(false); // Close the dropdown
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDropdown);

    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  return (
    <div
      className="relative"
      ref={searchBarRef}
      // onMouseOver={() => !searchBarOpened && setSearchBarOpened(true)}
    >
      <div className="search-bar">
        <input
          className="search-input medium-large:w-72 w-full"
          placeholder="Search for Ticker"
          value={query}
          onChange={(e) => {
            setIsLoading(true);
            setQuery(e.target.value);
          }}
          onClick={() => !searchBarOpened && setSearchBarOpened(true)}
        />
        <div id="search-icon-container">
          <FaSearch id="search-icon" />
        </div>
      </div>
      {!isLoading ? (
        <SearchBarResults
          results={results}
          query={query}
          searchBarOpened={searchBarOpened}
          isLoading={isLoading}
        />
      ) : (
        <div
          className="absolute top-[125%]"
          style={{ width: "-webkit-fill-available" }}
        >
          <LoadingBar />
        </div>
      )}
    </div>
  );
};

export default SearchBar;
