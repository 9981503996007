import React, { useEffect, useRef } from "react";

const GoogleMapComponent = ({ address, apiKey }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const loadMap = () => {
      if (!window.google || !window.google.maps) {
        console.error("Google Maps JavaScript API not loaded.");
        return;
      }

      const geocoder = new window.google.maps.Geocoder();
      console.log("Geocoding address: ", address);
      geocoder.geocode({ address }, (results, status) => {
        if (status === "OK") {
          const map = new window.google.maps.Map(mapRef.current, {
            center: results[0].geometry.location,
            zoom: 15,
          });

          new window.google.maps.Marker({
            position: results[0].geometry.location,
            map,
          });
        } else {
          console.error(
            `Geocode was not successful for the following reason: ${status}`
          );
        }
      });
    };

    if (window.google && window.google.maps) {
      loadMap();
    } else {
      window.initMap = loadMap;
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC-Y6adOuhrcIH5uibehJemIyb_OO_8SH4&callback=initMap`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        loadMap();
      };
      document.head.appendChild(script);
    }
  }, [address, apiKey]);

  return (
    <div
      ref={mapRef}
      style={{
        height: "250px",
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
      }}
    />
  );
};

export default GoogleMapComponent;
