import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import "../styles/searchfilter.css";

export default function SearchableFilterButton({
  name,
  values,
  value,
  clearValue,
  handleFilterChange,
  currentlyOpenedFilter,
  setCurrentlyOpenedFilter,
  disabled,
  showPrompt,
  setShowPrompt,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [scrolling, setScrolling] = useState(false);
  const scrollTimeoutRef = useRef(null);

  const filteredValues = values
    .filter((val) => val.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => {
      const lowerSearchTerm = searchTerm.toLowerCase();
      const aLower = a.toLowerCase();
      const bLower = b.toLowerCase();

      if (aLower === lowerSearchTerm) return -1; // Exact match comes first
      if (bLower === lowerSearchTerm) return 1;

      if (
        aLower.startsWith(lowerSearchTerm) &&
        !bLower.startsWith(lowerSearchTerm)
      )
        return -1; // Starts with search term comes next
      if (
        !aLower.startsWith(lowerSearchTerm) &&
        bLower.startsWith(lowerSearchTerm)
      )
        return 1;

      return 0; // Any other matches come later
    });

  const handleScroll = () => {
    setScrolling(true);
    clearTimeout(scrollTimeoutRef.current);
    scrollTimeoutRef.current = setTimeout(() => {
      setScrolling(false);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(scrollTimeoutRef.current);
    };
  }, []);

  return (
    <div
      className={`relative ${
        currentlyOpenedFilter === name
          ? "bg-transparent shadow-inner-purple-outline text-lavender-primary"
          : "bg-lavender-primary text-white-primary"
      } rounded-2xl px-[16px] py-[10px] flex items-center justify-center gap-2 `}
    >
      {value && (
        <button
          className="text-salmon"
          onClick={() => {
            clearValue();
            setCurrentlyOpenedFilter("");
          }}
        >
          <IoClose />
        </button>
      )}
      <button
        className={`font-primary bg-transparent font-semibold tracking-wider whitespace-nowrap flex items-center gap-1 sm:text-sm text-[10px] ${
          !value && "uppercase"
        }`}
        onClick={() => {
          if (disabled) {
            setShowPrompt(true);
            setTimeout(() => setShowPrompt(false), 2000);
          } else {
            setCurrentlyOpenedFilter((prev) => (prev === name ? "" : name));
          }
        }}
      >
        {value ? value : name}
        {currentlyOpenedFilter === name ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </button>
      {showPrompt && disabled && (
        <div className="absolute top-[-30px] left-0 right-0 bg-salmon text-white text-center p-1 rounded">
          You must select year first
        </div>
      )}
      <section
        className={`absolute w-[200px] max-h-[150px] overflow-hidden bg-white-primary drop-shadow-subtle-soft rounded-2xl right-0 top-12 flex-col ${
          currentlyOpenedFilter === name ? "flex" : "hidden"
        }`}
      >
        <input
          type="text"
          placeholder={`Search`}
          className="w-full px-4 py-2 border-b font-primary"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <span
          className={`overflow-y-scroll custom-scrollbar ${
            scrolling ? "scrolling" : ""
          }`}
          onScroll={handleScroll}
        >
          {filteredValues.map((value, index) => (
            <button
              key={index}
              className="text-black-primary w-full text-start px-4 py-0.5 hover:bg-lavender-smTint font-primary font-medium transition-all whitespace-nowrap"
              onClick={async (e) => {
                console.log(name, value);
                setCurrentlyOpenedFilter("");
                await handleFilterChange({ name: name, value: value });
              }}
            >
              {value}
            </button>
          ))}
        </span>
      </section>
    </div>
  );
}
