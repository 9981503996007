import React, { useEffect, useState } from "react";
import {
  useParams,
  Navigate,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import "./styles/LoadingPage.css";
import FinancialData from "./components/FinancialData";
import RootLayout from "./components/RootLayout";
import UserSettings from "./components/UserSettings";
import ProxiesPage from "./components/ProxiesPage";
import NewsPage from "./components/NewsPage";
import OwnershipPage from "./components/OwnershipPage";
import RegistrationsPage from "./components/RegistrationsPage";
import PersistentLayout from "./components/PersistentLayout";
import AllFilingsPage from "./components/AllFilingsPage";
import InsiderPage from "./components/InsiderPage";
import DocumentsNew from "./components/DocumentsNew";
import EarningsDetailNew from "./components/EarningsDetailNew";
import EarningsPage from "./components/EarningsPage";
import Settings from "./components/Settings";
import ProfileLayout from "./components/ProfileLayout";
import SearchResultsPage from "./components/SearchResultsPage";
import ScreenerSetup from "./components/ScreenerSetup";
import Login from "./components/Login";
import Screeners from "./components/Screeners";
import financialStore from "./stores/financialStore"; // Assuming you're using MobX for this store now
import { observer } from "mobx-react";
import { getUserInfo, fetchFinancials } from "./services/api";
import userInfoStore from "./stores/userInfoStore";
import AlertSetup from "./components/AlertSetup";
import Alerts from "./components/Alerts";
import Notifications from "./components/Notifications";
import Error404Page from "./components/Error404Page";
import Error500Page from "./components/Error500Page";
import RecentlyViewed from "./components/RecentlyViewed";
import LoadingSpinner from "./components/LoadingSpinner";
import ActivityLayout from "./components/ActivityLayout";
import MostRecentFiling from "./components/MostRecentFiling";

// Define ConditionalRedirect component
const ConditionalRedirect = observer(({ cik }) => {
  const { cik: paramsCik } = useParams();
  const finalCik = cik || paramsCik;
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    let isComponentMounted = true;

    const fetchData = async () => {
      try {
        // Start data fetching
        const data = await fetchFinancials(finalCik);

        if (isComponentMounted) {
          // If data is found and component is still mounted
          if (data) {
            financialStore.updateFinancialData(data);
            setRedirect(`/${finalCik}/financials`);
          } else {
            // If no data, set redirect to 404
            setRedirect(`/404`);
          }
        }
      } catch (error) {
        if (isComponentMounted) {
          // On error, redirect to 404
          setRedirect(`/404`);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      // Cleanup: avoid state updates if the component is unmounted
      isComponentMounted = false;
    };
  }, [finalCik]);

  if (loading) {
    return (
      <div className="loading-page-container">
        <LoadingSpinner />
      </div>
    );
  }

  return redirect ? <Navigate to={redirect} replace /> : null;
});

// Define App component
const App = () => {
  // const isMobile =
  //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //     navigator.userAgent
  //   );

  // if (isMobile) {
  //   window.location.href = "https://unlevered.ai/mobile/";
  // }

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await getUserInfo();
      if (userInfo?.name) {
        userInfoStore.updateUserInfo(userInfo);
      }
    };
    fetchUserInfo();
  }, []);

  return (
    <Router>
      <div className="App wrapper">
        <Routes>
          <Route element={<RootLayout />}>
            <Route path="/login" element={<Login />} />
            <Route element={<ProfileLayout />}>
              <Route path="/settings" element={<Settings />} />
              {/* <Route path="/pricing" element={<Pricing />} /> */}
            </Route>
            <Route element={<ActivityLayout />}>
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/recent" element={<RecentlyViewed />} />
              <Route path="/screeners" element={<Screeners />} />
              <Route path="/screeners-setup" element={<ScreenerSetup />} />
              <Route path="/alerts" element={<Alerts />} />
              <Route path="/alerts-setup" element={<AlertSetup />} />
            </Route>
            <Route element={<PersistentLayout />}>
              <Route path="/" element={<MostRecentFiling />} />
              <Route path="/financials" element={<MostRecentFiling />} />
              <Route path="/:cik/financials" element={<FinancialData />} />
              <Route path="/:cik/proxies" element={<ProxiesPage />} />
              <Route path="/:cik/news" element={<NewsPage />} />
              <Route path="/:cik/ownership" element={<OwnershipPage />} />
              <Route
                path="/:cik/registrations"
                element={<RegistrationsPage />}
              />
              <Route path="/:cik/all-filings" element={<AllFilingsPage />} />
              <Route path="/:cik/insider" element={<InsiderPage />} />
              <Route path="/user-settings" element={<UserSettings />} />
              <Route
                path="/:cik/earnings-transcripts-list/:year"
                element={<EarningsPage />}
              />

              {/* Redirect from <slug>/cik to <slug>/cik/financials */}
              <Route path="/:cik" element={<ConditionalRedirect />} />
            </Route>
            <Route
              path="/:cik/search-results"
              element={<SearchResultsPage />}
            />

            <Route
              path="/:cik/documents/:accessionNo"
              element={<DocumentsNew />}
            />
            <Route
              path="/:cik/earnings/:accessionNo"
              element={<EarningsDetailNew />}
            />
          </Route>
          <Route path="/500" element={<Error500Page />} />
          <Route path="*" element={<Error404Page />} />
          <Route path="/404" element={<Error404Page />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
