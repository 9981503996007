import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GoogleMapComponent from "./GoogleMapComponent";
import PlaceholderProfile from "../images/placeholderprofile.png";
import LoadingSpinner from "./LoadingSpinner";
import LoadingBar from "./LoadingBar";
import { FaSearch } from "react-icons/fa";
import { sectorIndustries } from "../constants";
import { observer } from "mobx-react";
import navigationBarStore from "../stores/navigationBarStore";
import "../styles/Sidebar.css";

const SideBar = observer(
  ({
    navigationLinks,
    financialStore,
    isLoading,
    includesSearch,
    isFullScreen,
    collapsed,
    setCollapsed,
    setActiveTab, // Optional prop
    hideHeaderNew, // Function to hide HeaderNew
  }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { cik } = useParams();
    const [companyDropdownInfo, setCompanyDropdownInfo] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const dropdownRef = useRef();

    const [isMediumLarge, setIsMediumLarge] = useState(
      window.innerWidth <= 896
    );

    const [isCategorizedOpen, setIsCategorizedOpen] = useState(true);
    const [isFilingsOpen, setIsFilingsOpen] = useState(true); // Default to open

    const uri = `${location.pathname}${location.search}${location.hash}`;
    const isDocumentsPage = uri.includes("/documents");

    useEffect(() => {
      // Update screen size on window resize
      const handleResize = () => {
        setIsMediumLarge(window.innerWidth <= 896);
      };
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const handleInputChange = (e) => {
      setSearchTerm(e.target.value);
    };

    const handleNewSearchClick = () => {
      if (searchTerm && financialStore.financialData?.cik) {
        const cik = financialStore.financialData.cik;
        navigate(
          `/${cik}/search-results?cik=${encodeURIComponent(
            cik
          )}&phrase=${encodeURIComponent(searchTerm)}`
        );
      }
      handleNavigationClose();
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        handleNewSearchClick();
      }
    };

    const checkActiveLink = (navigationLink) => {
      const active = navigationLink.exact
        ? uri === navigationLink.link
        : uri.includes(navigationLink.link);
      return active ? "bg-lavender-smTint" : "hover:bg-white-smShade";
    };

    const scrollToSection = (sectionId) => {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };

    const buildHref = (link) => {
      if (!link) {
        return "#";
      }

      if (link.startsWith("/documents")) {
        return `/${cik}${link}`;
      }

      return link;
    };

    const toggleDropdown = (dropdown) => {
      if (dropdown === "Categorized") {
        setIsCategorizedOpen(!isCategorizedOpen);
      } else if (dropdown === "Filings") {
        setIsFilingsOpen(!isFilingsOpen);
      }
    };

    // Handle filing click to reset to the "filing" tab, but only if setActiveTab is provided
    const handleFilingClick = (link) => {
      if (setActiveTab) {
        setActiveTab("filing"); // Reset the active tab to "filing" only if setActiveTab is available
      }
      navigate(buildHref(link), { replace: true });
    };

    const handleNavigationClose = () => {
      if (window.innerWidth < 898) {
        navigationBarStore.toggleIsOpen();
      }
    };

    useEffect(() => {
      // Handle clicking outside the dropdown contents to close dropdown
      const handleClickOutsideDropdown = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
          setCompanyDropdownInfo(false); // Close the dropdown
        }
      };
      document.addEventListener("mousedown", handleClickOutsideDropdown);

      return () => {
        // Clean up the event listener on unmount
        document.removeEventListener("mousedown", handleClickOutsideDropdown);
      };
    }, []);

    const handleToggleSidebar = () => {
      // Ensure dropdown closes when the sidebar is toggled
      setCompanyDropdownInfo(false);

      // Toggle the sidebar's collapsed state
      setCollapsed(!collapsed);

      // Optionally hide the header if the sidebar is collapsing on smaller screens
      if (isMediumLarge && !collapsed) {
        hideHeaderNew();
      }
    };
    return (
      <div
        key={cik}
        className={`flex flex-col items-center text-black-xsTint ${
          collapsed ? "w-0" : "w-[215px]"
        } h-full bg-white-xsShade drop-shadow-navigation-bar relative z-20 transition-all duration-300`}
      >
        {/* Show FaChevronLeft to hide sidebar on smaller screens */}
        {isMediumLarge && !collapsed && (
          <div
            onClick={handleToggleSidebar}
            className={`absolute top-4 right-[-16px] bg-white-xsShade p-2 rounded-full shadow-lg cursor-pointer`}
          >
            <FaChevronLeft />
          </div>
        )}

        {/* Show FaChevronRight only on documents page and larger screens */}
        {isDocumentsPage && !isMediumLarge && (
          <div
            onClick={handleToggleSidebar}
            className={`absolute top-4 right-[-16px] transform ${
              collapsed ? "translate-x-full" : ""
            } bg-white-xsShade p-2 rounded-full shadow-lg cursor-pointer z-50 flex items-center justify-center`}
          >
            {collapsed ? <FaChevronRight /> : <FaChevronLeft />}
          </div>
        )}

        {!collapsed &&
          cik &&
          financialStore?.financialData &&
          (financialStore.isLoading ? (
            <div className="w-full mt-14 mb-[42px] px-5">
              <LoadingBar />
            </div>
          ) : (
            <span className="relative" ref={dropdownRef}>
              <div
                className={`mb-4 mt-8 mx-3 flex items-center gap-2 ${
                  financialStore.financialData?.type !== "person"
                    ? "hover:bg-white-mdShade"
                    : ""
                } ${
                  companyDropdownInfo &&
                  financialStore.financialData?.type !== "person"
                    ? "bg-white-mdShade"
                    : ""
                } rounded-2xl py-2 px-4 transition-all`}
              >
                <span
                  onClick={() => {
                    navigate(
                      `/${financialStore.financialData?.cik}/financials`
                    );
                    handleNavigationClose();
                  }}
                  className="bg-white-smShade p-2 rounded-2xl cursor-pointer"
                >
                  <img
                    src={
                      financialStore.financialData?.logo || PlaceholderProfile
                    }
                    onError={(e) =>
                      (e.target.src =
                        "https://static.thenounproject.com/png/936853-200.png")
                    }
                    alt={
                      financialStore.financialData?.type === "person"
                        ? financialStore.financialData?.name
                        : financialStore.financialData?.companyName
                    }
                    width={45}
                    height={45}
                  />
                </span>
                <span
                  onClick={() =>
                    financialStore.financialData?.type !== "person" &&
                    setCompanyDropdownInfo((prev) => !prev)
                  }
                  className="flex items-center gap-2 cursor-pointer"
                >
                  <h3 className="text-sm font-semibold text-nowrap truncate w-[90px] text-left">
                    {financialStore.financialData?.type === "person"
                      ? financialStore.financialData?.name || "Undefined"
                      : financialStore.financialData?.companyName ||
                        "Undefined"}
                  </h3>
                  {financialStore.financialData?.type !== "person" &&
                    (companyDropdownInfo ? (
                      <IoIosArrowUp className="h-[14px] w-[14px]" />
                    ) : (
                      <IoIosArrowDown className="h-[14px] w-[14px]" />
                    ))}
                </span>
              </div>
              {companyDropdownInfo &&
                financialStore.financialData?.type !== "person" && (
                  <div className="absolute top-full left-3 w-[225%] h-auto bg-white-primary rounded-2xl z-50 drop-shadow-subtle-soft company-info-dropdown">
                    <div className="p-6 space-y-1.5">
                      <h3 className="text-xl font-bold text-start text-periwinkle-primary">
                        {financialStore.financialData?.companyName}
                      </h3>
                      <ul className="text-left font-semibold space-y-2">
                        <li className="ml-0 list-none text-sm">
                          Exchange:
                          <span
                            className={`ml-1 pill font-medium ${
                              financialStore.financialData?.exchange ===
                              "NASDAQ"
                                ? "bg-[#A3C4F3]"
                                : "bg-[#F4A6A1]"
                            }`}
                          >
                            {financialStore.financialData?.exchange ||
                              "Undefined"}
                          </span>
                        </li>
                        <li className="ml-0 list-none text-sm">
                          Stock Price: $
                          {Math.round(
                            financialStore.financialData?.stock_price * 100
                          ) / 100 || "Undefined"}
                          <span
                            className={`ml-1 pill font-medium ${
                              financialStore.financialData?.price_change < 0
                                ? "bg-salmon"
                                : "bg-green-300"
                            }`}
                          >
                            {financialStore.financialData?.price_change < 0
                              ? ""
                              : "+"}
                            {financialStore.financialData?.price_change ||
                              "Undefined"}
                            %
                          </span>
                        </li>
                        <li className="ml-0 list-none text-sm">
                          Market Cap:{" "}
                          {financialStore.financialData?.market_cap ||
                            "Undefined"}
                        </li>
                        <li className="ml-0 list-none text-sm">
                          Sector:
                          <span
                            className="ml-1 pill font-medium"
                            style={{
                              backgroundColor:
                                sectorIndustries[
                                  financialStore.financialData?.sector
                                ]?.color,
                            }}
                          >
                            {financialStore.financialData?.sector ||
                              "Undefined"}
                          </span>
                        </li>
                        <li className="ml-0 list-none text-sm">
                          Industry:
                          <span
                            className="ml-1 pill font-medium"
                            style={{
                              backgroundColor:
                                sectorIndustries[
                                  financialStore.financialData?.sector
                                ]?.industries[
                                  financialStore.financialData?.industry
                                ],
                            }}
                          >
                            {financialStore.financialData?.industry ||
                              "Undefined"}
                          </span>
                        </li>
                      </ul>
                    </div>
                    {financialStore.financialData?.address && (
                      <GoogleMapComponent
                        address={financialStore.financialData?.address}
                        apiKey="YOUR_GOOGLE_MAPS_API_KEY"
                      />
                    )}
                  </div>
                )}
            </span>
          ))}

        {!collapsed && (
          <div
            className={`flex flex-col w-full overflow-y-auto overflow-x-hidden sidebar-links ${
              !financialStore?.financialData && "mt-[104px]"
            } ${includesSearch && "h-3/4"}`}
          >
            {isLoading && (
              <div className="flex justify-center items-center h-full">
                <LoadingSpinner />
              </div>
            )}
            {!isLoading &&
              navigationLinks?.map((navigationLink, i) => (
                <div key={i}>
                  {navigationLink?.isFragment ? (
                    <a
                      className={`py-2 px-4 mx-3 mb-2 mt-0 cursor-pointer transition-all duration-300 border-none text-left text-sm flex items-center gap-2 whitespace-nowrap rounded-xl text-nowrap truncate ${checkActiveLink(
                        navigationLink
                      )}`}
                      href={navigationLink.link}
                    >
                      {navigationLink?.prefix && navigationLink.prefix}
                      <span>
                        {navigationLink?.icon && <navigationLink.icon />}
                      </span>
                      {navigationLink.label}
                      {navigationLink.subLinks?.length >= 1 &&
                        (navigationLink.label === "Categorized" ? (
                          isCategorizedOpen ? (
                            <IoIosArrowUp className="ml-auto" />
                          ) : (
                            <IoIosArrowDown className="ml-auto" />
                          )
                        ) : navigationLink.label === "Filings" ? (
                          isFilingsOpen ? (
                            <IoIosArrowUp className="ml-auto" />
                          ) : (
                            <IoIosArrowDown className="ml-auto" />
                          )
                        ) : null)}
                    </a>
                  ) : (
                    <div
                      className={`py-2 px-4 mx-3 mb-2 mt-0 cursor-pointer transition-all duration-300 border-none text-left text-sm flex items-center gap-2 whitespace-nowrap rounded-xl text-nowrap truncate ${checkActiveLink(
                        navigationLink
                      )}`}
                      onClick={() => {
                        if (navigationLink.label === "Categorized") {
                          toggleDropdown("Categorized");
                        } else if (navigationLink.label === "Filings") {
                          toggleDropdown("Filings");
                        } else if (navigationLink.sectionId) {
                          scrollToSection(navigationLink.sectionId);
                        } else {
                          handleFilingClick(navigationLink.link); // Call filing click handler
                        }
                        handleNavigationClose();
                      }}
                    >
                      {navigationLink?.prefix && navigationLink.prefix}
                      <span>
                        {navigationLink?.icon && <navigationLink.icon />}
                      </span>
                      {navigationLink.label}
                      {navigationLink.subLinks?.length >= 1 &&
                        (navigationLink.label === "Categorized" ? (
                          isCategorizedOpen ? (
                            <IoIosArrowUp className="ml-auto" />
                          ) : (
                            <IoIosArrowDown className="ml-auto" />
                          )
                        ) : navigationLink.label === "Filings" ? (
                          isFilingsOpen ? (
                            <IoIosArrowUp className="ml-auto" />
                          ) : (
                            <IoIosArrowDown className="ml-auto" />
                          )
                        ) : null)}
                    </div>
                  )}
                  {navigationLink.subLinks?.length > 0 && (
                    <div
                      className={`${
                        (navigationLink.label === "Categorized" &&
                          isCategorizedOpen) ||
                        (navigationLink.label === "Filings" && isFilingsOpen)
                          ? "flex"
                          : "hidden"
                      } flex-col ml-5`}
                    >
                      {navigationLink.subLinks?.map((navigationSubLink, j) =>
                        navigationSubLink.isFragment ? (
                          <a
                            key={j}
                            className={`py-2 px-4 mx-3 mb-2 mt-0 cursor-pointer transition-all duration-300 border-none text-left text-sm flex items-center gap-2 whitespace-nowrap rounded-xl text-nowrap truncate ${checkActiveLink(
                              navigationSubLink
                            )}`}
                            href={navigationSubLink.link}
                          >
                            {navigationSubLink?.prefix && (
                              <div
                                className={`size-6 text-[10px] p-2 rounded-lg ${
                                  navigationSubLink.prefix.backgroundColor || ""
                                } flex items-center justify-center font-medium`}
                              >
                                {navigationSubLink.prefix.initials}
                              </div>
                            )}
                            <span>
                              <navigationSubLink.icon />
                            </span>
                            {navigationSubLink.label}
                          </a>
                        ) : (
                          <div
                            key={j}
                            className={`py-2 px-4 mx-3 mb-2 mt-0 cursor-pointer transition-all duration-300 border-none text-left text-sm flex items-center gap-2 whitespace-nowrap rounded-xl text-nowrap truncate ${checkActiveLink(
                              navigationSubLink
                            )}`}
                            onClick={() => {
                              handleFilingClick(navigationSubLink.link); // Reset tab and navigate
                              handleNavigationClose();
                            }}
                          >
                            {navigationSubLink?.prefix && (
                              <div
                                className={`size-6 text-[10px] p-2 rounded-lg ${
                                  navigationSubLink.prefix.backgroundColor || ""
                                } flex items-center justify-center font-medium`}
                              >
                                {navigationSubLink.prefix.initials}
                              </div>
                            )}
                            <span>
                              <navigationSubLink.icon />
                            </span>
                            {navigationSubLink.label}
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              ))}

            {includesSearch && (
              <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 w-[85%] bg-white-smShade rounded-3xl h-12 flex items-center px-3 gap-2">
                <input
                  className="flex-1"
                  placeholder="Search keyword"
                  value={searchTerm}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                />
                <button
                  id="search-icon-container"
                  onClick={handleNewSearchClick}
                >
                  <FaSearch id="search-icon" />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);
export default SideBar;
