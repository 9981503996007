import React from "react";
import UnleveredLogo from "../images/Unlevered-Logo-1.png"; // Adjust the path as necessary
import { HiMiniBars3BottomLeft } from "react-icons/hi2";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const LogoWithMenuToggle = ({ navigationBarStore }) => {
  const navigate = useNavigate();
  return (
    <div className="medium-large:hidden flex items-center gap-4">
      <p
        className="text-2xl cursor-pointer"
        onClick={() => navigationBarStore.toggleIsOpen()}
      >
        {navigationBarStore.isOpen ? <IoClose /> : <HiMiniBars3BottomLeft />}
      </p>
      <span className="size-12 cursor-pointer" onClick={() => navigate("/")}>
        <img src={UnleveredLogo} alt="Unlevered Logo" height={48} width={48} />
      </span>
    </div>
  );
};

export default LogoWithMenuToggle;
