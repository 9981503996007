export const filingHexCodes = {
  1: "#8CFF75",
  3: "#82C4E6",
  4: "#FFB3C6",
  5: "#D8B4E2",
  25: "#B5F1FF",
  144: "#FFBE86",
  425: "#ECD8B5",
  487: "#FF77BF",
  497: "#FF96C9",
  "1-A": "#A3FF8E",
  "1-A POS": "#B6FF9F",
  "1-A-W": "#9FFF75",
  "1-E": "#C8FFB3",
  "1-E AD": "#D2FFBF",
  "1-K": "#AFFFA3",
  "1-SA": "#96FFA6",
  "1-U": "#CCFF99",
  "1-Z": "#B8FF75",
  "1-Z-W": "#E0FFCC",
  "10-12B": "#A4FF94",
  "10-12G": "#B2FFAA",
  "10-C": "#98FF80",
  "10-D": "#C6FFC1",
  "10-K": "#F0F7A2",
  "10-K/A": "#E4F58D",
  "10-K405": "#F7F9B0",
  "10-K405/A": "#DFF283",
  "10-KSB": "#F5F7A7",
  "10-KSB/A": "#E2F78A",
  "10-KT": "#F1F7C3",
  "10-KT/A": "#D9F57A",
  "10-Q": "#E8F79C",
  "10-Q/A": "#F3F7B5",
  "10-QSB": "#D4F27A",
  "10-QT": "#EDF7A1",
  "10-QT/A": "#E4F383",
  "10KSB": "#A2E86C",
  "10KSB/A": "#89D960",
  "10KSB40": "#78C754",
  "10KSB40/A": "#6EBB4A",
  "10KT405": "#63AF42",
  "10KT405/A": "#5AA33A",
  "10QSB": "#4F9431",
  "10QSB/A": "#45882A",
  "10SB12B": "#3D7C24",
  "10SB12G": "#34911F",
  "11-K": "#FFA857",
  "11-KT": "#FFB86A",
  "12G3-2B": "#FF9C42",
  "12G32BR": "#FF8740",
  "13F-E": "#FF914D",
  "13F-HR": "#FF9D61",
  "13F-NT": "#FFA86E",
  "13FCONP": "#FFB37A",
  "15-12B": "#FFA3A3",
  "15-12G": "#FFB0B0",
  "15-15D": "#FFB8A8",
  "15F-12B": "#FF9A9A",
  "15F-12G": "#FFCCCC",
  "15F-15D": "#FFC2B2",
  "18-12B": "#FF8F8F",
  "18-K": "#FFADAD",
  "19B-4E": "#FF9999",
  "2-A": "#A3D8FF",
  "2-AF": "#B0E0FF",
  "2-E": "#99D0FF",
  "20-F": "#8FCFFF",
  "20-F/A": "#B3E7FF",
  "20FR12B": "#A8E2FF",
  "20FR12G": "#9EDAFF",
  "24F-1": "#87CCFF",
  "24F-2EL": "#BBEEFF",
  "24F-2NT": "#94DFFF",
  "24F-2TM": "#A1E4FF",
  "25-NSE": "#C0F5FF",
  "253G1": "#93D6FF",
  "253G2": "#ADDFFF",
  "253G3": "#89CEFF",
  "253G4": "#B8E8FF",
  "305B2": "#78B8DD",
  "34-12H": "#7AC3F0",
  "35-APP": "#6FB6E0",
  "35-CERT": "#6DB0D8",
  "39-304D": "#85CAE9",
  "39-310B": "#8ACFF1",
  "40-17F1": "#FFB8D1",
  "40-17F2": "#FFC0D6",
  "40-17G": "#FFADC4",
  "40-17GCS": "#FFA3BE",
  "40-202A": "#FFCCD6",
  "40-203A": "#FFBCCB",
  "40-205E": "#FFA6C1",
  "40-206A": "#FFB3D6",
  "40-24B2": "#FFBFD9",
  "40-33": "#FFCEDF",
  "40-6B": "#FFAFCC",
  "40-6C": "#FFC2D9",
  "40-8B25": "#FFAACC",
  "40-8F-2": "#FF99BA",
  "40-8F-A": "#FFB8D4",
  "40-8F-B": "#FF95C1",
  "40-8F-L": "#FFBED6",
  "40-8F-M": "#FFCCE1",
  "40-8FC": "#FFDBE7",
  "40-APP": "#FFAACF",
  "40-F": "#FF9EC6",
  "40-F/A": "#FFC4D9",
  "40-OIP": "#FFD1E1",
  "40-RPT": "#FF97BC",
  "40FR12B": "#FF86B5",
  "40FR12G": "#FFBFD4",
  "424A": "#F5E3C3",
  "424B1": "#F7E8D0",
  "424B2": "#F3DCC0",
  "424B3": "#EED7B8",
  "424B4": "#F8E4C7",
  "424B5": "#EED9BE",
  "424B7": "#F2E2C7",
  "424B8": "#F1DCC3",
  "424H": "#F4E5CE",
  "485A24E": "#FF99CC",
  "485A24F": "#FF85B6",
  "485APOS": "#FF8FCF",
  "485B24E": "#FF7EB5",
  "485B24F": "#FF94D1",
  "485BPOS": "#FF9AD6",
  "485BXT": "#FFB3E0",
  "485BXTF": "#FF85C1",
  "486A24E": "#FF9FCF",
  "486APOS": "#FF73AC",
  "486B24E": "#FF80B8",
  "486BPOS": "#FF99D9",
  "486BXT": "#FFADD9",
  "497AD": "#FFA3DB",
  "497H2": "#FFA8CF",
  "497J": "#FFBADF",
  "497K": "#FFA0CC",
  "497K1": "#FF8EB9",
  "497K2": "#FFAED8",
  "497K3A": "#FF92C3",
  "497K3B": "#FF89BB",
  "497VPI": "#FFA9CE",
  "497VPU": "#FFBFD9",
  "6-K": "#CFA1DD",
  "6-K/A": "#E3B8F0",
  "6B NTC": "#D3A7E8",
  "6B ORDR": "#E6C1F1",
  "8-A12B": "#FFF275",
  "8-A12G": "#FFEB59",
  "8-B12B": "#FFE94A",
  "8-B12G": "#FFDE5A",
  "8-K": "#FFED70",
  "8-K/A": "#FFDB3A",
  "8-K12B": "#FFE34F",
  "8-K12G3": "#FFEC87",
  "8-K15D5": "#FFF05A",
  "8-M": "#FFD74A",
  "8-M Filing": "#FFEB6A",
  "8A12BEF": "#FFF97A",
  "8A12BT": "#FFDD33",
  "8F-2 NTC": "#FFE14F",
  "8F-2 ORDR": "#FFF066",
  "9-M": "#FFEE5B",
  "9-M Filing": "#FFD94C",
  "ABS-15G": "#7FCFCC",
  "ABS-EE": "#82CEC9",
  ADB: "#89DDD8",
  "ADN-MTL": "#7AC5C2",
  "ADN-MTL Filing": "#76C1BD",
  ADV: "#8BD9D4",
  "ADV-E": "#91E1DE",
  "ADV-E Filing": "#85D2D1",
  "ADV-H-C": "#87D4D3",
  "ADV-H-T": "#90DCD9",
  "ADV-NR": "#80C8C7",
  "ADV-NR Filing": "#8BCFD0",
  ADVCO: "#78C3BE",
  ADVW: "#83CDC8",
  AFDB: "#84D0CF",
  ANNLRPT: "#7AC2BB",
  "APP NTC": "#88D7D4",
  "APP ORDR": "#79C5C1",
  "APP WD": "#75C0BC",
  "APP WD Filing": "#8ADAD7",
  "APP WDG": "#86D4D0",
  ARS: "#7FC9C5",
  "ATS-N": "#8AD5D2",
  "ATS-N-C": "#82CFCC",
  "ATS-N-W": "#81CAC8",
  "ATS-N/CA": "#77C4C0",
  "ATS-N/MA": "#8CCFCD",
  "ATS-N/UA": "#8BD3D0",
  AW: "#81D1CD",
  "AW WD": "#79CFC8",
  BDCO: "#85D7D1",
  "BW-2": "#7BCFC4",
  "BW-3": "#8ADBD6",
  C: "#F28B82",
  "C-AR": "#F4A7A3",
  "C-AR-W": "#F6B1AD",
  "C-TR": "#F8C1BB",
  "C-TR-W": "#F9D0C9",
  "C-U": "#FAB5A9",
  "C-U-W": "#FCAAA3",
  "C-W": "#FCA1A1",
  "CANCELLATION-MA": "#E5948E",
  CB: "#D97B78",
  CERT: "#F3AD9D",
  CERTAMX: "#F4B5A6",
  CERTARCA: "#F9B8B2",
  CERTBATS: "#FDD3C7",
  CERTBSE: "#FDBDB8",
  CERTCBO: "#FC8E8B",
  CERTCSE: "#FAA09A",
  CERTNAS: "#FBC9C5",
  CERTNYS: "#EFA3A1",
  CERTPAC: "#F49895",
  CERTPBS: "#EE9E97",
  CFPORTAL: "#E69C9B",
  "CFPORTAL-W": "#F0B0AD",
  CORRESP: "#E58B87",
  "CT ORDER": "#F29C95",
  D: "#F5F9F0",
  "DEF 14A": "#F0F5FA",
  "DEF 14C": "#FAF5F0",
  "DEF-OC": "#F7FAF5",
  DEF13E3: "#F9F5F3",
  DEFA14A: "#F6F5FF",
  DEFA14C: "#F4FAF9",
  DEFC14A: "#F8F9E5",
  DEFC14C: "#F5F0F8",
  DEFM14A: "#FAF7F3",
  DEFM14C: "#FFF5F8",
  DEFN14A: "#F5FBF6",
  DEFR14A: "#F0FAF7",
  DEFR14C: "#FAF8F5",
  DEFS14A: "#F6F2FB",
  DEFS14C: "#FAF6F0",
  "DEL AM": "#F9F0F7",
  DFAN14A: "#F5F0FB",
  DFRN14A: "#F8FAF0",
  DOS: "#F5F3FA",
  DOSLTR: "#FBF4F2",
  DRS: "#F3FAF5",
  DRSLTR: "#FAF0F3",
  DSTRBRPT: "#F6F2F5",
  EBRD: "#F2F7FA",
  EFFECT: "#FFA07A",
  "F-1": "#FF8C42",
  "F-10": "#FF7F24",
  "F-10EF": "#FF9856",
  "F-10POS": "#FF9C68",
  "F-1MEF": "#FF8430",
  "F-2": "#FFB07F",
  "F-3": "#FFA55A",
  "F-3ASR": "#FF9140",
  "F-3D": "#FFA26D",
  "F-3DPOS": "#FF8A3D",
  "F-3MEF": "#FF7A24",
  "F-4": "#FF9F49",
  "F-4 POS": "#FF8B30",
  "F-4EF": "#FFB86F",
  "F-4MEF": "#FF944C",
  "F-6": "#FF7333",
  "F-6 POS": "#FFB284",
  "F-6EF": "#FF8C3A",
  "F-7": "#FFA94C",
  "F-7 POS": "#FF8652",
  "F-8": "#FFA763",
  "F-8 POS": "#FF9D40",
  "F-80": "#FF9837",
  "F-80POS": "#FFB14A",
  "F-9": "#FF8240",
  "F-9 POS": "#FF7C33",
  "F-9EF": "#FFA453",
  "F-N": "#FF9630",
  "F-X": "#FFB464",
  FOCUSN: "#FF8B2D",
  FWP: "#FF9442",
  "G-405": "#FF9B37",
  "G-405N": "#FF8630",
  "G-FIN": "#FFBA6A",
  "G-FINW": "#FF8F45",
  IADB: "#FF7D28",
  "ID-NEWCIK": "#FFAC57",
  IFC: "#FF8940",
  IRANNOTICE: "#FFA249",
  MA: "#FF9845",
  "MA-A": "#FFAD72",
  "MA-I": "#FF8C28",
  "MA-W": "#FFB06A",
  MSD: "#FF993A",
  MSDCO: "#FFA354",
  MSDW: "#FF8333",
  "MSDW Filing": "#FFBC7D",
  "N-1": "#B0D8C0",
  "N-14": "#A8D1B7",
  "N-14 8C": "#C1E1CA",
  "N-14AE": "#AECFB5",
  "N-14MEF": "#BADDC5",
  "N-18F1": "#C6E5CE",
  "N-1A": "#A2C8AC",
  "N-1A EL": "#B8D6B9",
  "N-2": "#C3E3C7",
  "N-2 POSASR": "#B4D4B2",
  "N-23C-1": "#AFCFB8",
  "N-23C-2": "#BFDCC6",
  "N-23C3A": "#D1EAD6",
  "N-23C3B": "#B2D1B3",
  "N-23C3C": "#C8E1C6",
  "N-27D-1": "#D5E9D4",
  "N-2ASR": "#A9CBB2",
  "N-2MEF": "#D0E4C9",
  "N-3": "#C4DBC5",
  "N-3 EL": "#A4C6A9",
  "N-30B-2": "#B5D8B7",
  "N-30D": "#CCEDD3",
  "N-4": "#D8F1DA",
  "N-4 EL": "#C1DCC6",
  "N-5": "#B7D7B5",
  "N-54A": "#A5C8A8",
  "N-54C": "#D3E9D2",
  "N-6": "#BED5BA",
  "N-6F": "#C6E0C8",
  "N-8A": "#B9D6BB",
  "N-8B-2": "#D1E9CF",
  "N-8B-4": "#C2DFBE",
  "N-8F": "#B3D3B4",
  "N-8F NTC": "#CFE4C7",
  "N-8F ORDR": "#A8C9A9",
  "N-CEN": "#D4EBD4",
  "N-CR": "#B9D9B7",
  "N-CSR": "#AFCFAD",
  "N-CSRS": "#CADECA",
  "N-MFP": "#D8F0D5",
  "N-MFP1": "#BDD9B9",
  "N-MFP2": "#CFE7CC",
  "N-PX": "#B3D5B6",
  "N-Q": "#D4ECD3",
  "N-VP": "#BFD9B9",
  "N-VPFS": "#C4DFC5",
  N14AE24: "#D7F1D6",
  N14EL24: "#B8D8B5",
  "NO ACT": "#D2E6C8",
  "NPORT-EX": "#ADD0B0",
  "NPORT-P": "#C9E2C9",
  "NRSRO-CE": "#D6EED2",
  "NRSRO-UPD": "#B4D2B4",
  "NSAR-A": "#A9CAA8",
  "NSAR-AT": "#CFE9D0",
  "NSAR-B": "#C1DDC3",
  "NSAR-BT": "#BCDAB9",
  "NSAR-U": "#B6D6B3",
  "NT 10-D": "#E3FFB6",
  "NT 10-K": "#DFFFAD",
  "NT 10-K/A": "#D9FFA3",
  "NT 10-Q": "#D5FF9B",
  "NT 10-Q/A": "#E8FFBE",
  "NT 11-K": "#D1FF94",
  "NT 15D2": "#CFFF8C",
  "NT 20-F": "#C9FF82",
  "NT N-CEN": "#EBFFC4",
  "NT N-MFP": "#D4FF8E",
  "NT N-MFP1": "#D0FF87",
  "NT N-MFP2": "#E1FFAB",
  "NT NPORT-EX": "#C6FF74",
  "NT NPORT-P": "#E5FFB2",
  "NT-NCEN": "#DEFF9E",
  "NT-NCSR": "#D7FF93",
  "NT-NSAR": "#ECFFC8",
  NTFNCEN: "#DFFF8D",
  NTFNCSR: "#E2FFAE",
  NTFNSAR: "#CDFF82",
  "NTN 10D": "#E6FFB5",
  "NTN 10K": "#D9FF98",
  "NTN 10Q": "#D3FF8C",
  "NTN 11K": "#E9FFBF",
  "NTN 20F": "#D6FF90",
  NTN15D2: "#DAFF9D",
  "OIP NTC": "#FFC2D1",
  "OIP ORDR": "#FFB6C1",
  "POS 8C": "#FFCAD4",
  "POS AM": "#FFBBCF",
  "POS AMC": "#FFCCD9",
  "POS AMI": "#FFC1CE",
  "POS EX": "#FFB3C4",
  POS462B: "#FFD1DD",
  POS462C: "#FFDFE7",
  POSASR: "#FFB8C8",
  "PRE 14A": "#FFC8D3",
  "PRE 14C": "#FFDFE6",
  PRE13E3: "#FFACC1",
  PREA14A: "#FFCBD9",
  PREA14C: "#FFDEE4",
  PREC14A: "#FFBFD1",
  PREC14C: "#FFC5D4",
  PREM14A: "#FFE1E9",
  PREM14C: "#FFBDCF",
  PREN14A: "#FFC3D7",
  PRER14A: "#FFE4E9",
  PRER14C: "#FFB0C7",
  PRES14A: "#FFD4DE",
  PRES14C: "#FFEBF1",
  PRRN14A: "#FFC7D9",
  PX14A6G: "#FFDCE3",
  PX14A6N: "#FFAFCA",
  QRTLYRPT: "#FFDAE1",
  QUALIF: "#FFCADB",
  "REG-NR": "#FFD7E1",
  REGDEX: "#FFE3E8",
  "REVOCATION-MA": "#FFC4D8",
  REVOKED: "#FFD1E0",
  RW: "#FFDFE8",
  "RW WD": "#FFB3C8",
  "S-1": "#D8B4E2",
  "S-11": "#CFA1DD",
  "S-11MEF": "#E3B8F0",
  "S-1MEF": "#D3A7E8",
  "S-2": "#E6C1F1",
  "S-20": "#D9B3E2",
  "S-2MEF": "#E1C3F0",
  "S-3": "#D6A9E8",
  "S-3ASR": "#E4C6F1",
  "S-3D": "#E8B8F0",
  "S-3DPOS": "#D4A6DE",
  "S-3MEF": "#E0C2EB",
  "S-4": "#DBB5E6",
  "S-4 POS": "#E1BCEF",
  "S-4EF": "#CBA0E2",
  "S-4MEF": "#E3C6F5",
  "S-6": "#DAB1EB",
  "S-6EL24": "#EACCF4",
  "S-8": "#D7AEE5",
  "S-8 POS": "#E5BEEF",
  "S-B": "#E6C8F2",
  "S-BMEF": "#D9A5E5",
  "SB-1": "#E5BDF0",
  "SB-1MEF": "#E1C0F2",
  "SB-2": "#DBA9E6",
  "SB-2MEF": "#E5CCF4",
  SBSE: "#E2B8EB",
  "SBSE-A": "#D7AFE6",
  "SBSE-BD": "#E8C7F0",
  "SBSE-C": "#DDB5E6",
  "SC 13D": "#E4C0F3",
  "SC 13E1": "#DBA3DE",
  "SC 13E3": "#EDCFF6",
  "SC 13E4": "#D9B8E3",
  "SC 13G": "#E9BDEF",
  "SC 14D1": "#D2A9E0",
  "SC 14D9": "#E5C8F2",
  "SC 14F1": "#DEC0EB",
  "SC 14N": "#D1A1DD",
  "SC TO-C": "#E8C2F3",
  "SC TO-I": "#E3C1F1",
  "SC TO-T": "#DCB3E8",
  SC13E4F: "#E9CCF5",
  SC14D1F: "#D6A7E4",
  SC14D9C: "#E3BAEE",
  SC14D9F: "#DBA8E2",
  SD: "#E4C5F4",
  SDR: "#E5B6E8",
  SE: "#D4A8E1",
  "SEC ACTION": "#E9C3F0",
  "SEC STAFF": "#D9AEE7",
  LETTER: "#E4B9EB",
  "SF-1": "#D8BFAA",
  "SF-3": "#E2C8B5",
  "SP 15D2": "#E7D3C1",
  SUPPL: "#CBB29C",
  "T-3": "#DEC5AB",
  "T-6": "#D4B8A1",
  "TA-1": "#EAD4B9",
  "TA-2": "#F0DAC3",
  "TA-W": "#C5A990",
  TACO: "#DFC3A9",
  TH: "#E6D0BA",
  TTW: "#E3C5AD",
  "U-1": "#C9A88C",
  "U-12-IA": "#F0DCC4",
  "U-12-IB": "#EBD1B8",
  "U-13-60": "#D1B49A",
  "U-13E-1": "#E4C7AF",
  "U-33-S": "#F3DDC5",
  "U-3A-2": "#D9B79B",
  "U-3A3-1": "#EAD2B6",
  "U-57": "#E8CAB3",
  "U-6B-2": "#D6B497",
  "U-7D": "#F1DAC0",
  "U-9C-3": "#DEC2A6",
  U5A: "#D5B59B",
  U5B: "#E9D3B9",
  U5S: "#F2DEBF",
  UNDER: "#CFAF95",
  UPLOAD: "#E1C5A8",
  "WDL-REQ": "#EAD6BB",
  "X-17A-5": "#D2B296",
};

export function getHexCode(cik) {
  return filingHexCodes[cik];
}
