import { action, makeObservable, observable } from "mobx";

class FinancialStore {
  financialData = null;
  isLoading = false;

  constructor() {
    makeObservable(this, {
      // observables are values
      financialData: observable,

      // actions update observable values / state
      updateFinancialData: action,
    });
  }

  //   actions
  updateFinancialData = (financialData) => {
    this.financialData = financialData;
  };
  toggleIsLoading = () => {
    this.isLoading = !this.isLoading;
  };
}

export default new FinancialStore();
