import React from "react";
import UnleveredLogo from "../images/UnleveredLogo.png";
import GoogleLogo from "../images/google.png";
import "../styles/login.css";

const Login = () => {
  const authenticateWithGoogle = async () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/login`;
  };

  return (
    <div className="login-container">
      <div className="login-bg flex items-center flex-col gap-5 sm:py-[42px] sm:px-14 py-[31.5px] px-[42px]">
        <img
          src={UnleveredLogo}
          alt="unlevered"
          className="sm:w-[250px] sm:h-[52px] w-[187.5px] h-[39px]"
        />
        <button
          className="font-primary font-medium text-white-primary bg-lavender-primary hover:bg-lavender-primary/90 transition-all py-[14px] px-2 rounded-2xl sm:text-lg text-base relative tracking-wide flex items-center justify-center w-full gap-2"
          onClick={authenticateWithGoogle}
        >
          <img
            src={GoogleLogo}
            alt="google"
            className="sm:size-8 size-6 xs:absolute block left-6"
          />
          Login with Google
        </button>
        <p className="text-sm">
          By logging in, I am agreeing to the{" "}
          <a
            href="https://unlevered.ai/terms/"
            target="_blank"
            rel="noreferrer"
            className="underline text-lavender-primary"
          >
            terms of service
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
