import React, { useEffect, useState } from "react";
import "../styles/checkbox.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { fetchData, getAlert, getScreeners, saveAlert } from "../services/api";
import { IoClose } from "react-icons/io5";
import useDebounce from "./useDebounce";
import { defaultAlertAdjustments, formTypes } from "../constants";
import Alert from "./Alert";
import LoadingSpinner from "./LoadingSpinner";
import LogoWithMenuToggle from "./LogoWithMenuToggle";
import { observer } from "mobx-react";

function SelectorDropdown({
  isOpen,
  selections,
  closeDropdown,
  setter,
  label,
  creatable,
  createLink,
  clearInput,
}) {
  const navigate = useNavigate();
  return (
    <div
      className={`absolute w-full max-h-[400%] top-[125%] bg-white-primary left-0 rounded-2xl drop-shadow-subtle-xsoft overflow-hidden z-20 ${
        isOpen ? "block" : "hidden"
      } overflow-y-auto`}
    >
      <article className="flex flex-col overflow-auto h-full">
        {selections.map((selection, index) => (
          <span
            className="px-4 py-0.5 hover:bg-lavender-smTint cursor-pointer transition-all duration-300 font-primary font-medium text-left"
            key={index}
            onClick={() => {
              setter((prev) => {
                const currentValue = prev[label];
                return {
                  ...prev,
                  [label]: Array.isArray(currentValue)
                    ? currentValue?.length < 5
                      ? [...currentValue, selection]
                      : currentValue
                    : selection,
                };
              });
              if (clearInput) {
                clearInput();
              }
              closeDropdown();
            }}
          >
            {selection}
          </span>
        ))}
        {creatable && createLink && (
          <span
            className="px-4 py-0.5 hover:bg-lavender-smTint cursor-pointer transition-all duration-300 font-primary font-medium text-left"
            onClick={() => {
              navigate(createLink);
            }}
          >
            Create new +
          </span>
        )}
      </article>
    </div>
  );
}

const AlertSetup = observer(() => {
  const [adjustments, setAdjustments] = useState(defaultAlertAdjustments);
  const [inputs, setInputs] = useState({
    queries: "",
    formTypes: "",
    tickers: "",
  });
  const [alertTitle, setAlertTitle] = useState("");
  const [screeners, setScreeners] = useState([]);
  const [type, setType] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState("");
  const [tickerDropdownValues, setTickerDropdownValues] = useState([]);
  const [errorTooltipVisible, setErrorTooltipVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const debouncedQuery = useDebounce(inputs.tickers, 300); // Use the debounce hook
  const navigate = useNavigate();
  const location = useLocation();
  const [navigationBarStore] = useOutletContext();

  const handleAdd = (event, type) => {
    event.preventDefault();
    setAdjustments((prev) => ({
      ...prev,
      [type]: [...prev[type], inputs[type]],
    }));
    setInputs((prev) => ({ ...prev, [type]: "" }));
  };

  const handleScreenerClick = () => {
    if (screeners.length === 0) {
      setErrorTooltipVisible(true);
      setTimeout(() => setErrorTooltipVisible(false), 3000);
    } else {
      setDropdownOpen((prev) => (prev === "screener" ? "" : "screener"));
    }
  };

  const getAdjustmentsFiltered = () => {
    return {
      queries: adjustments.queries,
      form_types:
        adjustments.formTypesSelectedAll || !adjustments.formTypes.length
          ? ["all"]
          : adjustments.formTypes,
      ...(adjustments.equityType === "screener" && adjustments.screener
        ? {
            screener_id: screeners.filter(
              (screener) => screener.name === adjustments.screener
            )[0].screener_id,
          }
        : adjustments.tickers?.length
        ? { tickers: adjustments.tickers }
        : { tickers: ["all"] }),
    };
  };

  const handleAlertSave = async () => {
    setIsSubmitting(true);
    if (adjustments === defaultAlertAdjustments) {
      setError(
        "No changes detected. Please modify the values before creating a alert."
      );
      setIsSubmitting(false);
      return;
    }
    if (!alertTitle) {
      setError("Please provide a name for your alert before proceeding.");
      setIsSubmitting(false);
      return;
    }
    const adjustmentsFiltered = getAdjustmentsFiltered();

    const res = await saveAlert({
      ...adjustmentsFiltered,
      old_name: type === "create" ? null : location.state?.reference,
      new_name: alertTitle,
    });

    if (res?.status === "success") {
      navigate("/alerts");
    } else {
      setError(
        res?.message || "Something unexpected happened. Please try again."
      );
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (location?.state?.reference) {
      const populateAdjustments = async () => {
        const alertAdjustments = await getAlert(location.state.reference);
        if (alertAdjustments && alertAdjustments?.status === "success") {
          // set the adjustments and set the alert title
          setAdjustments({
            queries: alertAdjustments.data.queries,
            ...(alertAdjustments.data.form_types?.length === 1 &&
            alertAdjustments.data.form_types[0] === "all"
              ? { formTypes: [], formTypesSelectedAll: true }
              : {
                  formTypes: alertAdjustments.data.form_types || [],
                  formTypesSelectedAll: false,
                }),
            ...(alertAdjustments.data.screener_id
              ? {
                  equityType: "screener",
                  tickers: [],
                  screener_id: alertAdjustments.data.screener_id,
                }
              : {
                  equityType: "ticker",
                  screener_id: "",
                  tickers:
                    alertAdjustments.data.tickers?.length === 1 &&
                    alertAdjustments.data.tickers[0] === "all"
                      ? []
                      : alertAdjustments.data.tickers || [],
                }),
          });
          setAlertTitle(alertAdjustments.data.name);
          type !== "edit" && setType("edit");
        } else {
          type !== "error" && setType("error");
        }
      };
      populateAdjustments();
    } else {
      type !== "create" && setType("create");
    }
  }, [location?.state?.reference]);

  useEffect(() => {
    const fetchScreeners = async () => {
      const fetchedScreeners = await getScreeners();
      setScreeners(fetchedScreeners || []);
    };

    fetchScreeners();
  }, []);

  useEffect(() => {
    // Fetch data when the debounced value changes
    if (debouncedQuery) {
      fetchData(debouncedQuery).then((companies) => {
        if (companies?.length) {
          setDropdownOpen("tickers");
          setTickerDropdownValues(companies.map((company) => company.ticker));
        }
      });
    } else {
      setDropdownOpen("");
      setTickerDropdownValues([]); // Clear dropdown if input is empty
    }
  }, [debouncedQuery]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Reload site? Changes you made may not be saved.";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <LogoWithMenuToggle navigationBarStore={navigationBarStore} />
      <div className="mt-[31px] medium-large:mt-[78px]">
        {type !== "edit" && type !== "create" ? (
          type === "error" ? (
            <div className="loading-page-container flex flex-col">
              <h1 className="text-salmon font-bold text-8xl">404</h1>
              <p>
                The screener you're trying to access may no longer be available.
              </p>
            </div>
          ) : (
            <div className="loading-page-container">
              <LoadingSpinner />
            </div>
          )
        ) : (
          <>
            <h1 className="text-2xl font-bold text-start mb-2">
              {type === "create" ? "Create an alert" : "Update your alert"}
            </h1>
            {error && (
              <Alert
                type="error"
                message={error}
                clearMessage={() => setError("")}
                duration={2000}
              />
            )}
            <main className="flex flex-col items-start gap-4 p-4 rounded-2xl bg-white-xsShade mb-4">
              <form
                className="w-fit bg-white-smShade rounded-2xl"
                onSubmit={(e) => handleAdd(e, "queries")}
              >
                <input
                  className="text-base w-fit px-4 py-2"
                  type="text"
                  value={inputs.queries}
                  onChange={(e) =>
                    setInputs((prev) => ({ ...prev, queries: e.target.value }))
                  }
                  placeholder="Keywords"
                />
                <button
                  type="submit"
                  className="py-2 px-4 rounded-2xl bg-lavender-primary text-white-primary disabled:bg-lavender-primary/50 disabled:cursor-not-allowed"
                  disabled={adjustments.queries.length >= 5 || !inputs.queries}
                >
                  +
                </button>
              </form>
              <section className="flex flex-wrap items-center gap-x-4 gap-y-2">
                {adjustments.queries.length ? (
                  adjustments.queries.map((query, index) => (
                    <p
                      className="px-2 py-1 rounded-2xl bg-white-mdShade w-fit flex items-center gap-2"
                      key={index}
                    >
                      {query}
                      <span
                        className="text-salmon cursor-pointer"
                        onClick={() =>
                          setAdjustments((prev) => ({
                            ...prev,
                            queries: prev.queries.filter((_, i) => i !== index),
                          }))
                        }
                      >
                        <IoClose />
                      </span>
                    </p>
                  ))
                ) : (
                  <p className="text-black-xsTint text-sm">
                    You have no keywords
                  </p>
                )}
              </section>
              <span className="relative">
                <form
                  className="w-fit bg-white-smShade rounded-2xl"
                  onSubmit={(e) => handleAdd(e, "formTypes")}
                >
                  <input
                    className={`text-base w-fit px-4 py-2 ${
                      adjustments.formTypesSelectedAll
                        ? "cursor-not-allowed"
                        : ""
                    } ${
                      adjustments.formTypesSelectedAll && inputs.formTypes
                        ? "strike"
                        : ""
                    }`}
                    type="text"
                    value={inputs.formTypes}
                    onChange={(e) => {
                      setInputs((prev) => ({
                        ...prev,
                        formTypes: e.target.value,
                      }));
                      if (e.target.value && dropdownOpen !== "formTypes") {
                        setDropdownOpen("formTypes");
                      } else if (!e.target.value) {
                        setDropdownOpen("");
                      }
                    }}
                    placeholder="Form Type"
                    readOnly={adjustments.formTypesSelectedAll}
                    disabled={adjustments.formTypesSelectedAll}
                  />
                  <button
                    type="submit"
                    className="py-2 px-4 rounded-2xl bg-lavender-primary text-white-primary disabled:bg-lavender-primary/50 disabled:cursor-not-allowed"
                    disabled={
                      adjustments.formTypes.length >= 5 ||
                      !inputs.formTypes ||
                      adjustments.formTypesSelectedAll
                    }
                  >
                    +
                  </button>
                </form>
                <SelectorDropdown
                  isOpen={dropdownOpen === "formTypes"}
                  selections={formTypes
                    .filter((val) =>
                      val.toLowerCase().includes(inputs.formTypes.toLowerCase())
                    )
                    .filter((val) => !adjustments.formTypes?.includes(val))}
                  closeDropdown={() => setDropdownOpen("")}
                  setter={setAdjustments}
                  label={`formTypes`}
                  clearInput={() =>
                    setInputs((prev) => ({ ...prev, formTypes: "" }))
                  }
                />
              </span>
              <label className="checkbox-container">
                Select all form types
                <input
                  type="checkbox"
                  checked={adjustments.formTypesSelectedAll}
                  onChange={() => {
                    setAdjustments((prev) => ({
                      ...prev,
                      formTypesSelectedAll: !prev.formTypesSelectedAll,
                    }));
                  }}
                />
                <span className="checkmark"></span>
              </label>
              {adjustments.formTypes.length ? (
                <section className="flex flex-wrap items-center gap-x-4 gap-y-2">
                  {adjustments.formTypes.map((formType, index) => (
                    <p
                      className={`px-2 py-1 rounded-2xl bg-white-mdShade w-fit flex items-center gap-2 ${
                        adjustments.formTypesSelectedAll
                          ? "opacity-50 strike cursor-not-allowed"
                          : ""
                      }`}
                      key={index}
                    >
                      {formType}
                      <span
                        className={`text-salmon ${
                          adjustments.formTypesSelectedAll
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        }`}
                        onClick={() =>
                          !adjustments.formTypesSelectedAll
                            ? setAdjustments((prev) => ({
                                ...prev,
                                formTypes: prev.formTypes.filter(
                                  (_, i) => i !== index
                                ),
                              }))
                            : {}
                        }
                      >
                        <IoClose />
                      </span>
                    </p>
                  ))}
                </section>
              ) : (
                !adjustments.formTypesSelectedAll && (
                  <p className="text-black-xsTint text-sm">
                    You have no form types
                  </p>
                )
              )}
              <div className="space-x-6 flex items-center">
                <button
                  className={`py-2 px-4 rounded-2xl font-primary transition-all duration-300 ${
                    adjustments.equityType === "screener"
                      ? "bg-lavender-primary text-white-primary font-semibold"
                      : "bg-lavender-smTint font-medium"
                  }`}
                  onClick={() =>
                    setAdjustments((prev) => ({
                      ...prev,
                      equityType: "screener",
                    }))
                  }
                >
                  Screener
                </button>
                <button
                  className={`py-2 px-4 rounded-2xl font-primary transition-all duration-300 ${
                    adjustments.equityType === "ticker"
                      ? "bg-lavender-primary text-white-primary  font-semibold"
                      : "bg-lavender-smTint font-medium"
                  }`}
                  onClick={() =>
                    setAdjustments((prev) => ({
                      ...prev,
                      equityType: "ticker",
                    }))
                  }
                >
                  Ticker
                </button>
              </div>

              {adjustments.equityType === "screener" ? (
                <span className="relative">
                  <div className="flex w-fit items-center rounded-2xl bg-white-smShade cursor-pointer">
                    <input
                      type="text"
                      value={adjustments.screener ? adjustments.screener : ""}
                      placeholder="Select"
                      className="w-full text-base cursor-pointer py-2 px-4"
                      readOnly
                      onClick={handleScreenerClick}
                    />
                    {adjustments.screener && (
                      <button
                        className="text-salmon size-10"
                        onClick={() =>
                          setAdjustments((prev) => ({ ...prev, screener: "" }))
                        }
                      >
                        <IoClose />
                      </button>
                    )}
                    <button className="size-10" onClick={handleScreenerClick}>
                      {dropdownOpen === "screener" ? (
                        <IoIosArrowUp />
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </button>
                  </div>
                  {errorTooltipVisible && (
                    <div className="absolute top-[125%] left-0 bg-salmon text-white-primary px-4 py-2 rounded-2xl z-50 font-primary font-medium">
                      No screeners found. Please create a screener first.
                    </div>
                  )}
                  <SelectorDropdown
                    isOpen={dropdownOpen === "screener"}
                    selections={screeners.map((screener) => screener.name)}
                    closeDropdown={() => setDropdownOpen("")}
                    setter={setAdjustments}
                    label={`screener`}
                    creatable={true}
                    createLink="/screeners-setup"
                  />
                </span>
              ) : (
                <>
                  <span className="relative">
                    <form
                      className="w-fit bg-white-smShade rounded-2xl"
                      onSubmit={(e) => handleAdd(e, "tickers")}
                    >
                      <input
                        className="text-base w-fit px-4 py-2"
                        type="text"
                        value={inputs.tickers}
                        onChange={(e) =>
                          setInputs((prev) => ({
                            ...prev,
                            tickers: e.target.value,
                          }))
                        }
                        placeholder="Tickers"
                      />
                      <button
                        type="submit"
                        className="py-2 px-4 rounded-2xl bg-lavender-primary text-white-primary disabled:bg-lavender-primary/50 disabled:cursor-not-allowed"
                        disabled={
                          adjustments.tickers.length >= 5 || !inputs.tickers
                        }
                      >
                        +
                      </button>
                    </form>
                    <SelectorDropdown
                      isOpen={
                        dropdownOpen === "tickers" &&
                        tickerDropdownValues.length
                      }
                      selections={tickerDropdownValues.filter(
                        (val) => !adjustments.tickers.includes(val)
                      )}
                      closeDropdown={() => setDropdownOpen("")}
                      setter={setAdjustments}
                      label={`tickers`}
                      clearInput={() =>
                        setInputs((prev) => ({ ...prev, tickers: "" }))
                      }
                    />
                  </span>
                  <section className="flex flex-wrap items-center gap-x-4 gap-y-2">
                    {adjustments.tickers.length ? (
                      adjustments.tickers.map((ticker, index) => (
                        <p
                          className="px-2 py-1 rounded-2xl bg-white-mdShade w-fit flex items-center gap-2"
                          key={index}
                        >
                          {ticker}
                          <span
                            className="text-salmon cursor-pointer"
                            onClick={() =>
                              setAdjustments((prev) => ({
                                ...prev,
                                tickers: prev.tickers.filter(
                                  (_, i) => i !== index
                                ),
                              }))
                            }
                          >
                            <IoClose />
                          </span>
                        </p>
                      ))
                    ) : (
                      <p className="text-black-xsTint text-sm">
                        You have no tickers
                      </p>
                    )}
                  </section>
                </>
              )}
            </main>
            <div className="lg:w-fit w-full ml-auto bg-white-xsShade rounded-2xl overflow-hidden h-fit flex items-center">
              <input
                type="text"
                className="lg:w-fit w-full px-4 py-2 text-base"
                placeholder="Alert title:"
                value={alertTitle}
                onChange={(e) => setAlertTitle(e.target.value)}
              />
              <button
                className="mx-auto bg-lavender-primary text-white-primary px-4 py-2 font-primary font-semibold rounded-2xl disabled:bg-lavender-primary/50 disabled:cursor-not-allowed"
                onClick={handleAlertSave}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <LoadingSpinner
                    size="size-4"
                    className="text-white-primary border-2"
                  />
                ) : type === "create" ? (
                  "Create"
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
});

export default AlertSetup;
