import React, { useEffect, useRef, useState } from "react";
import newsStore from "../stores/newsStore";
import "../styles/news.css";
import FilterButton from "./FilterButton";
import LoadingSpinner from "./LoadingSpinner";
import Table from "./Table";
import { getHexCode } from "../constants/filingHexcodes";
import { observer } from "mobx-react";

const NewsCard = observer(({ cik, financialData }) => {
  const dropdownsRef = useRef(null);
  const [showPrompt, setShowPrompt] = useState(false); // Add state for showing prompt

  // Handle CIK changes
  useEffect(() => {
    if (newsStore.cik !== cik) {
      newsStore.setCik(cik);
    }

    const handleClickOutsideDropdown = (e) => {
      if (dropdownsRef.current && !dropdownsRef.current.contains(e.target)) {
        newsStore.setCurrentlyOpenedFilter(""); // Close filter dropdown on outside click
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, [cik]);

  const handleFilterChange = async ({ name, value }) => {
    await newsStore.handleFilterChange(name, value);
  };

  const loadMore = async () => {
    await newsStore.loadMore();
  };

  const years = Array.from(
    { length: 2025 - financialData?.start_year },
    (_, i) => financialData?.start_year + i
  );
  const months = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  ).slice(
    newsStore.filter.year === financialData?.start_year
      ? financialData?.start_month - 1
      : 0,
    newsStore.filter.year === new Date().getFullYear()
      ? new Date().getMonth() + 1
      : 12
  );

  // Function to toggle the currently opened filter
  const toggleCurrentlyOpenedFilter = (filterName) => {
    if (newsStore.currentlyOpenedFilter === filterName) {
      newsStore.setCurrentlyOpenedFilter(""); // Close the dropdown if it's already open
    } else {
      newsStore.setCurrentlyOpenedFilter(filterName); // Open the dropdown
    }
  };

  return (
    <>
      <div className="flex items-center justify-between mt-[31px] mb-2 flex-wrap gap-1">
        <h3 className="text-black-primary text-lg md:text-2xl font-semibold text-left">
          News
        </h3>
        <div
          className="flex items-start md:gap-4 gap-2 flex-wrap"
          ref={dropdownsRef}
        >
          <FilterButton
            name="year"
            values={years.reverse()}
            value={newsStore.filter.year}
            clearValue={async (e) =>
              handleFilterChange({ name: "year", value: "" })
            }
            handleFilterChange={handleFilterChange}
            currentlyOpenedFilter={newsStore.currentlyOpenedFilter}
            setCurrentlyOpenedFilter={
              () => toggleCurrentlyOpenedFilter("year") // Use toggle function
            }
          />
          <FilterButton
            name="month"
            values={months.reverse()}
            value={newsStore.filter.month}
            clearValue={async (e) =>
              handleFilterChange({ name: "month", value: "" })
            }
            handleFilterChange={handleFilterChange}
            currentlyOpenedFilter={newsStore.currentlyOpenedFilter}
            setCurrentlyOpenedFilter={
              () => toggleCurrentlyOpenedFilter("month") // Use toggle function
            }
            disabled={!newsStore.filter.year} // Disable if no year selected
            showPrompt={showPrompt} // Pass showPrompt
            setShowPrompt={setShowPrompt} // Pass setShowPrompt
          />
        </div>
      </div>

      {newsStore.loading ? (
        <div className="loading-page-container">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Table
            data={newsStore.newsData.map((newsItem) => ({
              ...newsItem,
              url: `/${cik}/documents/${newsItem.accessionNo}?filing=${newsItem.form_type}`,
              pillCN: getHexCode(newsItem.form_type) || "#ebf38c",
              title:
                newsItem.title.split(" ").length > 8
                  ? newsItem.title.split(" ").slice(0, 8).join(" ") + "..."
                  : newsItem.title.split(" ").slice(0, 8).join(" "),
            }))}
            references={[
              {
                reference: "form_type",
                pill: true,
              },
              { reference: "title" },
              { reference: "filingDate", additionalCN: "text-right" },
            ]}
          />

          {newsStore.hasMoreData &&
            newsStore.newsData.length < newsStore.total && (
              <button
                className="news-load-more cursor-pointer hover:bg-lavender-smTint transition-all disabled:cursor-not-allowed"
                onClick={loadMore}
                disabled={newsStore.paginating}
              >
                <span
                  className={`news-load-more-btn ${
                    newsStore.paginating ? "loading" : ""
                  }`}
                >
                  {newsStore.paginating ? (
                    <LoadingSpinner size="w-4 h-4" className="border-2" />
                  ) : (
                    "+ Load More"
                  )}
                </span>
              </button>
            )}
        </>
      )}
    </>
  );
});

export default NewsCard;
