import React, { useEffect, useState } from "react";
import "../styles/dashboard.css";
import "../styles/searchbar.css";
import { fetchFinancials, getNotifications } from "../services/api";
import LoadingSpinner from "./LoadingSpinner";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getHexCode } from "../constants/cikHexcodes";
import LogoWithMenuToggle from "./LogoWithMenuToggle";
import { observer } from "mobx-react";
import financialStore from "../stores/financialStore";

const Notifications = observer(() => {
  const [notifications, setNotifications] = useState(null);
  const [navigationBarStore] = useOutletContext();
  const navigate = useNavigate();

  const handleNotificationClick = async (notification) => {
    let baseUrl = "https://app.unlevered.ai";
    financialStore.updateFinancialData(null);
    financialStore.toggleIsLoading();
    navigate(notification?.url?.replace(baseUrl, "") || "#");
    const data = await fetchFinancials(notification.cik);
    financialStore.updateFinancialData(data);
    financialStore.toggleIsLoading();
  };

  useEffect(() => {
    const fetchData = async () => {
      const notificationsData = await getNotifications();
      if (notificationsData?.notifications?.length) {
        setNotifications(notificationsData.notifications);
      } else {
        setNotifications([]);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <LogoWithMenuToggle navigationBarStore={navigationBarStore} />
      <div className="mt-[31px] medium-large:mt-[78px] mb-2">
        <h1 className="text-2xl font-bold text-start mb-2">Notifications</h1>
        <div>
          {!notifications ? (
            <div className="loading-page-container">
              <LoadingSpinner />
            </div>
          ) : !notifications.length ? (
            <div className="loading-page-container">
              <h3 className="text-black-mdTint">
                No notifications yet. Please click{" "}
                <a
                  href="/alerts-setup"
                  className="text-lavender-primary underline"
                >
                  create alert
                </a>{" "}
                to set up an alert
              </h3>
            </div>
          ) : (
            <div className="w-full h-full overflow-auto">
              <table>
                <tbody>
                  {notifications.map((notification, index) => (
                    <tr
                      className="hover:bg-lavender-smTint"
                      key={index}
                      onClick={() => handleNotificationClick(notification)}
                    >
                      <td
                        className={`py-3 md:px-4 px-2 pl-4 cursor-pointer ${
                          notification?.read ? "bg-white-smShade" : ""
                        }`}
                      >
                        <span
                          className="table-pill text-center"
                          style={{
                            backgroundColor: getHexCode(notification?.cik),
                          }}
                        >
                          {notification?.ticker || "-"}
                        </span>
                      </td>
                      <td
                        className={`py-3 md:px-4 px-2 cursor-pointer ${
                          notification?.read ? "bg-white-smShade" : ""
                        }`}
                      >
                        <span className="table-pill bg-[#ebf38c] text-center">
                          {notification?.alert_name || "-"}
                        </span>
                      </td>
                      <td
                        className={`py-3 px-4 cursor-pointer ${
                          notification?.read ? "bg-white-smShade" : ""
                        }`}
                      >
                        {notification?.title || "-"}
                      </td>
                      <td
                        className={`py-3 px-4 cursor-pointer text-right ${
                          notification?.read ? "bg-white-smShade" : ""
                        }`}
                      >
                        {notification?.date?.split(" ")[0] || "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default Notifications;
