import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { searchAcrossFilings, fetchFinancials } from "../services/api";
import LoadingSpinner from "./LoadingSpinner";
import HeaderNew from "./HeaderNew";
import SearchResultsSideBar from "./SearchResultsSideBar";
import DocumentsContentReplica from "./DocumentsContentReplica"; // Import the DocumentsContentReplica component
import { observer } from "mobx-react";
import navigationBarStore from "../stores/navigationBarStore";
import financialStore from "../stores/financialStore";

const SearchResultsPage = observer(() => {
  const navRef = useRef();
  const [searchParams] = useSearchParams();
  const cik = searchParams.get("cik");
  const phrase = searchParams.get("phrase");
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFiling, setSelectedFiling] = useState(null); // State to store selected filing details
  const [highlightedInstances, setHighlightedInstances] = useState([]); // State to store highlighted instances
  const [scrollToId, setScrollToId] = useState(null); // State to handle scrolling to specific ID
  const [loadingInstances, setLoadingInstances] = useState(false); // New state to manage loading

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        console.log("i ran");
        if (cik && !financialStore.financialData) {
          financialStore.toggleIsLoading();
          const data = await fetchFinancials(cik); // Fetch data based on cik
          financialStore.updateFinancialData(data);
          financialStore.toggleIsLoading();
        }
        const searchAcrossFilingsRes = await searchAcrossFilings(cik, phrase);
        setResults(searchAcrossFilingsRes.result || []);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [cik, phrase]);

  console.log(isLoading);

  const handleResultClick = async (accessionNo, formType) => {
    setHighlightedInstances([]); // Clear the old instances
    setLoadingInstances(true); // Start loading

    setSelectedFiling({ accessionNo, formType });
    setScrollToId(null);

    setLoadingInstances(false); // Stop loading once instances are fetched
  };

  const handleInstanceClick = (id) => {
    setScrollToId(id);
  };

  return (
    <>
      <nav
        ref={navRef}
        className={`fixed h-[100dvh] top-0 z-40 flex transition-all duration-300 medium-large:left-0 ${
          !navigationBarStore.isOpen ? "-left-full" : "left-0"
        }`}
      >
        <HeaderNew />
        <SearchResultsSideBar
          results={results}
          onResultClick={handleResultClick}
          highlightedInstances={highlightedInstances}
          onInstanceClick={handleInstanceClick}
          isLoading={isLoading}
          isLoadingInstances={loadingInstances} // Pass the loading state
          financialData={financialStore.financialData}
        />
      </nav>
      <div className="h-[100vh] w-full">
        {" "}
        {/* Add padding to shift content right */}
        {isLoading ? (
          <div className="flex justify-center items-center h-[100vh]">
            <LoadingSpinner />
          </div>
        ) : results.length === 0 ? (
          <div className="flex justify-center items-center h-[100vh] w-full px-8">
            <p className="text-black-mdTint" style={{ paddingLeft: "140px" }}>
              No results found for "{phrase}".
            </p>
          </div>
        ) : (
          <DocumentsContentReplica
            cik={cik} // Pass the CIK to DocumentsContentReplica
            accessionNo={selectedFiling?.accessionNo}
            form_type={selectedFiling?.formType}
            searchTerm={phrase}
            setSearchResults={setHighlightedInstances}
            scrollToId={scrollToId}
            setLoadingInstances={setLoadingInstances} // Pass the function to set loading state
          />
        )}
      </div>
    </>
  );
});

export default SearchResultsPage;
