import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import earningsStore from "../stores/earningsStore";
import FilterButton from "./FilterButton";
import Table from "./Table";
import LoadingSpinner from "./LoadingSpinner";
import "../styles/earnings.css";
import { observer } from "mobx-react";

const Earnings = observer(({ financialData }) => {
  const { cik } = useParams();
  const {
    transcriptsData,
    loading,
    year,
    currentlyOpenedFilter,
    setYear,
    setCurrentlyOpenedFilter,
  } = earningsStore;

  useEffect(() => {
    earningsStore.setCik(cik); // Reset state only if the cik changes
  }, [cik]);

  // Dynamically generate the year options based on start_year and end_year or current year
  const currentYear = new Date().getFullYear();
  const years = financialData
    ? Array.from(
        {
          length:
            (financialData.end_year || currentYear) -
            financialData.start_year +
            1,
        },
        (_, i) => financialData.start_year + i
      ).reverse()
    : []; // Default to an empty array if financialData is null or undefined

  const handleFilterChange = ({ name, value }) => {
    if (name === "year") {
      setYear(value); // This will trigger data fetching
    }
  };

  const clearFilter = () => {
    setYear("2024"); // Reset to default year
  };

  return (
    <>
      <div className="flex items-center justify-between mt-[31px] mb-2 flex-wrap gap-1">
        <h3 className="text-black-primary text-lg md:text-2xl font-semibold text-left">
          Earnings Transcripts
        </h3>
        <div className="flex items-start md:gap-4 gap-2 flex-wrap">
          <FilterButton
            name="year"
            values={years} // Dynamically generated years
            value={year}
            clearValue={clearFilter}
            handleFilterChange={handleFilterChange}
            currentlyOpenedFilter={currentlyOpenedFilter}
            setCurrentlyOpenedFilter={setCurrentlyOpenedFilter}
          />
        </div>
      </div>
      {loading ? (
        <div className="loading-page-container">
          <LoadingSpinner />
        </div>
      ) : (
        <Table
          titles={["Quarter", "Topic", "File Date"]}
          data={transcriptsData.map((transcript) => ({
            ...transcript,
            url: `/${cik}/earnings/${transcript.accessionNo}`,
            filedAt: transcript?.filedAt?.split(" ")[0],
          }))}
          references={[
            { reference: "quarter" },
            { reference: "topic" },
            { reference: "filedAt" },
          ]}
        />
      )}
    </>
  );
});

export default Earnings;
