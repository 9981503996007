import { FaLink } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { IoPerson } from "react-icons/io5";

export const allFilingSections = {
  "10-K": ["1", "1A", "1B", "2", "3", "4", "7", "9A", "9B"],
  "10-Q": [
    "part1item1",
    "part1item2",
    "part1item3",
    "part1item4",
    "part2item1",
    "part2item1a",
    "part2item4",
    "part2item5",
  ],
  misc: ["all"],
};

export const transactionCodeMap = {
  A: "Grant or Award",
  C: "Conversion",
  D: "Disposition",
  E: "Expiration",
  F: "Tax Payment",
  G: "Bona Fide Gift",
  H: "Cancellation of Derivative",
  I: "Discretionary Transaction",
  J: "Other Acquisition",
  L: "Small Acquisition",
  M: "Derivative Exercise",
  O: "Out-of-money Derivative",
  P: "Open Market Purchase",
  S: "Open Market Sale",
  U: "Tender",
  W: "Will",
  X: "In-the-money Derivative",
  Z: "Voting Trust",
};

export const filingSectionHeadings = {
  1: { titleCase: "Part 1" },
  "1A": { titleCase: "Part 1A" },
  "1B": { titleCase: "Part 1B" },
  2: { titleCase: "Part 2" },
  3: { titleCase: "Part 3" },
  4: { titleCase: "Part 4" },
  5: { titleCase: "Part 5" },
  6: { titleCase: "Part 6" },
  7: { titleCase: "Part 7" },
  8: { titleCase: "Part 8" },
  "9A": { titleCase: "Part 9A" },
  "9B": { titleCase: "Part 9B" },
  part1item1: { titleCase: "Part I Item 1" },
  part1item2: { titleCase: "Part I Item 2" },
  part1item3: { titleCase: "Part I Item 3" },
  part1item4: { titleCase: "Part I Item 4" },
  part2item1: { titleCase: "Part II Item 1" },
  part2item1a: { titleCase: "Part II Item 1A" },
  part2item2: { titleCase: "Part II Item 2" },
  part2item3: { titleCase: "Part II Item 3" },
  part2item4: { titleCase: "Part II Item 4" },
  part2item5: { titleCase: "Part II Item 5" },
};

export const userDetailsSettings = [
  {
    name: "name",
    label: "Name",
    disabled: true,
    icon: IoPerson,
  },
  {
    name: "email",
    label: "E-mail address",
    disabled: true,
    icon: IoMdMail,
  },
  {
    name: "joined_at",
    label: "First Joined",
    disabled: true,
    icon: FaLink,
  },
];

export const userDetails = {
  name: "Ray Chu",
  pfp: "https://lh3.googleusercontent.com/a/ACg8ocI0F1ZLI_V3ZdfSfZSskQ7YG0qMHBLqAy-P6OBttfmkOuTsLQ=s288-c-no",
  settings: [
    {
      name: "name",
      label: "Name",
      value: "Ray Chu",
      disabled: true,
      icon: IoPerson,
    },
    {
      name: "email",
      label: "E-mail address",
      value: "ray@unlevered.ai",
      disabled: true,
      icon: IoMdMail,
    },
    {
      name: "joined",
      label: "First Joined",
      value: "October 7 2023",
      disabled: true,
      icon: FaLink,
    },
  ],
  subscription: "Starter Plan",
};

export const pricingPlans = [
  {
    name: "Starter Plan",
    monthlyCost: 0,
    benefits: [
      "Lorem ipsum dolor sit amet, consectetur ",
      "Adipiscing elit, sed do eiusmod tempor",
      "Incididunt ut labore et dolore magna aliqua.",
    ],
  },
  {
    name: "Professional Plan",
    monthlyCost: 15,
    benefits: [
      "Ut enim ad minim veniam, quis",
      "Nostrud exercitation ullamco laboris nisi ut",
      "Aliquip ex ea commodo consequat. Duis ",
      "Aute irure dolor in reprehenderit in ",
      "Velit esse cillum dolore eu fugiat nulla",
    ],
  },
  {
    name: "Enterprise Plan",
    monthlyCost: 30,
    benefits: [
      "Excepteur sint occaecat cupidatat non",
      "Proident, sunt in culpa qui officia",
      "Deserunt mollit anim id est laborum.",
      "Sed ut perspiciatis unde omnis iste natus",
      "Error sit voluptatem accusantium",
      "Doloremque laudantium, totam rem",
      "Aperiam, eaque ipsa quae ab illo inventore",
    ],
  },
];

export const formatNumberWithPrefix = (value, prefix) => {
  const formattedNumber = Math.abs(value).toLocaleString();
  return (value < 0 ? `-${prefix}` : `${prefix}`) + formattedNumber;
};

export const suffixMap = { M: 6, B: 9, T: 12 };

export const convertMoneySuffixToMoney = (value, suffix) => {
  if (value === "" || !suffix) {
    return value;
  }
  return String(Number(value) * Math.pow(10, suffixMap[suffix]));
};

export const convertMoneyToMoneySuffix = (value) => {
  let suffix = "";
  let newValue = value;

  const absValue = Math.abs(value);

  if (absValue >= Math.pow(10, suffixMap["T"])) {
    newValue = value / Math.pow(10, suffixMap["T"]);
    suffix = "T";
  } else if (absValue >= Math.pow(10, suffixMap["B"])) {
    newValue = value / Math.pow(10, suffixMap["B"]);
    suffix = "B";
  } else if (absValue >= Math.pow(10, suffixMap["M"])) {
    newValue = value / Math.pow(10, suffixMap["M"]);
    suffix = "M";
  }

  return { value: newValue, suffix: suffix || "M" };
};

export const enforceMinMax = (value, suffix, min, max, sign) => {
  // Only updates the value with the suffix does not update the signs*
  if (value < 0 || isNaN(value)) {
    return { value: 0 };
  } else if (
    Number(
      `${sign === "-" ? "-" : ""}${convertMoneySuffixToMoney(
        Number(value),
        suffix
      )}`
    ) < Number(min)
  ) {
    console.log("case 1");
    return suffix
      ? {
          value: Math.abs(convertMoneyToMoneySuffix(min).value),
          suffix: convertMoneyToMoneySuffix(min).suffix,
        }
      : { value: Math.abs(min) };
  } else if (
    Number(
      `${sign === "-" ? "-" : ""}${convertMoneySuffixToMoney(
        Number(value),
        suffix
      )}`
    ) > Number(max)
  ) {
    console.log("case 2");
    return suffix ? convertMoneyToMoneySuffix(max) : { value: max };
  } else {
    console.log("case 3");
    return { value, suffix };
  }
};

export const defaultAlertAdjustments = {
  queries: [],
  formTypes: [],
  formTypesSelectedAll: false,
  tickers: [],
  screener: "",
  equityType: "screener",
};

export const defaultScreenerAdjustments = {
  sector: { value: "", type: "dropdown", titleCase: "Sector" },
  industry: { value: "", type: "dropdown", titleCase: "Industry" },
  market_cap: {
    type: "dollar",
    titleCase: "Market Cap",
    minValue: { value: "", suffix: "M" },
    maxValue: { value: "", suffix: "M" },
    min: "0",
    max: convertMoneySuffixToMoney(100, "T"),
  },
  price: {
    type: "dollar",
    titleCase: "Price",
    minValue: { value: "", suffix: undefined },
    maxValue: { value: "", suffix: undefined },
    min: "0",
    max: convertMoneySuffixToMoney(1, "M"),
  },
  volume: {
    type: "number",
    titleCase: "Average Volume",
    minValue: { value: "", suffix: undefined },
    maxValue: { value: "", suffix: undefined },
    min: "0",
    max: convertMoneySuffixToMoney(10, "B"),
  },
  pe: {
    type: "number",
    titleCase: "Price to Earnings Ratio",
    minValue: { value: "", suffix: undefined },
    maxValue: { value: "", suffix: undefined },
    min: "0",
    max: "10000",
  },
  peg: {
    type: "number",
    titleCase: "Price Earnings to Growth Ratio",
    minValue: { value: "", suffix: undefined },
    maxValue: { value: "", suffix: undefined },
    min: "0",
    max: "10000",
  },
  ps: {
    type: "number",
    titleCase: "Price to Sales Ratio",
    minValue: { value: "", suffix: undefined },
    maxValue: { value: "", suffix: undefined },
    min: "0",
    max: "10000",
  },
  pb: {
    type: "number",
    titleCase: "Price to Book Ratio",
    minValue: { value: "", suffix: undefined },
    maxValue: { value: "", suffix: undefined },
    min: "0",
    max: "10000",
  },
  roe: {
    type: "percent",
    titleCase: "Return on Equity",
    minValue: { value: "", suffix: undefined, sign: "+" },
    maxValue: { value: "", suffix: undefined, sign: "+" },
    min: "-200",
    max: "500",
  },
  roa: {
    type: "percent",
    titleCase: "Return on Assets",
    minValue: { value: "", suffix: undefined, sign: "+" },
    maxValue: { value: "", suffix: undefined, sign: "+" },
    min: "-200",
    max: "500",
  },
  current_ratio: {
    type: "number",
    titleCase: "Current Ratio",
    minValue: { value: "", suffix: undefined },
    maxValue: { value: "", suffix: undefined },
    min: "0",
    max: "10000",
  },
  debt_equity: {
    type: "number",
    titleCase: "Debt to Equity Ratio",
    minValue: { value: "", suffix: undefined },
    maxValue: { value: "", suffix: undefined },
    min: "0",
    max: "10000",
  },
  net_margin: {
    type: "percent",
    titleCase: "Net Profit Margin",
    minValue: { value: "", suffix: undefined },
    maxValue: { value: "", suffix: undefined },
    min: "0",
    max: "100",
  },
  operating_margin: {
    type: "percent",
    titleCase: "Operating Profit Margin",
    minValue: { value: "", suffix: undefined },
    maxValue: { value: "", suffix: undefined },
    min: "0",
    max: "100",
  },
  gross_margin: {
    type: "percent",
    titleCase: "Gross Profit Margin",
    minValue: { value: "", suffix: undefined },
    maxValue: { value: "", suffix: undefined },
    min: "0",
    max: "100",
  },
  revenue: {
    type: "dollar",
    titleCase: "Revenue",
    minValue: { value: "", suffix: "M" },
    maxValue: { value: "", suffix: "M" },
    min: "0",
    max: convertMoneySuffixToMoney(100, "T"),
  },
  gross_profit: {
    type: "dollar",
    titleCase: "Gross Profit",
    minValue: { value: "", suffix: "M", sign: "+" },
    maxValue: { value: "", suffix: "M", sign: "+" },
    min: `-${convertMoneySuffixToMoney(100, "T")}`,
    max: convertMoneySuffixToMoney(100, "T"),
  },
  operating_profit: {
    type: "dollar",
    titleCase: "Operating Income",
    minValue: { value: "", suffix: "M", sign: "+" },
    maxValue: { value: "", suffix: "M", sign: "+" },
    min: `-${convertMoneySuffixToMoney(100, "T")}`,
    max: convertMoneySuffixToMoney(100, "T"),
  },
  net_profit: {
    type: "dollar",
    titleCase: "Net Income",
    minValue: { value: "", suffix: "M", sign: "+" },
    maxValue: { value: "", suffix: "M", sign: "+" },
    min: `-${convertMoneySuffixToMoney(100, "T")}`,
    max: convertMoneySuffixToMoney(100, "T"),
  },
  eps: {
    type: "dollar",
    titleCase: "Earnings Per Share",
    minValue: { value: "", suffix: "M", sign: "+" },
    maxValue: { value: "", suffix: "M", sign: "+" },
    min: `-${convertMoneySuffixToMoney(100, "T")}`,
    max: convertMoneySuffixToMoney(100, "T"),
  },
  ebitda: {
    type: "dollar",
    titleCase: "EBITDA",
    minValue: { value: "", suffix: "M", sign: "+" },
    maxValue: { value: "", suffix: "M", sign: "+" },
    min: `-${convertMoneySuffixToMoney(100, "T")}`,
    max: convertMoneySuffixToMoney(100, "T"),
  },
  assets: {
    type: "dollar",
    titleCase: "Total Assets",
    minValue: { value: "", suffix: "M", sign: "+" },
    maxValue: { value: "", suffix: "M", sign: "+" },
    min: `-${convertMoneySuffixToMoney(100, "T")}`,
    max: convertMoneySuffixToMoney(100, "T"),
  },
  liabilities: {
    type: "dollar",
    titleCase: "Total Liabilities",
    minValue: { value: "", suffix: "M", sign: "+" },
    maxValue: { value: "", suffix: "M", sign: "+" },
    min: `-${convertMoneySuffixToMoney(100, "T")}`,
    max: convertMoneySuffixToMoney(100, "T"),
  },
  total_equity: {
    type: "dollar",
    titleCase: "Total Equity",
    minValue: { value: "", suffix: "M", sign: "+" },
    maxValue: { value: "", suffix: "M", sign: "+" },
    min: `-${convertMoneySuffixToMoney(100, "T")}`,
    max: convertMoneySuffixToMoney(100, "T"),
  },
  cash: {
    type: "dollar",
    titleCase: "Cash",
    minValue: { value: "", suffix: "M", sign: "+" },
    maxValue: { value: "", suffix: "M", sign: "+" },
    min: `-${convertMoneySuffixToMoney(100, "T")}`,
    max: convertMoneySuffixToMoney(100, "T"),
  },
  st_debt: {
    type: "dollar",
    titleCase: "Short-Term Debt",
    minValue: { value: "", suffix: "M", sign: "+" },
    maxValue: { value: "", suffix: "M", sign: "+" },
    min: `-${convertMoneySuffixToMoney(100, "T")}`,
    max: convertMoneySuffixToMoney(100, "T"),
  },
  lt_debt: {
    type: "dollar",
    titleCase: "Long-Term Debt",
    minValue: { value: "", suffix: "M", sign: "+" },
    maxValue: { value: "", suffix: "M", sign: "+" },
    min: `-${convertMoneySuffixToMoney(100, "T")}`,
    max: convertMoneySuffixToMoney(100, "T"),
  },
  net_change_in_cash: {
    type: "dollar",
    titleCase: "Net Change in Cash",
    minValue: { value: "", suffix: "M", sign: "+" },
    maxValue: { value: "", suffix: "M", sign: "+" },
    min: `-${convertMoneySuffixToMoney(100, "T")}`,
    max: convertMoneySuffixToMoney(100, "T"),
  },
  free_cash_flow: {
    type: "dollar",
    titleCase: "Free Cash Flow",
    minValue: { value: "", suffix: "M", sign: "+" },
    maxValue: { value: "", suffix: "M", sign: "+" },
    min: `-${convertMoneySuffixToMoney(100, "T")}`,
    max: convertMoneySuffixToMoney(100, "T"),
  },
  da: {
    type: "dollar",
    titleCase: "Depreciation and Amortization",
    minValue: { value: "", suffix: "M", sign: "+" },
    maxValue: { value: "", suffix: "M", sign: "+" },
    min: `-${convertMoneySuffixToMoney(100, "T")}`,
    max: convertMoneySuffixToMoney(100, "T"),
  },
};

export const sectorIndustries = {
  "Basic Materials": {
    color: "#D2B4A1",
    industries: {
      Steel: "#C4A4A4",
      Silver: "#D6C8D2",
      "Other Precious Metals": "#C9D0C6",
      Gold: "#E3D8A5",
      Copper: "#D7AFA6",
      Aluminum: "#C5C9D6",
      "Paper, Lumber & Forest Products": "#D1C2A1",
      "Industrial Materials": "#B9D3D1",
      "Construction Materials": "#E6D7A7",
      "Chemicals - Specialty": "#D9CAD2",
      Chemicals: "#D2C9CA",
      "Agricultural Inputs": "#C9E3B7",
    },
  },
  "Communication Services": {
    color: "#A3C7E9",
    industries: {
      "Telecommunications Services": "#AEB9D7",
      "Internet Content & Information": "#C2D6E1",
      Publishing: "#D3B9C4",
      Broadcasting: "#D8C5D9",
      "Advertising Agencies": "#D6B7C6",
      Entertainment: "#E4C5D1",
    },
  },
  "Consumer Cyclical": {
    color: "#FBC488",
    industries: {
      "Travel Lodging": "#D7C2A8",
      "Travel Services": "#C5D8D4",
      "Specialty Retail": "#F4D8B5",
      "Luxury Goods": "#E2C6C3",
      "Home Improvement": "#D4C3A3",
      "Residential Construction": "#D8C4A8",
      "Department Stores": "#F2D7A7",
      "Personal Products & Services": "#D2D1D6",
      Leisure: "#F2D8A6",
      "Gambling, Resorts & Casinos": "#E0C3C4",
      "Furnishings, Fixtures & Appliances": "#D1B5A7",
      Restaurants: "#F6C9A6",
      "Auto - Parts": "#D6C4D0",
      "Auto - Manufacturers": "#D8C7C4",
      "Auto - Recreational Vehicles": "#D3D8C9",
      "Auto - Dealerships": "#E7D3A8",
      "Apparel - Retail": "#F1D6C4",
      "Apparel - Manufacturers": "#F3D8B6",
      "Apparel - Footwear & Accessories": "#E6C6C7",
      "Packaging & Containers": "#E4D1B1",
    },
  },
  "Consumer Defensive": {
    color: "#C7EFCF",
    industries: {
      Tobacco: "#D8C1A8",
      "Grocery Stores": "#C8D8C1",
      "Discount Stores": "#EAD7A5",
      "Household & Personal Products": "#D7C6B4",
      "Packaged Foods": "#F3D4B4",
      "Food Distribution": "#D3C4A8",
      "Food Confectioners": "#EBD7B5",
      "Agricultural Farm Products": "#D2E1B7",
      "Education & Training Services": "#C4D6D5",
      "Beverages - Wineries & Distilleries": "#D8B7C8",
      "Beverages - Non-Alcoholic": "#C3D6D9",
      "Beverages - Alcoholic": "#D6C7C4",
    },
  },
  Energy: {
    color: "#F7A397",
    industries: {
      Uranium: "#B9D3D6",
      Solar: "#E6D7A7",
      "Oil & Gas Refining & Marketing": "#D5B4A8",
      "Oil & Gas Midstream": "#D8C9C4",
      "Oil & Gas Integrated": "#D4B7B6",
      "Oil & Gas Exploration & Production": "#E6C5B8",
      "Oil & Gas Equipment & Services": "#D3D4D5",
      "Oil & Gas Energy": "#D3B7A7",
      "Oil & Gas Drilling": "#D9C4C1",
      Coal: "#C5C7C6",
    },
  },
  "Financial Services": {
    color: "#99A4E9",
    industries: {
      "Shell Companies": "#E3C6B5",
      "Investment - Banking & Investment Services": "#B5C4D6",
      "Insurance - Specialty": "#C7D5E3",
      "Insurance - Reinsurance": "#C3D3E6",
      "Insurance - Property & Casualty": "#B5D1D8",
      "Insurance - Life": "#B9C3D4",
      "Insurance - Diversified": "#B6D4D8",
      "Insurance - Brokers": "#D3E0D8",
      "Financial - Mortgages": "#C4D1E6",
      "Financial - Diversified": "#D1C3C9",
      "Financial - Data & Stock Exchanges": "#D1C9D3",
      "Financial - Credit Services": "#D1B4B8",
      "Financial - Conglomerates": "#C8D4E6",
      "Financial - Capital Markets": "#C4D6D3",
      "Banks - Regional": "#C6D4D6",
      "Banks - Diversified": "#C1D1E3",
      Banks: "#D6B9B9",
      "Asset Management": "#B4C8D4",
      "Asset Management - Bonds": "#B9D4E6",
      "Asset Management - Income": "#C8C9C8",
      "Asset Management - Leveraged": "#D1D6E3",
      "Asset Management - Cryptocurrency": "#D8C3C6",
      "Asset Management - Global": "#C1B9D4",
    },
  },
  Healthcare: {
    color: "#C5E7F1",
    industries: {
      "Medical - Specialties": "#D4C7D8",
      "Medical - Pharmaceuticals": "#C3D5D8",
      "Medical - Instruments & Supplies": "#D1C7C9",
      "Medical - Healthcare Plans": "#D3B4C8",
      "Medical - Healthcare Information Services": "#D9C7D5",
      "Medical - Equipment & Services": "#D1D6D9",
      "Medical - Distribution": "#B8C8C9",
      "Medical - Diagnostics & Research": "#D4C1C1",
      "Medical - Devices": "#D6D4C8",
      "Medical - Care Facilities": "#D6B4B4",
      "Drug Manufacturers - Specialty & Generic": "#B8C6C6",
      "Drug Manufacturers - General": "#C4D6D1",
      Biotechnology: "#C7D3D9",
    },
  },
  Industrials: {
    color: "#C6C6C6",
    industries: {
      "Waste Management": "#D6D1B6",
      Trucking: "#C8C6D8",
      Railroads: "#B4B6D1",
      "Aerospace & Defense": "#C6B8D1",
      "Marine Shipping": "#D6D8B9",
      "Integrated Freight & Logistics": "#D1D3D4",
      "Airlines, Airports & Air Services": "#C9D6D1",
      "General Transportation": "#D3D4D1",
      "Manufacturing - Tools & Accessories": "#D1B8B6",
      "Manufacturing - Textiles": "#D4D1D8",
      "Manufacturing - Miscellaneous": "#D4C6C1",
      "Manufacturing - Metal Fabrication": "#C3D4C6",
      "Industrial - Distribution": "#D8C6C3",
      "Industrial - Specialties": "#D3B9B6",
      "Industrial - Pollution & Treatment Controls": "#D3D1D4",
      "Environmental Services": "#D3D1B4",
      "Industrial - Machinery": "#D1C3D1",
      "Industrial - Infrastructure Operations": "#D4C1C1",
      "Industrial - Capital Goods": "#C1C3D3",
      "Consulting Services": "#C8B6D3",
      "Business Equipment & Supplies": "#D1C4D1",
      "Staffing & Employment Services": "#D1D3C8",
      "Rental & Leasing Services": "#C1D1D1",
      "Engineering & Construction": "#D4B6B4",
      "Security & Protection Services": "#C3B4C4",
      "Specialty Business Services": "#D4C9D4",
      Construction: "#C9C6D3",
      Conglomerates: "#C1B9C6",
      "Electrical Equipment & Parts": "#D8C4C9",
      "Farm & Heavy Construction Machinery": "#C8B4D4",
    },
  },
  "Real Estate": {
    color: "#F6D8B7",
    industries: {
      "REIT - Specialty": "#D1C3C9",
      "REIT - Retail": "#D6C1D1",
      "REIT - Residential": "#C1D3D4",
      "REIT - Office": "#C6C9D6",
      "REIT - Mortgage": "#C8D6D3",
      "REIT - Industrial": "#D4C3B4",
      "REIT - Healthcare Facilities": "#C9D1D6",
      "Real Estate - Services": "#C3C8D4",
      "Real Estate - Diversified": "#D6D3C9",
      "Real Estate - Development": "#D4D6B9",
      "Real Estate - Commercial": "#C4C1D4",
    },
  },
  Technology: {
    color: "#C7D6F1",
    industries: {
      "Software - Application": "#D8C4C6",
      "Software - Infrastructure": "#D3D1C4",
      Solar: "#C9D6C4",
      Semiconductors: "#D4C3D1",
      "Information Technology Services": "#D3D8C4",
      "Electronics & Computer Distribution": "#C1C4D3",
      "Communication Equipment": "#C6B9D8",
      "Computer Systems": "#D8B9D3",
      "Electronic Components": "#D6D4D1",
      "Data Storage": "#C4C9D4",
      "Contract Electronics Manufacturing": "#D6B4D1",
      "Consumer Electronics": "#D4D3D3",
      Computers: "#D4D6D6",
    },
  },
  Utilities: {
    color: "#C7D7A7",
    industries: {
      "Utilities - Regulated Water": "#D3D8D1",
      "Utilities - Regulated Gas": "#D3C1C1",
      "Utilities - Regulated Electric": "#D3C1D3",
      "Utilities - Renewable": "#D4C3D4",
      "Utilities - Independent Power Producers": "#C9D3C1",
    },
  },
};

export const formTypes = [
  "1",
  "1-A",
  "1-A POS",
  "1-A-W",
  "1-E",
  "1-E AD",
  "1-K",
  "1-SA",
  "1-U",
  "1-Z",
  "1-Z-W",
  "10-12B",
  "10-12G",
  "10-C",
  "10-D",
  "10-K",
  "10-K/A",
  "10-K405",
  "10-K405/A",
  "10-KSB",
  "10-KSB/A",
  "10-KT",
  "10-KT/A",
  "10-Q",
  "10-Q/A",
  "10-QSB",
  "10-QT",
  "10-QT/A",
  "10KSB",
  "10KSB/A",
  "10KSB40",
  "10KSB40/A",
  "10KT405",
  "10KT405/A",
  "10QSB",
  "10QSB/A",
  "10SB12B",
  "10SB12G",
  "11-K",
  "11-KT",
  "12G3-2B",
  "12G32BR",
  "13F-E",
  "13F-HR",
  "13F-NT",
  "13FCONP",
  "144",
  "15-12B",
  "15-12G",
  "15-15D",
  "15F-12B",
  "15F-12G",
  "15F-15D",
  "18-12B",
  "18-K",
  "19B-4E",
  "2-A",
  "2-AF",
  "2-E",
  "20-F",
  "20-F/A",
  "20FR12B",
  "20FR12G",
  "24F-1",
  "24F-2EL",
  "24F-2NT",
  "24F-2TM",
  "25",
  "25-NSE",
  "253G1",
  "253G2",
  "253G3",
  "253G4",
  "3",
  "305B2",
  "34-12H",
  "35-APP",
  "35-CERT",
  "39-304D",
  "39-310B",
  "4",
  "40-17F1",
  "40-17F2",
  "40-17G",
  "40-17GCS",
  "40-202A",
  "40-203A",
  "40-205E",
  "40-206A",
  "40-24B2",
  "40-33",
  "40-6B",
  "40-6C",
  "40-8B25",
  "40-8F-2",
  "40-8F-A",
  "40-8F-B",
  "40-8F-L",
  "40-8F-M",
  "40-8FC",
  "40-APP",
  "40-F",
  "40-F/A",
  "40-OIP",
  "40-RPT",
  "40FR12B",
  "40FR12G",
  "424A",
  "424B1",
  "424B2",
  "424B3",
  "424B4",
  "424B5",
  "424B7",
  "424B8",
  "424H",
  "425",
  "485A24E",
  "485A24F",
  "485APOS",
  "485B24E",
  "485B24F",
  "485BPOS",
  "485BXT",
  "485BXTF",
  "486A24E",
  "486APOS",
  "486B24E",
  "486BPOS",
  "486BXT",
  "487",
  "497",
  "497AD",
  "497H2",
  "497J",
  "497K",
  "497K1",
  "497K2",
  "497K3A",
  "497K3B",
  "497VPI",
  "497VPU",
  "5",
  "6-K",
  "6-K/A",
  "6B NTC",
  "6B ORDR",
  "8-A12B",
  "8-A12G",
  "8-B12B",
  "8-B12G",
  "8-K",
  "8-K/A",
  "8-K12B",
  "8-K12G3",
  "8-K15D5",
  "8-M",
  "8-M Filing",
  "8A12BEF",
  "8A12BT",
  "8F-2 NTC",
  "8F-2 ORDR",
  "9-M",
  "9-M Filing",
  "ABS-15G",
  "ABS-EE",
  "ADB",
  "ADN-MTL",
  "ADN-MTL Filing",
  "ADV",
  "ADV-E",
  "ADV-E Filing",
  "ADV-H-C",
  "ADV-H-T",
  "ADV-NR",
  "ADV-NR Filing",
  "ADVCO",
  "ADVW",
  "AFDB",
  "ANNLRPT",
  "APP NTC",
  "APP ORDR",
  "APP WD",
  "APP WD Filing",
  "APP WDG",
  "ARS",
  "ATS-N",
  "ATS-N-C",
  "ATS-N-W",
  "ATS-N/CA",
  "ATS-N/MA",
  "ATS-N/UA",
  "AW",
  "AW WD",
  "BDCO",
  "BW-2",
  "BW-3",
  "C",
  "C-AR",
  "C-AR-W",
  "C-TR",
  "C-TR-W",
  "C-U",
  "C-U-W",
  "C-W",
  "CANCELLATION-MA",
  "CB",
  "CERT",
  "CERTAMX",
  "CERTARCA",
  "CERTBATS",
  "CERTBSE",
  "CERTCBO",
  "CERTCSE",
  "CERTNAS",
  "CERTNYS",
  "CERTPAC",
  "CERTPBS",
  "CFPORTAL",
  "CFPORTAL-W",
  "CORRESP",
  "CT ORDER",
  "D",
  "DEF 14A",
  "DEF 14C",
  "DEF-OC",
  "DEF13E3",
  "DEFA14A",
  "DEFA14C",
  "DEFC14A",
  "DEFC14C",
  "DEFM14A",
  "DEFM14C",
  "DEFN14A",
  "DEFR14A",
  "DEFR14C",
  "DEFS14A",
  "DEFS14C",
  "DEL AM",
  "DFAN14A",
  "DFRN14A",
  "DOS",
  "DOSLTR",
  "DRS",
  "DRSLTR",
  "DSTRBRPT",
  "EBRD",
  "EFFECT",
  "F-1",
  "F-10",
  "F-10EF",
  "F-10POS",
  "F-1MEF",
  "F-2",
  "F-3",
  "F-3ASR",
  "F-3D",
  "F-3DPOS",
  "F-3MEF",
  "F-4",
  "F-4 POS",
  "F-4EF",
  "F-4MEF",
  "F-6",
  "F-6 POS",
  "F-6EF",
  "F-7",
  "F-7 POS",
  "F-8",
  "F-8 POS",
  "F-80",
  "F-80POS",
  "F-9",
  "F-9 POS",
  "F-9EF",
  "F-N",
  "F-X",
  "FOCUSN",
  "FWP",
  "G-405",
  "G-405N",
  "G-FIN",
  "G-FINW",
  "IADB",
  "ID-NEWCIK",
  "IFC",
  "IRANNOTICE",
  "MA",
  "MA-A",
  "MA-I",
  "MA-W",
  "MSD",
  "MSDCO",
  "MSDW",
  "MSDW Filing",
  "N-1",
  "N-14",
  "N-14 8C",
  "N-14AE",
  "N-14MEF",
  "N-18F1",
  "N-1A",
  "N-1A EL",
  "N-2",
  "N-2 POSASR",
  "N-23C-1",
  "N-23C-2",
  "N-23C3A",
  "N-23C3B",
  "N-23C3C",
  "N-27D-1",
  "N-2ASR",
  "N-2MEF",
  "N-3",
  "N-3 EL",
  "N-30B-2",
  "N-30D",
  "N-4",
  "N-4 EL",
  "N-5",
  "N-54A",
  "N-54C",
  "N-6",
  "N-6F",
  "N-8A",
  "N-8B-2",
  "N-8B-4",
  "N-8F",
  "N-8F NTC",
  "N-8F ORDR",
  "N-CEN",
  "N-CR",
  "N-CSR",
  "N-CSRS",
  "N-MFP",
  "N-MFP1",
  "N-MFP2",
  "N-PX",
  "N-Q",
  "N-VP",
  "N-VPFS",
  "N14AE24",
  "N14EL24",
  "NO ACT",
  "NPORT-EX",
  "NPORT-P",
  "NRSRO-CE",
  "NRSRO-UPD",
  "NSAR-A",
  "NSAR-AT",
  "NSAR-B",
  "NSAR-BT",
  "NSAR-U",
  "NT 10-D",
  "NT 10-K",
  "NT 10-K/A",
  "NT 10-Q",
  "NT 10-Q/A",
  "NT 11-K",
  "NT 15D2",
  "NT 20-F",
  "NT N-CEN",
  "NT N-MFP",
  "NT N-MFP1",
  "NT N-MFP2",
  "NT NPORT-EX",
  "NT NPORT-P",
  "NT-NCEN",
  "NT-NCSR",
  "NT-NSAR",
  "NTFNCEN",
  "NTFNCSR",
  "NTFNSAR",
  "NTN 10D",
  "NTN 10K",
  "NTN 10Q",
  "NTN 11K",
  "NTN 20F",
  "NTN15D2",
  "OIP NTC",
  "OIP ORDR",
  "POS 8C",
  "POS AM",
  "POS AMC",
  "POS AMI",
  "POS EX",
  "POS462B",
  "POS462C",
  "POSASR",
  "PRE 14A",
  "PRE 14C",
  "PRE13E3",
  "PREA14A",
  "PREA14C",
  "PREC14A",
  "PREC14C",
  "PREM14A",
  "PREM14C",
  "PREN14A",
  "PRER14A",
  "PRER14C",
  "PRES14A",
  "PRES14C",
  "PRRN14A",
  "PX14A6G",
  "PX14A6N",
  "QRTLYRPT",
  "QUALIF",
  "REG-NR",
  "REGDEX",
  "REVOCATION-MA",
  "REVOKED",
  "RW",
  "RW WD",
  "S-1",
  "S-11",
  "S-11MEF",
  "S-1MEF",
  "S-2",
  "S-20",
  "S-2MEF",
  "S-3",
  "S-3ASR",
  "S-3D",
  "S-3DPOS",
  "S-3MEF",
  "S-4",
  "S-4 POS",
  "S-4EF",
  "S-4MEF",
  "S-6",
  "S-6EL24",
  "S-8",
  "S-8 POS",
  "S-B",
  "S-BMEF",
  "SB-1",
  "SB-1MEF",
  "SB-2",
  "SB-2MEF",
  "SBSE",
  "SBSE-A",
  "SBSE-BD",
  "SBSE-C",
  "SC 13D",
  "SC 13E1",
  "SC 13E3",
  "SC 13E4",
  "SC 13G",
  "SC 14D1",
  "SC 14D9",
  "SC 14F1",
  "SC 14N",
  "SC TO-C",
  "SC TO-I",
  "SC TO-T",
  "SC13E4F",
  "SC14D1F",
  "SC14D9C",
  "SC14D9F",
  "SD",
  "SDR",
  "SE",
  "SEC ACTION",
  "SEC STAFF",
  "LETTER",
  "SF-1",
  "SF-3",
  "SP 15D2",
  "SUPPL",
  "T-3",
  "T-6",
  "TA-1",
  "TA-2",
  "TA-W",
  "TACO",
  "TH",
  "TTW",
  "U-1",
  "U-12-IA",
  "U-12-IB",
  "U-13-60",
  "U-13E-1",
  "U-33-S",
  "U-3A-2",
  "U-3A3-1",
  "U-57",
  "U-6B-2",
  "U-7D",
  "U-9C-3",
  "U5A",
  "U5B",
  "U5S",
  "UNDER",
  "UPLOAD",
  "WDL-REQ",
  "X-17A-5",
];

export const fakeScreeners = [
  "Screener 1",
  "Screener 2",
  "Screener 3",
  "Screener 4",
  "Screener 5",
];
