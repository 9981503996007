import { makeAutoObservable } from "mobx";
import { fetchAllFilings } from "../services/api";

class AllFilingsStore {
  allFilingsData = [];
  page = 1;
  total = 0;
  filter = { year: "", month: "", formType: "" };
  loading = false;
  paginating = false;
  hasMoreData = true;
  currentlyOpenedFilter = "";
  cik = "";

  constructor() {
    makeAutoObservable(this);
  }

  setCik(cik) {
    this.cik = cik;
    this.resetState();
  }

  resetState() {
    this.allFilingsData = [];
    this.page = 1;
    this.total = 0;
    this.filter = { year: "", month: "", formType: "" };
    this.hasMoreData = true;
    this.fetchAllFilingsData();
  }

  setCurrentlyOpenedFilter(name) {
    this.currentlyOpenedFilter = name;
  }

  async fetchAllFilingsData() {
    this.loading = true;
    let combinedData = [];
    let total = 0;

    try {
      for (let i = 1; i <= this.page; i++) {
        const filings = await fetchAllFilings(
          this.cik,
          i,
          this.filter.formType,
          this.filter.year,
          this.filter.month
        );
        if (filings && filings.data) {
          combinedData = [...combinedData, ...filings.data];
          total = filings.total;
        }
      }
      this.allFilingsData = combinedData;
      this.total = total;
      this.hasMoreData = combinedData.length < total;
    } catch (error) {
      console.error("Error fetching filings data:", error);
      this.allFilingsData = [];
      this.total = 0;
      this.hasMoreData = false;
    } finally {
      this.loading = false;
    }
  }

  async handleFilterChange(name, value) {
    this.loading = true;
    const updatedFilter = { ...this.filter, [name]: value };
    if (name === "year" && value === "") {
      updatedFilter.month = "";
    }
    this.filter = updatedFilter;
    this.page = 1;

    try {
      const filings = await fetchAllFilings(
        this.cik,
        1,
        this.filter.formType,
        this.filter.year,
        this.filter.month
      );
      if (filings && filings.data) {
        this.allFilingsData = filings.data;
        this.total = filings.total;
        this.hasMoreData = filings.data.length < filings.total;
      } else {
        this.allFilingsData = [];
        this.total = 0;
        this.hasMoreData = false;
      }
    } catch (error) {
      console.error("Error fetching filtered filings data:", error);
      this.allFilingsData = [];
      this.total = 0;
      this.hasMoreData = false;
    } finally {
      this.loading = false;
    }
  }

  async loadMore() {
    this.paginating = true;
    const nextPage = this.page + 1;
    try {
      const filings = await fetchAllFilings(
        this.cik,
        nextPage,
        this.filter.formType,
        this.filter.year,
        this.filter.month
      );
      if (filings && filings.data.length > 0) {
        this.allFilingsData = [...this.allFilingsData, ...filings.data];
        this.page = nextPage;
        this.hasMoreData = filings.data.length < filings.total;
      } else {
        this.hasMoreData = false;
      }
    } catch (error) {
      console.error("Error loading more filings data:", error);
      this.hasMoreData = false;
    } finally {
      this.paginating = false;
    }
  }
}

const allFilingsStore = new AllFilingsStore();
export default allFilingsStore;
