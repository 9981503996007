import React, { useState } from "react";
import { enforceMinMax, sectorIndustries, suffixMap } from "../constants";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

export default function ScreenerAdjustments({
  adjustments,
  setAdjustments,
  screenerType,
}) {
  const [dropdownOpened, setDropdownOpened] = useState("");

  const dropdownValues = {
    sector: Object.keys(sectorIndustries),
    industry: Object.keys(
      sectorIndustries[adjustments.sector.value]?.industries || {}
    ),
  };

  const generalAdjustments = [
    "sector",
    "industry",
    "market_cap",
    "price",
    "volume",
  ];

  const statisticsAdjustments = [
    "pe",
    "peg",
    "ps",
    "pb",
    "roe",
    "roa",
    "current_ratio",
    "debt_equity",
    "net_margin",
    "operating_margin",
    "gross_margin",
  ];

  const fundamentalAdjustments = [
    "revenue",
    "gross_profit",
    "operating_profit",
    "net_profit",
    "eps",
    "ebitda",
    "assets",
    "liabilities",
    "total_equity",
    "cash",
    "st_debt",
    "lt_debt",
    "net_change_in_cash",
    "free_cash_flow",
    "da",
  ];

  const handleSuffixCircularQueue = (suffix) => {
    const currentSuffixIndex = Object.keys(suffixMap).indexOf(suffix);
    const newSuffixIndex =
      (currentSuffixIndex + 1) % Object.keys(suffixMap).length;
    return Object.keys(suffixMap)[newSuffixIndex];
  };

  const isSectorSelected = !!adjustments.sector.value; // Check if a sector is selected

  return (
    <>
      {[
        ...(screenerType === "General"
          ? generalAdjustments
          : screenerType === "Statistics"
          ? statisticsAdjustments
          : fundamentalAdjustments),
      ].map((adjustment, index) => (
        <div className="text-start w-full h-fit relative" key={index}>
          <label>{adjustments[adjustment]["titleCase"]}:</label>
          {adjustments[adjustment]["type"] === "dropdown" ? (
            <>
              <div
                className={`flex w-full items-center py-2 px-4 rounded-2xl bg-white-smShade ${
                  adjustment === "industry" && !isSectorSelected
                    ? "opacity-50 cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                onClick={() =>
                  adjustment !== "industry" || isSectorSelected
                    ? setDropdownOpened((prev) =>
                        prev === adjustment ? "" : adjustment
                      )
                    : null
                }
              >
                <input
                  type="text"
                  value={adjustments[adjustment]["value"] || ""}
                  placeholder="Select"
                  className="w-full text-base cursor-pointer"
                  readOnly
                />
                {dropdownOpened === adjustment ? (
                  <IoIosArrowUp />
                ) : (
                  <IoIosArrowDown />
                )}
              </div>
              <ScreenerSetupDropdown
                label={adjustment}
                values={dropdownValues[adjustment]}
                isOpen={dropdownOpened === adjustment}
                closeDropdown={() => setDropdownOpened("")}
                setter={setAdjustments}
              />
            </>
          ) : (
            <>
              <div className="flex items-center gap-6">
                <div className="flex items-center gap-[2px] w-full py-2 px-4 rounded-2xl bg-white-smShade">
                  {adjustments[adjustment]["minValue"]?.sign && (
                    <span
                      className="text-[13px] prevent-highlight cursor-pointer hover:text-lavender-primary transition-all duration-300 font-medium"
                      onClick={() =>
                        setAdjustments((prev) => ({
                          ...prev,
                          [adjustment]: {
                            ...prev[adjustment],
                            minValue: {
                              ...prev[adjustment]["minValue"],
                              ...enforceMinMax(
                                prev[adjustment]["minValue"]["value"] || "0",
                                prev[adjustment]["minValue"]?.suffix,
                                prev[adjustment]["min"],
                                prev[adjustment]["max"],
                                prev[adjustment]["minValue"]["sign"] === "+"
                                  ? "-"
                                  : "+"
                              ),
                              sign:
                                prev[adjustment]["minValue"]["sign"] === "+"
                                  ? "-"
                                  : "+",
                            },
                          },
                        }))
                      }
                    >
                      {adjustments[adjustment]["minValue"]["sign"]}
                    </span>
                  )}
                  {adjustments[adjustment]["type"] === "dollar" && (
                    <span className="text-[13px] prevent-highlight">$</span>
                  )}
                  {adjustments[adjustment]["minValue"]?.sign === "-" && (
                    <span className="text-[13px] prevent-highlight">(</span>
                  )}
                  <input
                    type="number"
                    className="flex w-full items-center transition-all cursor-pointer text-base"
                    placeholder="Min"
                    value={adjustments[adjustment]["minValue"]["value"] || ""}
                    onChange={(e) => {
                      setAdjustments((prev) => ({
                        ...prev,
                        [adjustment]: {
                          ...prev[adjustment],
                          minValue: {
                            ...prev[adjustment]["minValue"],
                            ...enforceMinMax(
                              e.target.value,
                              prev[adjustment]["minValue"]?.suffix,
                              prev[adjustment]["min"],
                              prev[adjustment]["max"],
                              prev[adjustment]["minValue"]?.sign
                            ),
                          },
                        },
                      }));
                    }}
                  />
                  {adjustments[adjustment]["minValue"]?.sign === "-" && (
                    <span className="text-[13px] prevent-highlight">)</span>
                  )}
                  {adjustments[adjustment]["type"] === "percent" && (
                    <span className="text-[13px] prevent-highlight">%</span>
                  )}
                  {adjustments[adjustment]["minValue"]?.suffix && (
                    <span
                      className="text-[13px] flex items-center gap-1 cursor-pointer hover:text-lavender-primary transition-all duration-300 prevent-highlight"
                      onClick={() =>
                        setAdjustments((prev) => ({
                          ...prev,
                          [adjustment]: {
                            ...prev[adjustment],
                            minValue: {
                              ...prev[adjustment]["minValue"],
                              ...enforceMinMax(
                                prev[adjustment]["minValue"]["value"] || "0",
                                handleSuffixCircularQueue(
                                  ...prev[adjustment]["minValue"]["suffix"]
                                ),
                                prev[adjustment]["min"],
                                prev[adjustment]["max"],
                                prev[adjustment]["minValue"]?.sign
                              ),
                            },
                          },
                        }))
                      }
                    >
                      {adjustments[adjustment]["minValue"]["suffix"]}
                    </span>
                  )}
                </div>
                <div className="flex items-center gap-[2px] w-full py-2 px-4 rounded-2xl bg-white-smShade">
                  {adjustments[adjustment]["maxValue"]?.sign && (
                    <span
                      className="text-[13px] prevent-highlight cursor-pointer hover:text-lavender-primary transition-all duration-300 font-medium"
                      onClick={() =>
                        setAdjustments((prev) => ({
                          ...prev,
                          [adjustment]: {
                            ...prev[adjustment],
                            maxValue: {
                              ...prev[adjustment]["maxValue"],
                              ...enforceMinMax(
                                prev[adjustment]["maxValue"]["value"] || "0",
                                prev[adjustment]["maxValue"]?.suffix,
                                prev[adjustment]["min"],
                                prev[adjustment]["max"],
                                prev[adjustment]["maxValue"]["sign"] === "+"
                                  ? "-"
                                  : "+"
                              ),
                              sign:
                                prev[adjustment]["maxValue"]["sign"] === "+"
                                  ? "-"
                                  : "+",
                            },
                          },
                        }))
                      }
                    >
                      {adjustments[adjustment]["maxValue"]["sign"]}
                    </span>
                  )}
                  {adjustments[adjustment]["type"] === "dollar" && (
                    <span className="text-[13px] prevent-highlight">$</span>
                  )}
                  {adjustments[adjustment]["maxValue"]?.sign === "-" && (
                    <span className="text-[13px] prevent-highlight">(</span>
                  )}
                  <input
                    type="number"
                    className="flex w-full items-center transition-all cursor-pointer text-base"
                    placeholder="Max"
                    value={adjustments[adjustment]["maxValue"]["value"] || ""}
                    onChange={(e) => {
                      setAdjustments((prev) => ({
                        ...prev,
                        [adjustment]: {
                          ...prev[adjustment],
                          maxValue: {
                            ...prev[adjustment]["maxValue"],
                            ...enforceMinMax(
                              e.target.value,
                              prev[adjustment]["maxValue"]?.suffix,
                              prev[adjustment]["min"],
                              prev[adjustment]["max"],
                              prev[adjustment]["maxValue"]?.sign
                            ),
                          },
                        },
                      }));
                    }}
                  />
                  {adjustments[adjustment]["maxValue"]?.sign === "-" && (
                    <span className="text-[13px] prevent-highlight">)</span>
                  )}
                  {adjustments[adjustment]["type"] === "percent" && (
                    <span className="text-[13px] prevent-highlight">%</span>
                  )}
                  {adjustments[adjustment]["maxValue"]?.suffix && (
                    <span
                      className="text-[13px] flex items-center gap-1 cursor-pointer hover:text-lavender-primary transition-all duration-300 prevent-highlight"
                      onClick={() =>
                        setAdjustments((prev) => ({
                          ...prev,
                          [adjustment]: {
                            ...prev[adjustment],
                            maxValue: {
                              ...prev[adjustment]["maxValue"],
                              ...enforceMinMax(
                                prev[adjustment]["maxValue"]["value"] || "0",
                                handleSuffixCircularQueue(
                                  ...prev[adjustment]["maxValue"]["suffix"]
                                ),
                                prev[adjustment]["min"],
                                prev[adjustment]["max"],
                                prev[adjustment]["maxValue"]?.sign
                              ),
                            },
                          },
                        }))
                      }
                    >
                      {adjustments[adjustment]["maxValue"]["suffix"]}
                    </span>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </>
  );
}

function ScreenerSetupDropdown({
  label,
  values,
  isOpen,
  closeDropdown,
  setter,
}) {
  return (
    <div
      className={`absolute w-full h-[250%] top-[125%] bg-white-primary left-0 rounded-2xl drop-shadow-subtle-xsoft overflow-hidden z-20 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <article className="flex flex-col overflow-auto h-full">
        {values.map((value, index) => (
          <span
            className="px-4 py-0.5 hover:bg-lavender-smTint cursor-pointer transition-all duration-300 font-primary font-medium"
            key={index}
            onClick={() => {
              setter((prev) => ({
                ...prev,
                [label]: {
                  ...prev[label],
                  value: value,
                },
              }));
              closeDropdown();
            }}
          >
            {value}
          </span>
        ))}
      </article>
    </div>
  );
}
