import { makeAutoObservable } from "mobx";
import { fetchFilings } from "../services/api";

class NewsStore {
  newsData = [];
  page = 1;
  total = 0;
  filter = { year: "", month: "" };
  loading = false;
  paginating = false;
  hasMoreData = true;
  currentlyOpenedFilter = "";
  cik = "";

  constructor() {
    makeAutoObservable(this);
  }

  setCik(cik) {
    this.cik = cik;
    this.resetState();
  }

  resetState() {
    this.newsData = [];
    this.page = 1;
    this.total = 0;
    this.filter = { year: "", month: "" };
    this.hasMoreData = true;
    this.fetchNewsData();
  }

  setCurrentlyOpenedFilter(name) {
    this.currentlyOpenedFilter = name;
  }

  async fetchNewsData() {
    this.loading = true;
    let combinedData = [];
    let total = 0; // Define total outside the loop

    try {
      for (let i = 1; i <= this.page; i++) {
        const news = await fetchFilings(
          this.cik,
          "news",
          i,
          this.filter.year,
          this.filter.month,
          ""
        );
        if (news && news.data) {
          combinedData = [...combinedData, ...news.data];
          total = news.total; // Track the total number of filings
        }
      }
      this.newsData = combinedData;
      this.total = total;
      this.hasMoreData = combinedData.length < total;
    } catch (error) {
      console.error("Error fetching news data:", error);
      this.newsData = [];
      this.total = 0;
      this.hasMoreData = false;
    } finally {
      this.loading = false;
    }
  }

  async handleFilterChange(name, value) {
    this.loading = true;
    const updatedFilter = { ...this.filter, [name]: value };
    if (name === "year" && value === "") {
      updatedFilter.month = ""; // Reset month if year is cleared
    }
    this.filter = updatedFilter;
    this.page = 1;

    try {
      const news = await fetchFilings(
        this.cik,
        "news",
        1,
        this.filter.year,
        this.filter.month,
        ""
      );
      if (news && news.data) {
        this.newsData = news.data;
        this.total = news.total;
        this.hasMoreData = news.data.length < news.total;
      } else {
        this.newsData = [];
        this.total = 0;
        this.hasMoreData = false;
      }
    } catch (error) {
      console.error("Error fetching filtered news data:", error);
      this.newsData = [];
      this.total = 0;
      this.hasMoreData = false;
    } finally {
      this.loading = false;
    }
  }

  async loadMore() {
    this.paginating = true;
    const nextPage = this.page + 1;
    try {
      const news = await fetchFilings(
        this.cik,
        "news",
        nextPage,
        this.filter.year,
        this.filter.month,
        ""
      );
      if (news && news.data.length > 0) {
        this.newsData = [...this.newsData, ...news.data];
        this.page = nextPage;
        this.hasMoreData = news.data.length < news.total;
      } else {
        this.hasMoreData = false;
      }
    } catch (error) {
      console.error("Error loading more news data:", error);
      this.hasMoreData = false;
    } finally {
      this.paginating = false;
    }
  }
}

const newsStore = new NewsStore();
export default newsStore;
