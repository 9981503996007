import React from "react";
import { Outlet } from "react-router-dom";
import "../styles/financialData.css";

export default function RootLayout({ menuOpen, toggleMenu }) {
  return (
    <div className="financial-data">
      <Outlet />
    </div>
  );
}
