import { action, makeObservable, observable } from "mobx";

class NavigationBarStore {
  isOpen = false;
  constructor() {
    makeObservable(this, {
      isOpen: observable,
      toggleIsOpen: action,
      setIsOpen: action,
    });
  }

  toggleIsOpen = () => {
    this.isOpen = !this.isOpen;
  };
  setIsOpen = (val) => {
    this.isOpen = val;
  };
}

export default new NavigationBarStore();
