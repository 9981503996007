import React, { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import HeaderNew from "./HeaderNew";
import SideBar from "./SideBar";
import { FaRegBell, FaRegClock } from "react-icons/fa";
import { IoChatboxOutline, IoFilter } from "react-icons/io5";
import { observer } from "mobx-react";
import navigationBarStore from "../stores/navigationBarStore";

const ActivityLayout = observer(() => {
  const navRef = useRef();
  useEffect(() => {
    // Handle clicking outside the dropdown contents to close dropdown
    const handleClickOutsideDropdown = (e) => {
      if (navRef.current && !navRef.current.contains(e.target)) {
        navigationBarStore.setIsOpen(false); // Close the dropdown
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDropdown);

    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  return (
    <>
      <nav
        ref={navRef}
        className={`fixed h-[100dvh] top-0 z-40 flex transition-all duration-300 medium-large:left-0 ${
          !navigationBarStore.isOpen ? "-left-full" : "left-0"
        }`}
      >
        <HeaderNew active="activities" />
        <SideBar
          navigationLinks={[
            {
              label: "Notifications",
              icon: IoChatboxOutline,
              subLinks: [],
              exact: true,
              link: "/notifications",
            },
            {
              label: "Recently Viewed",
              link: "/recent",
              icon: FaRegClock,
              subLinks: [],
              exact: false,
            },
            {
              label: "My Screeners",
              link: "/screeners",
              icon: IoFilter,
              subLinks: [],
              exact: true,
            },
            {
              label: "My Alerts",
              link: "/alerts",
              icon: FaRegBell,
              subLinks: [],
              exact: true,
            },
          ]}
        />
      </nav>
      <div className="medium-large:ml-[320px] p-8 min-h-[100dvh]">
        <Outlet context={[navigationBarStore]} />
      </div>
    </>
  );
});

export default ActivityLayout;
