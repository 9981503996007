import React, { useEffect } from "react";
import { BsExclamationCircle } from "react-icons/bs";
import { FiAlertTriangle } from "react-icons/fi";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

export default function Alert({
  type = "warning",
  message,
  clearMessage,
  additionalClassNames,
  duration = 5000,
}) {
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        clearMessage();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [message]);
  return (
    <p
      className={`w-full rounded-2xl outline outline-[1.75px] text-start ${
        type === "error"
          ? "outline-salmon text-salmon"
          : type === "warning"
          ? "outline-orange-400 text-orange-400"
          : "outline-green-500 text-green-500"
      } ${
        additionalClassNames ? additionalClassNames : ""
      } px-4 py-2 font-primary font-medium mb-2 flex items-center gap-2`}
      style={{ width: "calc(100% - 3.50px)" }}
    >
      {type === "error" ? (
        <BsExclamationCircle />
      ) : type === "warning" ? (
        <FiAlertTriangle />
      ) : (
        <IoCheckmarkCircleOutline />
      )}
      {message}
    </p>
  );
}
