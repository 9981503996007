import React, { useState } from "react";
import "../styles/SearchResultsSideBar.css";
import LoadingSpinner from "./LoadingSpinner";
import { sectorIndustries } from "../constants";
import GoogleMapComponent from "./GoogleMapComponent";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import PlaceholderProfile from "../images/placeholderprofile.png";
import { IoDocumentText } from "react-icons/io5";

const SearchResultsSideBar = ({
  results = [],
  onResultClick,
  highlightedInstances,
  onInstanceClick,
  isLoading,
  isLoadingInstances,
  financialData,
}) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [activeInstance, setActiveInstance] = useState(null);
  const navigate = useNavigate();
  const [companyDropdownInfo, setCompanyDropdownInfo] = useState(false);

  const handleResultClick = (index, accessionNo, formType) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
      onResultClick(accessionNo, formType);
    }
  };

  const safeResults = Array.isArray(results) ? results : [];

  return (
    <div className="search-results-sidebar">
      {financialData && (
        <span className="relative">
          <div
            className={`mb-4 mt-8 mx-3 flex items-center gap-2 hover:bg-white-mdShade ${
              companyDropdownInfo && "bg-white-mdShade"
            } rounded-2xl py-2 px-4  transition-all`}
          >
            <span
              onClick={() => navigate(`/${financialData.cik}/financials`, {})}
              className="bg-white-smShade p-2 rounded-2xl cursor-pointer"
            >
              <img
                src={financialData?.logo || PlaceholderProfile}
                alt={financialData?.companyName}
                width={45}
                height={45}
              />
            </span>
            <span
              onClick={() => setCompanyDropdownInfo((prev) => !prev)}
              className="flex items-center gap-2 cursor-pointer"
            >
              <h3 className="text-sm font-semibold text-nowrap truncate w-[90px] text-left">
                {financialData?.companyName}
              </h3>
              {companyDropdownInfo ? (
                <IoIosArrowUp className="h-[14px] w-[14px]" />
              ) : (
                <IoIosArrowDown className="h-[14px] w-[14px]" />
              )}
            </span>
          </div>
          <div
            className={`absolute top-full left-3 w-[225%] h-auto bg-white-primary rounded-2xl z-50 ${
              companyDropdownInfo ? "block" : "hidden"
            } drop-shadow-subtle-soft`}
          >
            <div className="p-6 space-y-1.5">
              <h3 className="text-xl font-bold text-start text-periwinkle-primary">
                {financialData.companyName}
              </h3>
              <ul className="text-left font-semibold space-y-2">
                <li className="ml-0 list-none text-sm">
                  Exchange:
                  <span
                    className={`ml-1 pill font-medium ${
                      financialData?.exchange === "NASDAQ"
                        ? "bg-[#A3C4F3]"
                        : "bg-[#F4A6A1]"
                    }`}
                  >
                    {financialData?.exchange}
                  </span>
                </li>
                <li className="ml-0 list-none text-sm">
                  Stock Price: $
                  {Math.round(financialData?.stock_price * 100) / 100}
                  <span
                    className={`ml-1 pill font-medium ${
                      financialData?.price_change < 0
                        ? "bg-salmon"
                        : "bg-green-300"
                    }`}
                  >
                    {financialData.price_change < 0 ? "" : "+"}
                    {financialData.price_change}%
                  </span>
                </li>
                <li className="ml-0 list-none text-sm">
                  Market Cap: {financialData?.market_cap}
                </li>
                <li className="ml-0 list-none text-sm">
                  Sector:
                  <span
                    className={`ml-1 pill font-medium`}
                    style={{
                      backgroundColor:
                        sectorIndustries[financialData?.sector]?.color,
                    }}
                  >
                    {financialData?.sector}
                  </span>
                </li>
                <li className="ml-0 list-none text-sm">
                  Industry:
                  <span
                    className={`ml-1 pill font-medium`}
                    style={{
                      backgroundColor:
                        sectorIndustries[financialData?.sector]?.industries[
                          financialData?.industry
                        ],
                    }}
                  >
                    {financialData?.industry}
                  </span>
                </li>
              </ul>
            </div>
            <GoogleMapComponent
              address={financialData?.address}
              apiKey="YOUR_GOOGLE_MAPS_API_KEY"
            />
          </div>
        </span>
      )}
      <ul
        style={{ width: "-webkit-fill-available" }}
        className={`search-results-container ${
          !financialData ? "mt-[104px]" : ""
        }`}
      >
        {!isLoading ? (
          safeResults.length === 0 ? (
            <li>No results found.</li>
          ) : (
            safeResults.map((result, index) => (
              <li key={index} className="ml-0">
                <div
                  className="py-2 px-4 mx-3 mb-2 cursor-pointer transition-all duration-300 border-none text-left text-sm flex items-center gap-2 whitespace-nowrap rounded-xl text-nowrap truncate hover:bg-white-smShade"
                  onClick={() =>
                    handleResultClick(
                      index,
                      result.accessionNo,
                      result.formType
                    )
                  }
                >
                  <span>
                    <IoDocumentText />
                  </span>
                  <span className="flex-1 truncate">
                    <span onClick={(e) => e.preventDefault()}>
                      {result.title}
                    </span>
                  </span>
                  <span className="text-gray-500">
                    {expandedIndex === index ? (
                      <IoIosArrowUp />
                    ) : (
                      <IoIosArrowDown />
                    )}
                  </span>
                </div>

                {expandedIndex === index && (
                  <div>
                    {isLoadingInstances ? (
                      <div className="flex justify-center items-center py-4">
                        <LoadingSpinner />
                      </div>
                    ) : !highlightedInstances ||
                      highlightedInstances.length === 0 ? (
                      <div className="flex w-full justify-end py-4 px-8 text-gray-500">
                        No highlights found in the document.
                      </div>
                    ) : (
                      <ul className="flex flex-col ml-5">
                        {highlightedInstances.map((instance, instanceIndex) => (
                          <li
                            key={instanceIndex}
                            className={`search-result-instance truncate cursor-pointer py-2 px-4 mx-3 mb-2 mt-0 rounded-xl transition-all duration-300 ${
                              activeInstance === instance.id
                                ? "bg-lavender-smTint"
                                : "hover:bg-white-smShade "
                            }`}
                            onClick={() => {
                              onInstanceClick(instance.id);
                              setActiveInstance(instance.id);
                            }}
                            dangerouslySetInnerHTML={{ __html: instance.text }}
                          />
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </li>
            ))
          )
        ) : (
          <div className="flex justify-center items-center h-full">
            <LoadingSpinner />
          </div>
        )}
      </ul>
    </div>
  );
};

export default SearchResultsSideBar;
