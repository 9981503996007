import React from "react";

const Error404Page = () => {
  return (
    <div style={styles.container}>
      <div style={styles.errorBox}>
        <h1 style={styles.errorCode}>404</h1>
        <p style={styles.errorMessage}>
          The page you're looking for doesn't exist.
          <br />
          Please try again.
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100dvh",
    backgroundColor: "#f4f4f4", // Light background color
  },
  errorBox: {
    textAlign: "center",
    padding: "2rem",
    borderRadius: "8px",
  },
  errorCode: {
    fontSize: "8rem",
    color: "#FF6B6B", // Red color for the error code
    margin: 0,
    lineHeight: "1",
  },
  errorMessage: {
    fontSize: "1.25rem",
    color: "#333", // Darker color for the error message
    marginTop: "1rem",
  },
};

export default Error404Page;
