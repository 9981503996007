import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { deleteScreener, getScreeners } from "../services/api";
import LoadingSpinner from "./LoadingSpinner";
import Alert from "./Alert";
import { FiTrash2 } from "react-icons/fi";
import LogoWithMenuToggle from "./LogoWithMenuToggle";
import { observer } from "mobx-react";

// List of colors to apply sequentially
const colors = [
  "#FFB3BA", "#FFDFBA", "#FFFFBA", "#BAFFC9", "#BAE1FF", "#FFC8DD", "#FFAFCC",
  "#BDE0FE", "#A2D2FF", "#C5E1A5", "#FFD1C1", "#FFABAB", "#FFCCF9", "#C7CEEA",
  "#FFDAC1", "#D4A5A5", "#FFC4A3", "#C1E1C1", "#FFABCB", "#A0C4FF", "#FFCBC1",
  "#FFB5A7", "#FFD6BA", "#FFADAD", "#BDB2FF", "#A2F2B4", "#D4E157", "#FF9AA2",
  "#FFABAF", "#FFD700", "#D6D2D2", "#FDE2E4", "#FDFFB6", "#CAFFBF", "#9BF6FF",
  "#FFC3A0", "#FFE5B4", "#FAF3DD", "#B0E57C", "#FAF6D2", "#E2F0CB", "#B5EAD7",
  "#E2CFC4", "#FEC8D8", "#FCD5CE", "#E6E6FA", "#B1EDE8", "#FFA8A8", "#F6D8AC",
  "#FDE2E2"
];

const Screeners = observer(() => {
  const navigate = useNavigate();
  const [screeners, setScreeners] = useState(null);
  const [removePopup, setRemovePopup] = useState("");
  const [navigationBarStore] = useOutletContext();

  useEffect(() => {
    const fetchData = async () => {
      const screenersData = await getScreeners();
      if (screenersData?.length) {
        setScreeners(screenersData);
      } else {
        setScreeners([]);
      }
    };
    fetchData();
  }, []);

  const handleNavigation = (name) => {
    navigate("/screeners-setup", { state: { reference: name } });
  };

  return (
    <>
      <LogoWithMenuToggle navigationBarStore={navigationBarStore} />
      <div className="mt-[31px] medium-large:mt-[78px] mb-2">
        <div className="flex items-center justify-between mb-2">
          <h1 className="text-2xl font-bold text-start">My Screeners</h1>
          <button
            className="bg-lavender-primary text-white-primary px-4 py-2 font-primary font-semibold rounded-2xl"
            onClick={() => navigate("/screeners-setup")}
          >
            Create +
          </button>
        </div>
        {!screeners ? (
          <div className="loading-page-container">
            <LoadingSpinner />
          </div>
        ) : !screeners.length ? (
          <div className="loading-page-container">
            <h3 className="text-black-mdTint">No screeners found.</h3>
          </div>
        ) : (
          <>
            <div className="w-full h-full overflow-auto">
              <table>
                <tbody>
                  {screeners.map((screener, i) => (
                    <tr
                      key={i}
                      className="hover:bg-lavender-smTint"
                      onClick={() => handleNavigation(screener.name)}
                    >
                      <td className="py-3 px-4 cursor-pointer">
                        <span
                          className="table-pill text-center px-2 py-1 rounded-full"
                          style={{
                            backgroundColor: colors[i % colors.length], // Cycle through colors
                          }}
                        >
                          {screener.name}
                        </span>
                      </td>
                      <td className="py-3 px-4 cursor-pointer">
                        Created: {screener.created_time.split(" ")[0]}
                      </td>
                      <td className="py-3 px-4 cursor-pointer">
                        Last edited: {screener.last_edit_time.split(" ")[0]}
                      </td>
                      <td
                        className="2xl:px-0 px-2.5 text-base text-black-xsTint hover:text-salmon cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setRemovePopup(screener.name);
                        }}
                      >
                        <FiTrash2 />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {removePopup && (
              <DeleteScreenerPopUp
                name={removePopup}
                clearPopUp={() => setRemovePopup("")}
                setScreeners={setScreeners}
              />
            )}
          </>
        )}
      </div>
    </>
  );
});

export default Screeners;

function DeleteScreenerPopUp({ name, clearPopUp, setScreeners }) {
  const popUpRef = useRef(null);
  const [error, setError] = useState();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    const res = await deleteScreener(name);
    if (res?.status === "success") {
      const screenersData = await getScreeners();
      setScreeners(screenersData.length ? screenersData : []);
      clearPopUp();
    } else {
      setError(res?.message || "Something went wrong with removing the screener");
    }
    setIsDeleting(false);
  };

  useEffect(() => {
    const handleClickOutsideDropdown = (e) => {
      if (popUpRef.current && !popUpRef.current.contains(e.target)) {
        clearPopUp();
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  return (
    <>
      <div
        className="absolute top-1/2 left-1/2 bg-white-primary drop-shadow-subtle-soft -translate-x-1/2 -translate-y-1/2 rounded-2xl p-6 z-[10000] md:w-fit w-3/4"
        ref={popUpRef}
      >
        {error && (
          <Alert
            type="warning"
            message={error}
            clearMessage={() => setError("")}
          />
        )}
        <h2 className="text-start text-xl font-semibold">
          Are you sure you want to permanently delete "{name}"?
        </h2>
        <p className="text-start text-black-smTint">
          Please note that deleting this screener will also remove all associated alerts*
        </p>
        <section className="mt-10 flex justify-end gap-3">
          <button
            onClick={() => {
              clearPopUp();
            }}
            className="p-2 px-3 rounded-2xl outline outline-[1.75px] outline-salmon text-salmon font-medium font-primary"
          >
            Cancel
          </button>
          <button
            onClick={handleDelete}
            className="p-2 px-3 rounded-2xl bg-salmon text-white-primary font-medium hover:bg-salmon/90 font-primary disabled:bg-salmon/50 disabled:cursor-not-allowed"
            disabled={isDeleting}
          >
            {isDeleting ? (
              <LoadingSpinner
                size="size-4"
                className="text-white-primary border-2"
              />
            ) : (
              "Delete"
            )}
          </button>
        </section>
      </div>
      <div className="absolute w-full h-full top-0 left-0 z-[2000] bg-black-primary opacity-50 transition-all backdrop-blur-md" />
    </>
  );
}
