import React, { useEffect, useRef, useState } from "react";
import registrationsStore from "../stores/registrationsStore";
import "../styles/registration.css";
import FilterButton from "./FilterButton";
import LoadingSpinner from "./LoadingSpinner";
import Table from "./Table";
import { getHexCode } from "../constants/filingHexcodes";
import { observer } from "mobx-react";

const RegistrationsCard = observer(({ cik, financialData }) => {
  const dropdownsRef = useRef(null);
  const [showPrompt, setShowPrompt] = useState(false); // Add state for showing prompt

  // Handle CIK changes
  useEffect(() => {
    if (registrationsStore.cik !== cik) {
      registrationsStore.setCik(cik);
    }

    const handleClickOutsideDropdown = (e) => {
      if (dropdownsRef.current && !dropdownsRef.current.contains(e.target)) {
        registrationsStore.setCurrentlyOpenedFilter(""); // Close filter dropdown on outside click
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, [cik]);

  const handleFilterChange = async ({ name, value }) => {
    await registrationsStore.handleFilterChange(name, value);
  };

  const loadMore = async () => {
    await registrationsStore.loadMore();
  };

  const years = Array.from(
    { length: 2025 - financialData?.start_year },
    (_, i) => financialData?.start_year + i
  );
  const months = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  ).slice(
    registrationsStore.filter.year === financialData?.start_year
      ? financialData?.start_month - 1
      : 0,
    registrationsStore.filter.year === new Date().getFullYear()
      ? new Date().getMonth() + 1
      : 12
  );
  const formTypes = [
    "25",
    "S-1",
    "S-3",
    "424B4",
    "25-NSE",
    "S-3ASR",
    "424A",
    "424B1",
    "424B2",
    "424B3",
    "424B4",
    "424B5",
    "424B7",
    "424B8",
    "424H",
    "FWP",
    "8-A12B",
    "8-A12G",
    "POSASR",
    "S-4",
    "S-2",
    "DEL AM",
    "15-15D",
    "8-A12B",
    "8-A12G",
    "S-8 POS",
    "S-3ASR",
    "RW",
    "SC TO-I",
    "SC TO-T",
    "SC TO-C",
    "SC14D9C",
    "SC14D1",
    "SC 14D9",
  ];

  // Function to toggle the currently opened filter
  const toggleCurrentlyOpenedFilter = (filterName) => {
    if (registrationsStore.currentlyOpenedFilter === filterName) {
      registrationsStore.setCurrentlyOpenedFilter(""); // Close the dropdown if it's already open
    } else {
      registrationsStore.setCurrentlyOpenedFilter(filterName); // Open the dropdown
    }
  };

  return (
    <>
      <div className="flex items-center justify-between mt-[31px] mb-2 flex-wrap gap-1">
        <h3 className="text-black-primary text-lg md:text-2xl font-semibold text-left">
          Registrations
        </h3>
        <div
          className="flex items-start md:gap-4 gap-2 flex-wrap"
          ref={dropdownsRef}
        >
          <FilterButton
            name="year"
            values={years.reverse()}
            value={registrationsStore.filter.year}
            clearValue={async (e) =>
              handleFilterChange({ name: "year", value: "" })
            }
            handleFilterChange={handleFilterChange}
            currentlyOpenedFilter={registrationsStore.currentlyOpenedFilter}
            setCurrentlyOpenedFilter={
              () => toggleCurrentlyOpenedFilter("year") // Use toggle function
            }
          />
          <FilterButton
            name="month"
            values={months.reverse()}
            value={registrationsStore.filter.month}
            clearValue={async (e) =>
              handleFilterChange({ name: "month", value: "" })
            }
            handleFilterChange={handleFilterChange}
            currentlyOpenedFilter={registrationsStore.currentlyOpenedFilter}
            setCurrentlyOpenedFilter={
              () => toggleCurrentlyOpenedFilter("month") // Use toggle function
            }
            disabled={!registrationsStore.filter.year} // Disable if no year selected
            showPrompt={showPrompt} // Pass showPrompt
            setShowPrompt={setShowPrompt} // Pass setShowPrompt
          />
          <FilterButton
            name="formType"
            values={formTypes}
            value={registrationsStore.filter.formType}
            clearValue={async (e) =>
              handleFilterChange({ name: "formType", value: "" })
            }
            handleFilterChange={handleFilterChange}
            currentlyOpenedFilter={registrationsStore.currentlyOpenedFilter}
            setCurrentlyOpenedFilter={
              () => toggleCurrentlyOpenedFilter("formType") // Use toggle function
            }
          />
        </div>
      </div>

      {registrationsStore.loading ? (
        <div className="loading-page-container">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Table
            data={registrationsStore.registrationsData.map(
              (registrationData) => ({
                ...registrationData,
                url: `/${cik}/documents/${registrationData.accessionNo}?filing=${registrationData.form_type}`,
                pillCN: getHexCode(registrationData.form_type) || "#ebf38c",
                title:
                  registrationData.title.split(" ").length > 8
                    ? registrationData.title.split(" ").slice(0, 8).join(" ") +
                      "..."
                    : registrationData.title.split(" ").slice(0, 8).join(" "),
              })
            )}
            references={[
              {
                reference: "form_type",
                pill: true,
              },
              { reference: "title" },
              { reference: "filingDate", additionalCN: "text-right" },
            ]}
          />

          {registrationsStore.hasMoreData &&
            registrationsStore.registrationsData.length <
              registrationsStore.total && (
              <button
                className="registration-load-more cursor-pointer hover:bg-lavender-smTint transition-all disabled:cursor-not-allowed"
                onClick={loadMore}
                disabled={registrationsStore.paginating}
              >
                <span
                  className={`registration-load-more-btn ${
                    registrationsStore.paginating ? "loading" : ""
                  }`}
                >
                  {registrationsStore.paginating ? (
                    <LoadingSpinner size="w-4 h-4" className="border-2" />
                  ) : (
                    "+ Load More"
                  )}
                </span>
              </button>
            )}
        </>
      )}
    </>
  );
});

export default RegistrationsCard;
