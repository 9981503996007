import React, { useEffect, useRef, useState } from "react";
import HeaderNew from "./HeaderNew";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  fetchFiling,
  fetchFinancials,
  fetchSummarizedFilingSection,
} from "../services/api";
import { FiFileText, FiGrid } from "react-icons/fi";
import SideBar from "./SideBar";
import DocumentsContent from "./DocumentsContent";
import { allFilingSections } from "../constants";
import { observer } from "mobx-react";
import financialStore from "../stores/financialStore";
import navigationBarStore from "../stores/navigationBarStore";

const DocumentsNew = observer(() => {
  const { accessionNo, cik } = useParams(); // Use both accessionNo and cik from URL params
  const [displayingFiling, setDisplayingFiling] = useState(null);
  const [initialDisplayingFiling, setInitialDisplayingFiling] = useState(null);
  const [summarizedFilingData, setSummarizedFilingData] = useState([]);
  const location = useLocation();
  const [generatedSummary, setGeneratedSummary] = useState(false);
  const [isSummarizedFilingLoading, setIsSummarizedFilingLoading] =
    useState(false);
  const [isDisplayingSummaries, setIsDisplayingSummaries] = useState(false);
  const [isFullScreen, setFullScreenMode] = useState(false);
  const [collapsed, setCollapsed] = useState(false); // Handle sidebar visibility
  const navRef = useRef();
  const [activeTab, setActiveTab] = useState("filing"); // Manage active tab state

  const form_type = new URLSearchParams(location.search).get("filing");

  useEffect(() => {
    setDisplayingFiling(null);
    setSummarizedFilingData([]);
    setGeneratedSummary(false);
    setIsDisplayingSummaries(false);
    console.log("Current form_type:", form_type); // Log the form_type

    const getFilingData = async () => {
      if (accessionNo && form_type) {
        const filingData = await fetchFiling(accessionNo, form_type);
        if (filingData) {
          setDisplayingFiling(filingData);
          setInitialDisplayingFiling(filingData);
          if (!financialStore.financialData) {
            financialStore.toggleIsLoading();
            const data = await fetchFinancials(filingData.cik); // Use CIK from filing data
            financialStore.updateFinancialData(data);
            financialStore.toggleIsLoading();
          }
        }
      }
    };
    getFilingData();
    setGeneratedSummary(true);
  }, [accessionNo, form_type]);

  useEffect(() => {
    if (generatedSummary) {
      generateSummary();
    }
  }, [generatedSummary]);

  const generateSummary = async () => {
    setIsSummarizedFilingLoading(true);
    setIsDisplayingSummaries(true);
    let filingSections =
      allFilingSections[form_type] || allFilingSections["misc"];
    let filingSectionsData = filingSections.map((section) => ({
      section: section,
      summary: null,
    }));
    setSummarizedFilingData(
      filingSectionsData.map((filingSectionData) => filingSectionData.summary)
    );
    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < filingSectionsData.length; j++) {
        if (filingSectionsData[j].summary?.data) {
          continue;
        }
        const summary = await fetchSummarizedFilingSection(
          accessionNo,
          form_type,
          filingSectionsData[j].section
        );
        filingSectionsData[j] = {
          ...filingSectionsData[j],
          summary: summary,
        };
      }
      if (
        filingSectionsData.filter(
          (filingSectionData) => !filingSectionData?.summary?.data
        ).length === 0
      ) {
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
    setSummarizedFilingData(
      filingSectionsData.map((filingSectionData) => filingSectionData.summary)
    );
    setIsSummarizedFilingLoading(false);
  };

  useEffect(() => {
    // Handle clicking outside the dropdown contents to close dropdown
    const handleClickOutsideDropdown = (e) => {
      if (navRef.current && !navRef.current.contains(e.target)) {
        navigationBarStore.setIsOpen(false); // Close the dropdown
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDropdown);

    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  const isDocumentsPage = location.pathname.includes("/documents");

  return (
    <>
      <nav
        ref={navRef}
        className={`fixed h-[100dvh] top-0 z-40 flex transition-all duration-300 medium-large:left-0 ${
          !navigationBarStore.isOpen ? "-left-full" : "left-0"
        }`}
      >
        <HeaderNew active="filings" />
        <SideBar
          navigationLinks={[
            displayingFiling?.table_of_contents?.length && {
              label: "Table of Contents",
              icon: FiGrid,
              subLinks: displayingFiling.table_of_contents.map(
                (table_of_content) => ({
                  label: table_of_content.name,
                  link: `${location.pathname}${location.search}#${table_of_content.id}`,
                  icon: FiFileText,
                  exact: true,
                })
              ),
            },
            initialDisplayingFiling?.otherFilings && {
              label: "Filings",
              icon: FiGrid,
              subLinks: initialDisplayingFiling.otherFilings
                ? initialDisplayingFiling.otherFilings.map((otherFiling) => ({
                    label: otherFiling.form_type,
                    link: `/documents/${accessionNo}?filing=${otherFiling?.form_type}`, // Updated link structure
                    icon: FiFileText,
                    exact: false,
                  }))
                : [],
            },
          ].filter(Boolean)}
          financialStore={financialStore}
          includesSearch={true}
          isFullScreen={isFullScreen}
          setFullScreenMode={setFullScreenMode}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          {...(isDocumentsPage && { setActiveTab })} // Only pass setActiveTab if on /documents route
        />
      </nav>
      <DocumentsContent
        displayingFiling={displayingFiling}
        summarizedFilingData={summarizedFilingData}
        setDisplayingFiling={setDisplayingFiling}
        accessionNo={accessionNo}
        form_type={form_type}
        cik={displayingFiling?.cik} // Use CIK from the fetched filing data
        isSummarizedFilingLoading={isSummarizedFilingLoading}
        generatedSummary={generatedSummary}
        isDisplayingSummaries={isDisplayingSummaries}
        setIsDisplayingSummaries={setIsDisplayingSummaries}
        isFullScreen={isFullScreen}
        setFullScreenMode={setFullScreenMode}
        collapsed={collapsed}
        generateSummary={generateSummary}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </>
  );
});

export default DocumentsNew;
