import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useParams, useNavigate } from "react-router-dom";
import HeaderNew from "./HeaderNew";
import SideBar from "./SideBar";
import SearchResultsSideBar from "./SearchResultsSideBar";
import { fetchFinancials } from "../services/api";
import { FaNewspaper } from "react-icons/fa6";
import { PiEyeglasses, PiSuitcaseSimpleBold } from "react-icons/pi";
import { FaChartLine } from "react-icons/fa6";
import { FaDollarSign } from "react-icons/fa";
import { MdOutlineViewTimeline } from "react-icons/md";
import { FiBookOpen } from "react-icons/fi";
import { GoLaw } from "react-icons/go";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { observer } from "mobx-react";
import financialStore from "../stores/financialStore";
import navigationBarStore from "../stores/navigationBarStore";

const PersistentLayout = observer(() => {
  const { cik } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]);
  const navRef = useRef();
  const year = new Date().getFullYear();

  // Add collapsed state to control sidebar
  const [collapsed, setCollapsed] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 896);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true); // State to manage header visibility

  // Function to hide the header
  const hideHeaderNew = () => {
    setIsHeaderVisible(false);
  };

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 896);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Update screen size on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 896);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSearchResultsPage = location.pathname === "/search-results";

  useEffect(() => {
    const getFinancialData = async () => {
      if (cik && !financialStore.financialData) {
        financialStore.toggleIsLoading();
        const data = await fetchFinancials(cik); // Fetch data based on cik
        financialStore.updateFinancialData(data);
        financialStore.toggleIsLoading();
      }
    };

    getFinancialData();
  }, [cik]);

  useEffect(() => {
    // Handle clicking outside the dropdown contents to close dropdown
    const handleClickOutsideDropdown = (e) => {
      if (navRef.current && !navRef.current.contains(e.target)) {
        navigationBarStore.setIsOpen(false); // Close the dropdown
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDropdown);

    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  return (
    <>
      <nav
        ref={navRef}
        className={`fixed h-[100dvh] top-0 z-40 flex transition-all duration-300 medium-large:left-0 ${
          !navigationBarStore.isOpen ? "-left-full" : "left-0"
        }`}
      >
        {/* Conditionally render HeaderNew based on isHeaderVisible */}
        {isHeaderVisible && (!isSmallScreen || !collapsed) && (
          <HeaderNew active="filings" />
        )}
        {isSearchResultsPage ? (
          <SearchResultsSideBar
            results={searchResults}
            onResultClick={(url) => navigate(url)}
          />
        ) : (
          <SideBar
            navigationLinks={[
              {
                label: "Categorized",
                icon: MdOutlineViewTimeline,
                subLinks: [
                  {
                    label: "Financials",
                    link: `/${cik}/financials`, // CIK first
                    icon: FaDollarSign,
                    exact: true,
                  },
                  {
                    label: "Proxies",
                    link: `/${cik}/proxies`, // CIK first
                    icon: FiBookOpen,
                    exact: true,
                  },
                  {
                    label: "News",
                    link: `/${cik}/news`, // CIK first
                    icon: FaNewspaper,
                    exact: true,
                  },
                  {
                    label: "Ownership",
                    link: `/${cik}/ownership`, // CIK first
                    icon: PiSuitcaseSimpleBold,
                    exact: true,
                  },
                  {
                    label: "Registrations",
                    link: `/${cik}/registrations`, // CIK first
                    icon: GoLaw,
                    exact: true,
                  },
                ],
              },
              {
                label: "All Filings",
                link: `/${cik}/all-filings`, // CIK first
                icon: HiOutlineDocumentText,
                subLinks: [],
                exact: true,
              },
              {
                label: "Insider Trading",
                link: `/${cik}/insider`, // CIK first
                icon: PiEyeglasses,
                subLinks: [],
                exact: true,
              },
              {
                label: "Earnings Transcript",
                link: `/${cik}/earnings-transcripts-list/${year}`, // CIK first
                icon: FaChartLine,
                subLinks: [],
                exact: true,
              },
            ]}
            financialStore={financialStore}
            includesSearch={true}
            collapsed={collapsed} // Pass collapsed state
            setCollapsed={setCollapsed} // Pass setCollapsed function
            hideHeaderNew={hideHeaderNew} // Pass hideHeaderNew function
          />
        )}
      </nav>
      <div
        className={`p-8 min-h-[100dvh] ${
          !collapsed ? "medium-large:ml-[320px]" : ""
        }`}
      >
        <Outlet context={[financialStore.financialData, navigationBarStore]} />
      </div>
    </>
  );
});

export default PersistentLayout;
