import React, { useEffect, useState } from "react";
import { userDetailsSettings } from "../constants";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getUserProfile, logout } from "../services/api";
import PlaceholderProfile from "../images/placeholderprofile.png";
import LoadingSpinner from "../components/LoadingSpinner";
import LogoWithMenuToggle from "./LogoWithMenuToggle";
import { observer } from "mobx-react";

const Settings = observer(() => {
  const [userProfile, setUserProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [navigationBarStore] = useOutletContext();

  useEffect(() => {
    const fetchUserProfile = async () => {
      const userProfile = await getUserProfile();
      if (userProfile?.status === "success") {
        setUserProfile(userProfile.data);
      } else {
        console.log("SOMETHING WENT WRONG WHEN VALIDATING JWT");
      }
      setIsLoading(false);
    };
    fetchUserProfile();
  }, []);

  return (
    <>
      <LogoWithMenuToggle navigationBarStore={navigationBarStore} />
      {isLoading ? (
        <div className="loading-page-container">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <span className="relative flex items-center w-fit m-auto group">
            <img
              src={userProfile?.picture}
              onError={(e) => (e.target.src = PlaceholderProfile)}
              alt="profile"
              className="h-20 w-20 rounded-2xl transition-all"
            />
          </span>
          <h1 className="font-primary font-semibold text-4xl mt-4">
            {userProfile?.name || "-----"}
          </h1>
          <section className="p-8 rounded-[32px] drop-shadow-subtle-xsoft bg-white-primary max-w-[1028px] m-auto mt-4 flex flex-col gap-3 ">
            {userDetailsSettings.map((setting, index) => (
              <div
                key={index}
                className={`bg-white-xsShade hover:bg-lavender-smTint rounded-2xl group transition-all duration-300 w-full flex items-center overflow-hidden`}
              >
                <label className="font-primary w-1/3 text-start p-3 px-4 bg-white-smShade font-medium flex gap-2 items-center group-hover:bg-lavender-xsTint transition-all duration-300">
                  <setting.icon />
                  {setting.label}
                </label>
                <span className="flex p-3 px-4 justify-between w-full">
                  <input
                    type="text"
                    value={userProfile ? userProfile[setting?.name] : "-----"}
                    disabled
                    readOnly
                    className="disabled:text-black-mdTint disabled:cursor-not-allowed text-black-primary font-medium text-base"
                  />
                </span>
              </div>
            ))}
            <span className="flex items-center gap-3">
              <button
                className="p-2 px-3 rounded-2xl outline outline-[1.75px] outline-salmon text-salmon font-medium"
                onClick={async () => {
                  await logout();
                  navigate("/login", { replace: true });
                }}
              >
                Logout
              </button>
              <button className="p-2 px-3 rounded-2xl bg-salmon text-white-primary font-medium hover:bg-salmon/90">
                Delete Account
              </button>
            </span>
          </section>
        </>
      )}
    </>
  );
});

export default Settings;
