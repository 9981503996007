import { action, makeObservable, observable } from "mobx";

class UseInfoStore {
  userInfo = null;

  constructor() {
    makeObservable(this, {
      // observables are values
      userInfo: observable,

      // actions update observable values / state
      updateUserInfo: action,
    });
  }

  //   actions
  updateUserInfo = (userInfo) => {
    this.userInfo = userInfo;
  };
}

export default new UseInfoStore();
