import React from "react";

export default function LoadingSpinner({ size, className }) {
  return (
    <div
      className={`inline-block ${size ? size : "w-8 h-8"} ${
        className ? className : ""
      } animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-lavender-primary motion-reduce:animate-[spin_1.5s_linear_infinite]`}
      role="status"
    >
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        Loading...
      </span>
    </div>
  );
}
