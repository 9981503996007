import axios from "axios";

// Set the base URL and credentials
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers.common["Content-Type"] = "application/json";

// Axios interceptor to handle 500 errors
axios.interceptors.response.use(
  (response) => {
    // If the response is successful, simply return the response
    return response;
  },
  (error) => {
    // Check if the error is a 500 status
    if (error.response && error.response.status === 500) {
      // Redirect to the 500 error page
      window.location.href = "/500";
    }
    // Return a rejected promise to continue the chain of promise rejections
    return Promise.reject(error);
  }
);

export default axios;
