import React, { useState, useEffect, useRef, useCallback } from "react";
import { GrPowerReset } from "react-icons/gr";
import { MdCompareArrows } from "react-icons/md";
import { LuTable } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import WingmanLeft from "../images/Wingman_Left.png";
import WingmanRight from "../images/Wingman_Right.png";
import { filingSectionHeadings } from "../constants";
import { marked } from "marked";
import { fetchFiling, fetchTable, fetchAllTables } from "../services/api";
import { allFilingSections } from "../constants";
import LoadingBar from "./LoadingBar";
import LogoWithMenuToggle from "./LogoWithMenuToggle";
import navigationBarStore from "../stores/navigationBarStore";
import { observer } from "mobx-react";
import ComparePopup from "./ComparePopup";

const DocumentsContent = observer(function DocumentsContent({
  displayingFiling,
  summarizedFilingData,
  setDisplayingFiling,
  accessionNo,
  form_type,
  cik,
  isSummarizedFilingLoading,
  generatedSummary,
  isDisplayingSummaries,
  setIsDisplayingSummaries,
  isFullScreen,
  setFullScreenMode,
  collapsed,
  generateSummary,
  activeTab,
  setActiveTab,
}) {
  const [highlightedSummary, setHighlightedSummary] = useState("");
  const [documentsContainerHeight, setDocumentsContainerHeight] = useState(
    window.innerHeight - 114
  );
  const [comparePopUpOpened, setComparePopUpOpened] = useState(false);
  const comparePopupRef = useRef(null);
  const iframeRef = useRef(null);
  const navigate = useNavigate();

  const summaryEligibleForms = [
    "10-K",
    "10-Q",
    "20-F",
    "40-F",
    "DEF%2014A",
    "S-1",
    "3",
    "4",
    "5",
  ];
  const isSummaryEligible = summaryEligibleForms.includes(form_type);

  const cleanHtml = (html, deferred = false) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Remove script tags
    doc.querySelectorAll("script").forEach((el) => el.remove());

    // Replace ix:nonfraction with spans
    doc.querySelectorAll("ix\\:nonfraction").forEach((el) => {
      const span = document.createElement("span");
      span.style = el.getAttribute("style") || "";
      span.innerHTML = el.textContent.trim() + "&nbsp;";
      el.parentNode.replaceChild(span, el);
    });

    doc.querySelectorAll("span").forEach((outerSpan) => {
      const innerSpan = outerSpan.querySelector("span");
      if (innerSpan && outerSpan.innerHTML.includes(innerSpan.outerHTML)) {
        outerSpan.innerHTML = innerSpan.innerHTML;
      }
    });

    if (deferred) {
      doc.querySelectorAll("video, audio, iframe").forEach((el) => el.remove());
    }

    const filingStart = doc.querySelector("#filing-start");
    if (filingStart) {
      while (doc.body.firstChild !== filingStart) {
        doc.body.removeChild(doc.body.firstChild);
      }
    }

    return doc.body;
  };

  const insertTableButtons = (iframeDoc) => {
    const styleLink = iframeDoc.createElement("link");
    styleLink.rel = "stylesheet";
    styleLink.href =
      "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap";
    iframeDoc.head.appendChild(styleLink);

    const tables = iframeDoc.querySelectorAll("table");
    tables.forEach((table) => {
      if (table.id && table.id.startsWith("unlevered")) {
        const existingButtons = table.previousElementSibling;
        if (
          existingButtons &&
          existingButtons.className === "download-button-container"
        ) {
          existingButtons.remove();
        }

        const buttonContainer = iframeDoc.createElement("div");
        buttonContainer.className = "download-button-container";
        buttonContainer.style.display = "flex";
        buttonContainer.style.justifyContent = "flex-start";
        buttonContainer.style.marginBottom = "10px";

        const csvButton = iframeDoc.createElement("button");
        csvButton.className = "download-button";
        csvButton.style.display = "flex";
        csvButton.style.alignItems = "center";
        csvButton.style.padding = "8px 16px";
        csvButton.style.backgroundColor = "#8c88f2";
        csvButton.style.color = "#ffffff";
        csvButton.style.border = "none";
        csvButton.style.borderRadius = "1rem";
        csvButton.style.cursor = "pointer";
        csvButton.style.fontFamily = "'Montserrat', sans-serif";
        csvButton.style.fontWeight = "500";
        csvButton.style.fontSize = "14px";
        csvButton.style.boxShadow = "none";
        csvButton.style.transition = "background-color 0.3s ease";
        csvButton.addEventListener("click", () => downloadTableAsCSV(table));

        csvButton.addEventListener("mouseenter", () => {
          csvButton.style.backgroundColor = "#7a74e2";
        });
        csvButton.addEventListener("mouseleave", () => {
          csvButton.style.backgroundColor = "#8c88f2";
        });

        const iconSpan = iframeDoc.createElement("span");
        iconSpan.className = "download-icon";
        iconSpan.style.marginRight = "8px";
        iconSpan.innerHTML = `
          <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M9 3H5a2 2 0 0 0-2 2v4m6-6h10a2 2 0 0 1 2 2v4M9 3v18m0 0h10a2 2 0 0 0 2-2V9M9 21H5a2 2 0 0 1-2-2V9m0 0h18"></path></svg>
        `;

        const textSpan = iframeDoc.createElement("span");
        textSpan.textContent = "Download table";
        textSpan.style.fontWeight = "500";

        csvButton.appendChild(iconSpan);
        csvButton.appendChild(textSpan);

        buttonContainer.appendChild(csvButton);
        table.parentNode.insertBefore(buttonContainer, table);
      }
    });
  };

  const interceptLinkClicks = (iframeDoc) => {
    iframeDoc.querySelectorAll("a").forEach((link) => {
      link.addEventListener("click", (event) => {
        const href = link.getAttribute("href");

        if (href && href.startsWith("#")) {
          event.preventDefault();
          const targetElement = iframeDoc.querySelector(href);
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
          }
        } else if (href) {
          event.preventDefault();

          if (/^https?:\/\//i.test(href)) {
            window.open(href, "_blank");
          } else {
            const targetUrl = `/${cik}/documents/${accessionNo}/filing=${form_type}${href}`;
            navigate(targetUrl, { replace: true });
          }
        }
      });
    });
  };

  const scrollToTable = (iframeDoc) => {
    const hash = window.location.hash;
    const tableId = hash ? hash.substring(1) : null;

    if (tableId) {
      const tableElement = iframeDoc.getElementById(tableId);
      if (tableElement) {
        tableElement.scrollIntoView({ behavior: "smooth" });
      } else {
        console.error("Table with the specified ID not found.");
      }
    }
  };

  const refetchDisplayFiling = async () => {
    setDisplayingFiling(null);
    const filing = await fetchFiling(accessionNo, form_type, cik);
    setDisplayingFiling(filing);
    if (filing && filing.url) {
      loadInitialContent(filing.url);
    }
  };

  const downloadTableAsCSV = async (table) => {
    const table_id = table.id;
    try {
      const response = await fetchTable(table_id, accessionNo, form_type, cik);

      if (response && response.url) {
        window.location.href = response.url;
      } else {
        alert("Table cannot be downloaded currently, try again later.");
      }
    } catch (error) {
      console.error("Error fetching table data:", error);
      alert("There was an error fetching the table. Please try again later.");
    }
  };

  const refetchFilingSummary = async () => {
    generateSummary();
  };

  const handleTextSelection = (iframeDoc) => {
    const tooltipId = "iframe-tooltip";
    let selectedText = "";
    let selectedRange = null;

    iframeDoc.addEventListener("mouseup", () => {
      const selection = iframeDoc.getSelection();

      if (selection && !selection.isCollapsed && selection.rangeCount > 0) {
        selectedRange = selection.getRangeAt(0).cloneRange();

        // Use TreeWalker to calculate correct start and end offsets
        const globalOffsets = calculateGlobalOffsetsWithTreeWalker(
          iframeDoc,
          selectedRange
        );

        selectedText = selectedRange.toString().trim();

        if (!selectedText) {
          console.error("Selection contains no valid text.");
          removeTooltip();
          return;
        }

        // Handle non-breaking spaces
        selectedText = selectedText.replace(/\u00A0/g, "&nbsp;");

        // Ensure that the end offset is valid
        const adjustedEndOffset = adjustEndOffset(
          globalOffsets,
          selectedRange.endContainer,
          selectedRange
        );

        const uniqueId = `highlight-${Date.now()}`;
        const highlightData = {
          id: uniqueId,
          text: selectedText,
          startOffset: globalOffsets.start,
          endOffset: adjustedEndOffset,
          accessionNo: accessionNo,
        };

        const currentUrl = window.location.href.split("#")[0];
        const highlightUrl = `${currentUrl}&highlight=${encodeURIComponent(
          JSON.stringify(highlightData)
        )}`;

        console.log(`Generated highlight link: ${highlightUrl}`);

        // Copy the link to the clipboard
        navigator.clipboard
          .writeText(highlightUrl)
          .then(() => console.log("Highlight link copied to clipboard"))
          .catch((err) => console.error("Failed to copy the link", err));

        // Create and display tooltip
        const rect = selectedRange.getBoundingClientRect();
        createTooltip(rect);

        // Apply the highlight visually
        highlightSelectedTextInRange(iframeDoc, globalOffsets, selectedRange);
      } else {
        console.error("Selection is collapsed or no valid text selected.");
        removeTooltip();
      }
    });

    const calculateGlobalOffsetsWithTreeWalker = (iframeDoc, range) => {
      let globalStart = 0;
      let globalEnd = 0;
      let currentOffset = 0;

      // Create TreeWalker to traverse text nodes only
      const walker = iframeDoc.createTreeWalker(
        iframeDoc.body,
        NodeFilter.SHOW_TEXT,
        null,
        false
      );

      // Walk through the text nodes and calculate the correct global offsets
      while (walker.nextNode()) {
        const node = walker.currentNode;
        const nodeText = node.textContent;

        if (node === range.startContainer) {
          globalStart = currentOffset + range.startOffset;
        }

        if (node === range.endContainer) {
          globalEnd = currentOffset + range.endOffset;
          break;
        }

        currentOffset += nodeText.length;
      }

      return { start: globalStart, end: globalEnd };
    };

    const adjustEndOffset = (globalOffsets, endContainer, range) => {
      const actualEndOffset = range.endOffset;
      const containerLength = endContainer.textContent.length;

      if (actualEndOffset === 0 || actualEndOffset > containerLength) {
        return globalOffsets.start + containerLength;
      }

      return globalOffsets.end;
    };

    const highlightSelectedTextInRange = (iframeDoc, globalOffsets, range) => {
      let currentOffset = 0;
      const walker = iframeDoc.createTreeWalker(
        iframeDoc.body,
        NodeFilter.SHOW_ALL,
        null,
        false
      );

      // Walk through the nodes and apply the highlight for the selected range
      while (walker.nextNode()) {
        const node = walker.currentNode;
        const nodeText = node.textContent || ""; // Handles both text and non-text nodes
        const nodeLength = nodeText.length;

        const nodeStart = currentOffset;
        const nodeEnd = currentOffset + nodeLength;

        if (nodeStart <= globalOffsets.end && nodeEnd >= globalOffsets.start) {
          const highlightStart = Math.max(
            0,
            globalOffsets.start - currentOffset
          );
          const highlightEnd = Math.min(
            nodeLength,
            globalOffsets.end - currentOffset
          );

          if (highlightStart < highlightEnd) {
            highlightNodeRange(iframeDoc, node, highlightStart, highlightEnd);
          }
        }

        currentOffset += nodeLength;
      }
    };

    const highlightNodeRange = (iframeDoc, node, startOffset, endOffset) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const range = iframeDoc.createRange();
        range.setStart(node, startOffset);
        range.setEnd(node, endOffset);

        const highlightSpan = iframeDoc.createElement("span");
        highlightSpan.className = "highlighted-text";
        highlightSpan.style.backgroundColor = "yellow"; // Highlight style

        const documentFragment = range.extractContents();
        highlightSpan.appendChild(documentFragment);

        range.insertNode(highlightSpan);
      }
    };

    const createTooltip = (rect) => {
      let tooltip = iframeDoc.querySelector(`#${tooltipId}`);
      if (tooltip) {
        tooltip.remove();
      }

      if (selectedText && selectedRange) {
        tooltip = iframeDoc.createElement("div");
        tooltip.id = tooltipId;
        tooltip.style.position = "absolute";
        tooltip.style.display = "flex";
        tooltip.style.alignItems = "center";
        tooltip.style.padding = "8px 16px";
        tooltip.style.backgroundColor = "#FFFFFF";
        tooltip.style.color = "#8C88F2";
        tooltip.style.border = "2px solid #8C88F2";
        tooltip.style.borderRadius = "15px";
        tooltip.style.fontSize = "16px";
        tooltip.style.fontFamily = "Montserrat, sans-serif";
        tooltip.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
        tooltip.style.zIndex = "9999";
        tooltip.style.cursor = "pointer";
        iframeDoc.body.appendChild(tooltip);

        const iconElement = iframeDoc.createElement("span");
        iconElement.style.marginRight = "8px";
        iconElement.innerHTML = `
        <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" fill="#000000" stroke="#000000" stroke-width="1">
        <path d="M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"/>
      </svg>        `;

        tooltip.appendChild(iconElement);

        const textSpan = iframeDoc.createElement("span");
        textSpan.textContent = "Share Link";
        tooltip.appendChild(textSpan);

        tooltip.addEventListener("click", () => {
          removeTooltip();
        });

        const iframeScrollLeft =
          iframeDoc.documentElement.scrollLeft || iframeDoc.body.scrollLeft;
        const iframeScrollTop =
          iframeDoc.documentElement.scrollTop || iframeDoc.body.scrollTop;

        const tooltipX =
          rect.left +
          iframeScrollLeft +
          rect.width / 2 -
          tooltip.offsetWidth / 2;
        const tooltipY = rect.top + iframeScrollTop - tooltip.offsetHeight - 15;

        tooltip.style.top = `${tooltipY}px`;
        tooltip.style.left = `${tooltipX}px`;
        tooltip.style.display = "block";
      }
    };

    const removeTooltip = () => {
      const existingTooltip = iframeDoc.querySelector(`#${tooltipId}`);
      if (existingTooltip) {
        existingTooltip.style.display = "none";
      }
    };
  };

  const highlightSelectedText = (iframeDoc) => {
    const urlParams = new URLSearchParams(window.location.search);
    const highlightData = urlParams.get("highlight");

    if (highlightData) {
      const storedData = JSON.parse(decodeURIComponent(highlightData));

      if (
        storedData &&
        storedData.id &&
        storedData.accessionNo === accessionNo
      ) {
        let { startOffset, endOffset } = storedData;

        // If startOffset and endOffset are the same, increment the endOffset by 8
        if (startOffset === endOffset) {
          endOffset = startOffset + 8;
        }

        let currentOffset = 0;
        const walker = iframeDoc.createTreeWalker(
          iframeDoc.body,
          NodeFilter.SHOW_TEXT,
          null,
          false
        );

        while (walker.nextNode()) {
          const node = walker.currentNode;
          const nodeText = node.textContent;
          const nodeLength = nodeText.length;

          if (
            currentOffset + nodeLength > startOffset &&
            currentOffset <= endOffset
          ) {
            const range = iframeDoc.createRange();
            const nodeStartOffset = Math.max(0, startOffset - currentOffset);
            const nodeEndOffset = Math.min(
              nodeLength,
              endOffset - currentOffset
            );

            if (
              nodeStartOffset >= 0 &&
              nodeEndOffset >= nodeStartOffset &&
              nodeEndOffset <= nodeLength
            ) {
              range.setStart(node, nodeStartOffset);
              range.setEnd(node, nodeEndOffset);

              const documentFragment = range.extractContents();
              const span = iframeDoc.createElement("span");
              span.id = storedData.id;
              span.className = "highlighted-text";
              span.style.backgroundColor = "yellow";
              span.dataset.startIndex = nodeStartOffset;
              span.dataset.endIndex = nodeEndOffset;
              span.dataset.highlightId = storedData.id;

              span.appendChild(documentFragment);
              range.insertNode(span);

              span.scrollIntoView({ behavior: "smooth", block: "center" });
              break;
            }
          }

          currentOffset += nodeLength;
        }
      }
    }
  };

  const loadInitialContent = useCallback(
    async (url) => {
      try {
        const response = await fetch(url);
        const contentType = response.headers.get("Content-Type");

        const iframe = iframeRef.current;

        if (
          contentType.includes("text/plain") ||
          contentType.includes("text/html")
        ) {
          const textContent = await response.text();
          const cleanedBody = cleanHtml(textContent);

          if (iframe) {
            const iframeDoc =
              iframe.contentDocument || iframe.contentWindow.document;
            const body = iframeDoc.body;
            body.innerHTML = "";
            body.appendChild(cleanedBody);

            insertTableButtons(iframeDoc);
            interceptLinkClicks(iframeDoc);
            handleTextSelection(iframeDoc);
            highlightSelectedText(iframeDoc);
            scrollToTable(iframeDoc);
          }
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    },
    [cleanHtml, cik, form_type, accessionNo, navigate]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        comparePopupRef.current &&
        !comparePopupRef.current.contains(event.target)
      ) {
        setComparePopUpOpened(false); // Close the popup
      }
    };

    // Add the mousedown event listener when the popup is open
    if (comparePopUpOpened) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Cleanup the event listener on unmount or when the popup closes
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [comparePopUpOpened]);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.onload = () => {
        const iframeDoc =
          iframe.contentDocument || iframe.contentWindow.document;
        highlightSelectedText(iframeDoc);
        scrollToTable(iframeDoc);
      };
    }
  }, [iframeRef.current]);

  useEffect(() => {
    const handleIframeFocus = () => {
      setComparePopUpOpened(false); // Close the popup when the iframe is focused
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener("focus", handleIframeFocus);
    }
    return () => {
      if (iframe) {
        iframe.removeEventListener("focus", handleIframeFocus);
      }
    };
  }, [iframeRef.current]);

  useEffect(() => {
    const highlightedSummaries = summarizedFilingData
      .map((summarizedFiling, index) => {
        const filingSections =
          allFilingSections[form_type] || allFilingSections.misc;
        const sectionTitle = filingSectionHeadings[filingSections[index]]
          ?.titleCase
          ? `<h3 class="heading">${
              filingSectionHeadings[filingSections[index]]?.titleCase
            }</h3>`
          : "";
        if (!summarizedFiling?.data) {
          return `${sectionTitle} <p>Summary not found. Please refresh!</p> <br />`;
        }
        const summaryText = marked.parse(summarizedFiling.data.summary);
        return `${sectionTitle} ${summaryText} <br />`;
      })
      .join("");
    setHighlightedSummary(highlightedSummaries);
  }, [summarizedFilingData, form_type, isSummarizedFilingLoading]);

  useEffect(() => {
    if (displayingFiling && displayingFiling.url) {
      loadInitialContent(displayingFiling.url);
    }
  }, [displayingFiling, loadInitialContent]);

  useEffect(() => {
    const handleResize = () => {
      setDocumentsContainerHeight(window.innerHeight - 160);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`transition-all duration-300 ${
        collapsed ? "ml-[100px]" : "medium-large:ml-[320px]"
      } p-8 overflow-hidden h-full`}
    >
      <LogoWithMenuToggle navigationBarStore={navigationBarStore} />
      <div className="mt-2">
        <div className="flex items-end space-x-2 border-gray-300">
          <button
            className={`${
              activeTab === "filing"
                ? "bg-white-xsShade text-indigo-500 bg-white rounded-t-lg"
                : "text-gray-500 bg-gray-200 rounded-t-lg"
            } 
  xxs:px-2 xxs:py-1 xxs:w-[80px] xxs:h-[40px] xxs:text-xs xxs:whitespace-nowrap xxs:overflow-hidden xxs:text-ellipsis
  xs:px-3 xs:py-1.5 xs:w-[100px] xs:h-[45px] xs:text-sm  // Override xxs styles for xs and up
  md:px-4 md:py-2 md:w-auto md:h-auto md:text-base`} // Override xs styles for md and larger
            style={{
              display: "inline-block",
              maxWidth: "100%",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }} // Force ellipsis
            onClick={() => setActiveTab("filing")}
          >
            {displayingFiling
              ? displayingFiling.title || "Something went wrong..."
              : "Loading filing..."}
          </button>

          {isSummaryEligible && (
            <button
              className={`${
                activeTab === "summary"
                  ? "bg-white-xsShade text-indigo-500 bg-white rounded-t-lg"
                  : "text-gray-500 bg-gray-200 rounded-t-lg"
              } 
  xxs:px-2 xxs:py-1 xxs:w-[80px] xxs:h-[40px] xxs:text-xs xxs:whitespace-nowrap xxs:overflow-hidden xxs:text-ellipsis // Styles for xxs (250px and below)
  xs:px-3 xs:py-1.5 xs:w-[100px] xs:h-[45px] xs:text-sm // Styles for xs (456px and up)
  md:px-4 md:py-2 md:w-auto md:h-auto md:text-base`} // Styles for md (768px and up)
              style={{
                display: "inline-block",
                maxWidth: "100%",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              onClick={() => setActiveTab("summary")}
            >
              {isSummarizedFilingLoading
                ? "Loading filing summary..."
                : "Filing Summary"}
            </button>
          )}
          <button
            className={`${
              activeTab === "wingman"
                ? "bg-white-xsShade text-indigo-500 bg-white rounded-t-lg"
                : "text-gray-500 bg-gray-200 rounded-t-lg"
            } 
  xxs:px-2 xxs:py-1 xxs:w-[80px] xxs:h-[40px] xxs:text-xs xxs:whitespace-nowrap xxs:overflow-hidden xxs:text-ellipsis // Styles for xxs (250px and below)
  xs:px-3 xs:py-1.5 xs:w-[100px] xs:h-[45px] xs:text-sm // Styles for xs (456px and up)
  md:px-4 md:py-2 md:w-auto md:h-auto md:text-base`} // Styles for md (768px and up)
            style={{
              display: "inline-block",
              maxWidth: "100%",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            onClick={() => setActiveTab("wingman")}
          >
            Wingman
          </button>
        </div>

        <section
          className={`${
            isFullScreen ? "grid grid-rows-10" : "flex flex-col"
          } items-center gap-8`}
          style={{ height: documentsContainerHeight }}
        >
          {activeTab === "filing" && (
            <article
              className={`${
                isFullScreen
                  ? "row-span-10"
                  : isDisplayingSummaries
                  ? "row-span-5"
                  : "row-span-9"
              } w-full h-full overflow-hidden group`}
              style={{
                borderRadius: "1rem",
                borderTopLeftRadius: "0",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                className="flex items-center justify-between px-4 py-2 bg-white-xsShade relative h-[52px] rounded-none"
                style={{
                  borderTopLeftRadius: "0",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  zIndex: 1,
                }}
              >
                <h3 className="font-primary font-semibold text-lg text-left h-auto overflow-hidden text-ellipsis line-clamp-1">
                  {displayingFiling
                    ? displayingFiling.title
                    : "Loading filing..."}
                </h3>
                {displayingFiling && (
                  <div className="flex items-center gap-4">
                    {(form_type === "10-K" || form_type === "10-Q") && (
                      <>
                        <button
                          className="text-black-mdTint"
                          title="Download all Tables"
                          aria-label="Download all Tables"
                          onClick={async () => {
                            try {
                              const response = await fetchAllTables(
                                accessionNo,
                                form_type,
                                cik
                              );
                              if (response && response.url) {
                                window.location.href = response.url;
                              } else {
                                alert(
                                  "Tables cannot be downloaded currently, try again later."
                                );
                              }
                            } catch (error) {
                              console.error(
                                "Error fetching all tables data:",
                                error
                              );
                              alert(
                                "There was an error fetching the tables. Please try again later."
                              );
                            }
                          }}
                        >
                          <LuTable />
                        </button>
                      </>
                    )}
                    <button
                      className="text-salmon"
                      onClick={refetchDisplayFiling}
                      title="Try again"
                      aria-label="Try again"
                    >
                      <GrPowerReset />
                    </button>
                    <div className="relative">
                      <button
                        className="bg-lavender-primary text-white-primary font-primary py-2 px-4 font-medium rounded-2xl hover:bg-lavender-primary/90 text-sm flex items-center gap-1"
                        onClick={() => setComparePopUpOpened((prev) => !prev)}
                      >
                        <MdCompareArrows />
                        Compare
                      </button>

                      {/* Compare Popup */}
                      {comparePopUpOpened && (
                        <div ref={comparePopupRef}>
                          <ComparePopup />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {displayingFiling ? (
                <iframe
                  id="documentIframe"
                  ref={iframeRef}
                  className="w-full h-document-full-height-offset border-none"
                  title="Document Content"
                  style={{
                    borderRadius: "0 0 1rem 1rem",
                  }}
                  src={form_type === "144" ? displayingFiling?.url : undefined}
                ></iframe>
              ) : (
                <div className="relative h-document-full-height-offset w-full">
                  <div className="h-full flex items-center justify-center w-1/2 mx-auto">
                    <LoadingBar />
                  </div>
                </div>
              )}
            </article>
          )}
          {isSummaryEligible && activeTab === "summary" && (
            <article
              className="row-span-5 h-full w-full overflow-hidden group relative"
              style={{
                borderRadius: "1rem",
                borderTopLeftRadius: "0",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                className="flex items-center justify-between px-4 py-2 bg-white-xsShade relative h-[52px] rounded-none"
                style={{
                  borderTopLeftRadius: "0",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  zIndex: 1,
                }}
              >
                <h3 className="font-primary font-semibold text-lg">
                  {isSummarizedFilingLoading
                    ? "Loading filing summary..."
                    : "Filing summary"}
                </h3>
                <div className="flex items-center gap-4">
                  {generatedSummary && !isSummarizedFilingLoading && (
                    <>
                      <button
                        className="text-salmon"
                        onClick={() => refetchFilingSummary()}
                        title="Try again"
                        aria-label="Try again"
                      >
                        <GrPowerReset />
                      </button>
                    </>
                  )}
                </div>
              </div>
              {!isSummarizedFilingLoading ? (
                <div className="w-full h-document-full-height-offset transition-none duration-300 text-start py-4 px-8 overflow-y-scroll relative scroll-smooth">
                  <div
                    className="summary-content"
                    dangerouslySetInnerHTML={{
                      __html: highlightedSummary,
                    }}
                  />
                </div>
              ) : (
                <div className="h-full flex items-center justify-center w-1/2 mx-auto">
                  <LoadingBar />
                </div>
              )}
            </article>
          )}
          {activeTab === "wingman" && (
            <article
              className="row-span-5 h-full w-full overflow-hidden group relative"
              style={{
                borderRadius: "1rem",
                borderTopLeftRadius: "0",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                className="flex items-center justify-between px-4 py-2 bg-white-xsShade relative h-[52px] rounded-none"
                style={{
                  borderTopLeftRadius: "0",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  zIndex: 1,
                }}
              >
                <h3 className="font-primary font-semibold text-lg">Wingman</h3>
                <div className="flex items-center gap-4"></div>
              </div>
              <div className="w-full h-document-full-height-offset font-primary transition-none duration-300 text-start py-4 px-8 overflow-y-scroll relative scroll-smooth flex flex-col">
                <div className="max-w-3xl mx-auto text-center">
                  <p
                    className="text-xl font-bold mb-2 text-center"
                    style={{ fontSize: "1.25rem" }}
                  >
                    Introducing:
                  </p>
                  <p
                    className="text-3xl font-bold font-primary mb-2 text-center flex items-center justify-center"
                    style={{ fontWeight: "600" }}
                  >
                    <img
                      src={WingmanLeft}
                      alt="Wingman Left"
                      className="w-8 h-8 mr-2"
                    />
                    Wingman
                    <img
                      src={WingmanRight}
                      alt="Wingman Right"
                      className="w-8 h-8 ml-2"
                    />
                  </p>

                  <p
                    className="text-lg ml-2 mb-4 text-center"
                    style={{ fontSize: "1rem", maxWidth: "100%" }}
                  >
                    All of your questions about any filing, answered.
                  </p>
                </div>

                <div className="flex flex-col space-y-2">
                  <div className="flex justify-end">
                    <div
                      className="bg-lavender-smTint p-4 rounded-lg"
                      style={{
                        borderRadius: "3rem",
                        fontSize: "0.8rem",
                        lineHeight: "1.5rem",
                        maxWidth: "400px",
                        textAlign: "center",
                      }}
                    >
                      What are Apple’s key revenue drivers?
                    </div>
                  </div>

                  <div className="flex">
                    <div className="max-w-3xl self-start flex-grow">
                      <div
                        className="bg-gray-100 p-4 rounded-lg shadow-lg text-left"
                        style={{
                          borderRadius: "3rem",
                          fontSize: "0.8rem",
                          lineHeight: "1.3rem",
                        }}
                      >
                        <p className="mb-2">
                          In Apple's Q3 2024 10-Q filing, the key revenue
                          drivers for the company are primarily its products and
                          services. The major segments contributing to revenue
                          include:
                        </p>
                        <ol className="list-decimal list-inside space-y-2">
                          <li>
                            <strong>iPhone:</strong> The iPhone is Apple's
                            largest revenue driver, consistently generating the
                            highest percentage of the company’s total net sales.
                            It is a crucial product in Apple's lineup.{" "}
                            <a href="#" className="text-indigo-600">
                              [1]
                            </a>
                          </li>
                          <li>
                            <strong>iPad:</strong> The iPad also contributes
                            significantly to Apple’s revenue, although it is
                            smaller compared to the iPhone. The iPad segment
                            includes all versions of the iPad tablet.{" "}
                            <a href="#" className="text-indigo-600">
                              [2]
                            </a>
                          </li>
                          <li>
                            <strong>Mac:</strong> This segment includes the
                            MacBook, MacBook Pro, iMac, Mac Pro, and other
                            desktop and portable computers. It is another
                            important revenue stream for Apple.{" "}
                            <a href="#" className="text-indigo-600">
                              [3]
                            </a>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end mt-8">
                    <div
                      className="bg-lavender-smTint p-4 rounded-lg"
                      style={{
                        borderRadius: "3rem",
                        fontSize: "0.8rem",
                        lineHeight: "1.3rem",
                        maxWidth: "400px",
                        textAlign: "center",
                      }}
                    >
                      What did management say about key risks?
                    </div>
                  </div>
                </div>

                <div className="mt-8 text-center">
                  <h2
                    className="text-xl font-primary font-bold mb-2"
                    style={{ fontSize: "1.1rem", fontWeight: "600" }}
                  >
                    Coming November 2024
                  </h2>
                  <button
                    className="bg-lavender-primary font-primary py-3 px-6 rounded-lg font-semibold"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: "#ffffff",
                    }}
                  >
                    Learn more
                  </button>
                </div>
              </div>
            </article>
          )}
        </section>
      </div>
    </div>
  );
});

export default DocumentsContent;
