import axios from "../components/axiosConfig"; // import the Axios instance configured with the interceptor
import { allFilingSections } from "../constants";

var API_URL_SEARCH = process.env.REACT_APP_BACKEND_URL + "sec/search-tickers";
var API_URL_FINANCIALS =
  process.env.REACT_APP_BACKEND_URL + "sec/fetch-financials";
var API_URL_FILINGS =
  process.env.REACT_APP_BACKEND_URL + "sec/fetch-filings-list";
var API_URL_ALL_FILINGS =
  process.env.REACT_APP_BACKEND_URL + "sec/fetch-all-filings-list";
var API_URL_INSIDER_TRADING =
  process.env.REACT_APP_BACKEND_URL + "sec/fetch-insider-trading";
var API_URL_EARNINGS_TRANSCRIPT_LIST =
  process.env.REACT_APP_BACKEND_URL + "sec/fetch-earnings-transcripts-list";
var API_URL_EARNINGS_TRANSCRIPT =
  process.env.REACT_APP_BACKEND_URL + "sec/fetch-earnings-transcript";
var API_URL_EARNINGS_TRANSCRIPT_SUMMARY =
  process.env.REACT_APP_BACKEND_URL + "sec/fetch-earnings-transcript-summary";
var API_URL_ANALYZE_INCOME =
  process.env.REACT_APP_BACKEND_URL + "sec/analyze-income";
var API_URL_FILING = process.env.REACT_APP_BACKEND_URL + "sec/fetch-filing";
var API_URL_SUMMARIZED_FILING =
  process.env.REACT_APP_BACKEND_URL + "sec/fetch-summary";
var API_URL_ANALYZE_STATISTICS =
  process.env.REACT_APP_BACKEND_URL + "sec/analyze-statistics";
var API_URL_SEARCH_ACROSS_FILINGS =
  process.env.REACT_APP_BACKEND_URL + "sec/search-across-filings";
var API_URL_GET_USER_INFO = process.env.REACT_APP_BACKEND_URL + "get-user-info";
var API_URL_GET_USER_PROFILE =
  process.env.REACT_APP_BACKEND_URL + "get-user-profile";
var API_URL_GET_SCREENER_COMPANIES =
  process.env.REACT_APP_BACKEND_URL + "get-screener-companies";
var API_URL_SAVE_SCREENER = process.env.REACT_APP_BACKEND_URL + "save-screener";
var API_URL_SAVE_ALERT = process.env.REACT_APP_BACKEND_URL + "save-alert";
// var API_URL_GET_CSRF_TOKEN = process.env.REACT_APP_BACKEND_URL + "csrf-token";
// var API_URL_GET_TABLE = process.env.REACT_APP_BACKEND_URL + "sec/get-table";
var API_URL_GET_SCREENERS =
  process.env.REACT_APP_BACKEND_URL + "get-screeners-list";
var API_URL_GET_SCREENER = process.env.REACT_APP_BACKEND_URL + "get-screener";
var API_URL_GET_ALERT = process.env.REACT_APP_BACKEND_URL + "get-alert";
var API_URL_DELETE_SCREENER =
  process.env.REACT_APP_BACKEND_URL + "delete-screener";
var API_URL_DELETE_ALERT = process.env.REACT_APP_BACKEND_URL + "delete-alert";
var API_URL_GET_ALERTS_LIST =
  process.env.REACT_APP_BACKEND_URL + "get-alerts-list";
var API_URL_GET_NOTIFICATIONS =
  process.env.REACT_APP_BACKEND_URL + "get-notifcations";
var API_URL_GET_RECENT_FILES =
  process.env.REACT_APP_BACKEND_URL + "get-recent-files";

var API_KEY = encodeURIComponent(process.env.REACT_APP_API_KEY);
axios.defaults.withCredentials = true;

// Existing functions...

function checkAndHandle401(res) {
  if (res?.status === 401 && window.location.pathname !== "/login") {
    window.location.href = "/login";
  }
}

export async function fetchEarningsTranscriptSummary(accessionNo) {
  var config = {
    method: "get",
    url: `${API_URL_EARNINGS_TRANSCRIPT_SUMMARY}?key=${API_KEY}&accessionNo=${encodeURIComponent(
      accessionNo
    )}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    var response = await axios.request(config);
    console.log("Earnings transcript summary data:", response.data);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.error("Error fetching earnings transcript summary data:", error);
    return { data: [], total: 0 };
  }
}

export async function fetchEarningsTranscript(accessionNo) {
  var config = {
    method: "get",
    url: `${API_URL_EARNINGS_TRANSCRIPT}?key=${API_KEY}&accessionNo=${encodeURIComponent(
      accessionNo
    )}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    var response = await axios.request(config);
    console.log("Earnings transcript data:", response.data);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.error("Error fetching earnings transcript data:", error);
    return { data: [], total: 0 };
  }
}

export async function fetchEarningsTranscriptsList(cik, year) {
  var config = {
    method: "get",
    url: `${API_URL_EARNINGS_TRANSCRIPT_LIST}?key=${API_KEY}&cik=${encodeURIComponent(
      cik
    )}&year=${encodeURIComponent(year)}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    var response = await axios.request(config);
    checkAndHandle401(response);
    console.log("Earnings transcripts data:", response.data);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.error("Error fetching earnings transcripts data:", error);
    return { data: [], total: 0 };
  }
}

export async function fetchData(value) {
  var config = {
    method: "get",
    url: `${API_URL_SEARCH}?query=${encodeURIComponent(
      value
    )}&key=${API_KEY}&appid=${API_KEY}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    var response = await axios.request(config);
    console.log("Response data:", response.data);

    var lowerValue = value.toLowerCase();

    var results = response.data.data.filter(function (item) {
      return (
        (item &&
          item.ticker &&
          item.ticker.toLowerCase().includes(lowerValue)) ||
        (item && item.name && item.name.toLowerCase().includes(lowerValue))
      );
    });

    var sortedResults = results.sort(function (a, b) {
      var aTickerMatch = a.ticker.toLowerCase() === lowerValue;
      var bTickerMatch = b.ticker.toLowerCase() === lowerValue;
      var aNameMatch = a.name.toLowerCase() === lowerValue;
      var bNameMatch = b.name.toLowerCase() === lowerValue;

      if (aTickerMatch && !bNameMatch) return -1;
      if (!aTickerMatch && bNameMatch) return 1;
      if (aNameMatch && !bNameMatch) return -1;
      if (!aNameMatch && bNameMatch) return 1;

      return 0;
    });

    return sortedResults;
  } catch (error) {
    checkAndHandle401(error.response);
    console.error("Error fetching data:", error);
    return [];
  }
}

export async function fetchFinancials(cik) {
  var config = {
    method: "get",
    url: `${API_URL_FINANCIALS}?cik=${encodeURIComponent(cik)}&key=${API_KEY}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  console.log("Request URL:", config.url); // Log the entire URL

  try {
    var response = await axios.request(config);
    console.log("Financial data:", response.data);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.error("Error fetching financial data:", error);
    return {};
  }
}

export async function fetchFilings(
  cik,
  groupType,
  page = 1,
  year = "",
  month = "",
  formType = ""
) {
  const config = {
    method: "get",
    url: `${API_URL_FILINGS}?cik=${encodeURIComponent(
      cik
    )}&group_type=${encodeURIComponent(
      groupType
    )}&page=${page}&year=${encodeURIComponent(year)}&month=${encodeURIComponent(
      month
    )}&form_type=${encodeURIComponent(
      formType
    )}&key=${API_KEY}&appid=${API_KEY}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    var response = await axios.request(config);
    console.log("Filings data:", response.data);
    console.log(response.status);
    return response.data || { data: [], total: 0, groupType: "" };
  } catch (error) {
    checkAndHandle401(error.response);
    console.error("Error fetching filings data:", error);
    return { data: null, total: 0, groupType: "" };
  }
}

export async function fetchAllFilings(
  cik,
  page = 1,
  formType = "",
  year = "",
  month = ""
) {
  const config = {
    method: "get",
    url: `${API_URL_ALL_FILINGS}?cik=${encodeURIComponent(
      cik
    )}&page=${page}&form_type=${encodeURIComponent(
      formType
    )}&year=${encodeURIComponent(year)}&month=${encodeURIComponent(
      month
    )}&key=${API_KEY}&appid=${API_KEY}&group_type=financials`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.request(config);
    console.log("All filings data:", response.data);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.error("Error fetching all filings data:", error);
    return { data: [], total: 0 };
  }
}
export async function fetchFiling(accessionNo, form_type) {
  // Extract CIK from the current window location path
  const pathSegments = window.location.pathname.split("/");
  const cik = pathSegments[1]; // Assuming CIK is the first segment after the root

  if (!cik) {
    console.error("CIK is not found in the URL path");
    return {};
  }
  const url = `${API_URL_FILING}?key=${API_KEY}&accessionNo=${accessionNo}&form_type=${form_type}&cik=${cik}`;

  console.log(`Requesting URL: ${url}`);

  var config = {
    method: "get",
    url: `${API_URL_FILING}?key=${API_KEY}&accessionNo=${accessionNo}&form_type=${form_type}&cik=${cik}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    var response = await axios.request(config);
    console.log(
      `Filing data for accessionNo, form_type, and cik: ${accessionNo}, ${form_type}, ${cik}, ${response.data}`
    );
    return response.data || {};
  } catch (error) {
    checkAndHandle401(error.response);
    console.error(
      `Error fetching filing data for accessionNo, form_type, and cik: ${accessionNo}, ${form_type}, ${cik}, ${error}`
    );
    return {};
  }
}

export async function fetchSummarizedFiling(accessionNo, form_type) {
  const originalFormType = form_type;
  if (form_type !== "10-Q" && form_type !== "10-K") {
    form_type = "misc";
  }
  const sectionSummaries = {};
  try {
    await Promise.all(
      allFilingSections[form_type].map(async (section) => {
        const config = {
          method: "get",
          url: `${API_URL_SUMMARIZED_FILING}?key=${API_KEY}&accessionNo=${accessionNo}&section=${section}&form_type=${originalFormType}`,
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.request(config);
        if (response && response.data) {
          sectionSummaries[section] = response.data;
        }
      })
    );
    console.log(
      allFilingSections[form_type]?.map((section) => sectionSummaries[section])
    );
    return allFilingSections[form_type]?.map(
      (section) => sectionSummaries[section]
    );
  } catch (error) {
    checkAndHandle401(error.response);
    console.error(
      `Error fetching filing summaries for accessionNo: ${accessionNo}, ${error}`
    );
    return [];
  }
}

export async function fetchSummarizedFilingSection(
  accessionNo,
  form_type,
  section
) {
  try {
    const config = {
      method: "get",
      url: `${API_URL_SUMMARIZED_FILING}?key=${API_KEY}&accessionNo=${accessionNo}&section=${section}&form_type=${form_type}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.error(
      `Error fetching filing summary for accessionNo and section ${accessionNo}, ${section}, ${error}`
    );
    return null;
  }
}

export async function fetchInsiderTrading(
  cik,
  page = 1,
  selected = "custom",
  filterOptions = {}
) {
  const filters = Object.keys(filterOptions)
    .filter((key) => filterOptions[key])
    .map((key) => "filter=" + encodeURIComponent(key))
    .join("&");

  const url =
    `${API_URL_INSIDER_TRADING}?page=${page}` +
    `&key=${API_KEY}` +
    `&cik=${encodeURIComponent(cik)}` +
    `&selected=${encodeURIComponent(selected)}` +
    (filters ? `&${filters}` : "");

  const config = {
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.request(config);
    console.log("Insider trading data:", response.data);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.error("Error fetching insider trading data:", error);
    return { data: [], total: 0 };
  }
}

export async function fetchAnalyzeIncome(ticker, options = []) {
  var queryParams = options
    .map((option) => `option=${encodeURIComponent(option)}`)
    .join("&");

  var config = {
    method: "get",
    url: `${API_URL_ANALYZE_INCOME}?key=${API_KEY}&ticker=${encodeURIComponent(
      ticker
    )}&${queryParams}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    var response = await axios.request(config);
    console.log("Analyze Income data:", response.data);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.error("Error fetching analyze income data:", error);
    return { data: [], total: 0 };
  }
}

export async function fetchInsiderTradingWithFilterS(ticker, page = 1) {
  var filterOptions = { S: true };
  return fetchInsiderTrading(ticker, page, "custom", filterOptions);
}

export async function fetchAnalyzeStatistics(ticker, options = []) {
  var queryParams = options
    .map((option) => `option=${encodeURIComponent(option)}`)
    .join("&");

  var config = {
    method: "get",
    url: `${API_URL_ANALYZE_STATISTICS}?key=${API_KEY}&ticker=${encodeURIComponent(
      ticker
    )}&${queryParams}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    var response = await axios.request(config);
    console.log("Analyze Statistics data:", response.data);
    return response.data || {};
  } catch (error) {
    checkAndHandle401(error.response);
    console.error("Error fetching analyze statistics data:", error);
    return {};
  }
}
// At the end of your api.js file
export async function searchAcrossFilings(cik, phrase) {
  const config = {
    method: "get",
    url: `${API_URL_SEARCH_ACROSS_FILINGS}?key=${API_KEY}&cik=${encodeURIComponent(
      cik
    )}&phrase=${encodeURIComponent(phrase)}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.request(config);
    console.log("Search across filings data:", response.data);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.error("Error searching across filings:", error);
    return { data: [], total: 0 };
  }
}

export async function logout() {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_URL}logout`,
  };
  await axios.request(config);
}

export async function googleLogin() {
  try {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}login`,
    };
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log("Something went wrong when authenticating", { error });
    return null;
  }
}

export async function getUserInfo() {
  try {
    var config = {
      method: "get",
      url: `${API_URL_GET_USER_INFO}?key=${API_KEY}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    var response = await axios.request(config);
    console.log(response);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.log("Something went wrong when fetching user info", { error });
    return null;
  }
}

export async function getUserProfile() {
  try {
    var config = {
      method: "get",
      url: `${API_URL_GET_USER_PROFILE}?key=${API_KEY}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.log("Something went wrong when fetching user profile", { error });
    return null;
  }
}

export async function getScreeners() {
  try {
    var config = {
      method: "get",
      url: `${API_URL_GET_SCREENERS}?key=${API_KEY}`,
    };
    const response = await axios.request(config);
    console.log(response);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.log("Something went wrong when fetching saved screeners", {
      error,
    });
    return null;
  }
}

export async function getScreener(name) {
  try {
    var config = {
      method: "get",
      url: `${API_URL_GET_SCREENER}?key=${API_KEY}&name=${name}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.request(config);
    console.log(response);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.log("Something went wrong when fetching saved screener", {
      error,
    });
    return null;
  }
}

export async function getAlert(name) {
  try {
    var config = {
      method: "get",
      url: `${API_URL_GET_ALERT}?key=${API_KEY}&name=${name}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.request(config);
    console.log(response);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.log("Something went wrong when fetching saved alert", {
      error,
    });
    return null;
  }
}

export async function deleteScreener(name) {
  try {
    var config = {
      method: "post",
      url: `${API_URL_DELETE_SCREENER}?key=${API_KEY}`,
      headers: {
        "X-CSRFToken": document.cookie
          .split("; ")
          .find((row) => row.startsWith("csrf_token="))
          ?.split("=")[1],
        "Content-Type": "application/json",
      },
      data: { name },
    };
    const response = await axios.request(config);
    console.log(response);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.log("Something went wrong when removing screener", {
      error,
    });
    return null;
  }
}

export async function deleteAlert(name) {
  try {
    var config = {
      method: "post",
      url: `${API_URL_DELETE_ALERT}?key=${API_KEY}`,
      headers: {
        "X-CSRFToken": document.cookie
          .split("; ")
          .find((row) => row.startsWith("csrf_token="))
          ?.split("=")[1],
        "Content-Type": "application/json",
      },
      data: { name },
    };
    const response = await axios.request(config);
    console.log(response);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.log("Something went wrong when removing alert", {
      error,
    });
    return null;
  }
}

export async function getScreenerCompanies(data, page) {
  try {
    var config = {
      method: "post",
      url: `${API_URL_GET_SCREENER_COMPANIES}?key=${API_KEY}&page=${page}`,
      headers: {
        "X-CSRFToken": document.cookie
          .split("; ")
          .find((row) => row.startsWith("csrf_token="))
          ?.split("=")[1],
      },
      data: { ...data },
    };
    const response = await axios.request(config);
    console.log(response);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.log("Something went wrong when finding screener companies", {
      error,
    });
    return null;
  }
}

export async function saveScreener(data) {
  try {
    var config = {
      method: "post",
      url: `${API_URL_SAVE_SCREENER}?key=${API_KEY}`,
      headers: {
        "X-CSRFToken": document.cookie
          .split("; ")
          .find((row) => row.startsWith("csrf_token="))
          ?.split("=")[1],
        "Content-Type": "application/json",
      },
      data: { ...data },
    };
    const response = await axios.request(config);
    console.log(response);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.log("Something went wrong when saving screener", { error });
    return null;
  }
}

export async function saveAlert(data) {
  try {
    var config = {
      method: "post",
      url: `${API_URL_SAVE_ALERT}?key=${API_KEY}`,
      headers: {
        "X-CSRFToken": document.cookie
          .split("; ")
          .find((row) => row.startsWith("csrf_token="))
          ?.split("=")[1],
        "Content-Type": "application/json",
      },
      data: { ...data },
    };
    const response = await axios.request(config);
    console.log(response);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.log("Something went wrong when saving alert", { error });
    return null;
  }
}

export async function fetchTable(table_id, accessionNo, form_type, cik) {
  // Construct the correct URL with /sec/
  const url = `${process.env.REACT_APP_BACKEND_URL}sec/get-table?key=${
    process.env.REACT_APP_API_KEY
  }&table_id=${encodeURIComponent(table_id)}&accessionNo=${encodeURIComponent(
    accessionNo
  )}&form_type=${encodeURIComponent(form_type)}&cik=${encodeURIComponent(cik)}`;

  console.log("API Request URL:", url); // Log the correct URL

  var config = {
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    var response = await axios.request(config);
    console.log("Table data:", response.data);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.error("Error fetching table data:", error);
    return null;
  }
}

export async function fetchAllTables(accessionNo, form_type, cik) {
  // Construct the correct URL with /sec/
  const url = `${process.env.REACT_APP_BACKEND_URL}sec/get-all-tables?key=${
    process.env.REACT_APP_API_KEY
  }&accessionNo=${encodeURIComponent(
    accessionNo
  )}&form_type=${encodeURIComponent(form_type)}&cik=${encodeURIComponent(cik)}`;

  console.log("API Request URL:", url); // Log the correct URL

  var config = {
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    var response = await axios.request(config);
    console.log("Table data:", response.data);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.error("Error fetching table data:", error);
    return null;
  }
}

export async function fetchAlertsList() {
  const config = {
    method: "get",
    url: `${API_URL_GET_ALERTS_LIST}?key=${API_KEY}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.request(config);
    console.log("Alerts data:", response.data);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.error("Error fetching alerts data:", error);
    return null;
  }
}

export async function getNotifications() {
  const config = {
    method: "get",
    url: `${API_URL_GET_NOTIFICATIONS}?key=${API_KEY}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.request(config);
    console.log("Notifications data:", response.data);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.error("Error fetching notifications data:", error);
    return null;
  }
}

export async function getRecentFiles() {
  const config = {
    method: "get",
    url: `${API_URL_GET_RECENT_FILES}?key=${API_KEY}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.request(config);
    console.log("Recent Files data:", response.data);
    return response.data;
  } catch (error) {
    checkAndHandle401(error.response);
    console.error("Error fetching recent files data:", error);
    return null;
  }
}
