import React, { useEffect, useState } from "react";
import {
  convertMoneySuffixToMoney,
  convertMoneyToMoneySuffix,
  defaultScreenerAdjustments,
} from "../constants";
import ScreenerAdjustments from "./ScreenerAdjustments";
import {
  fetchFinancials,
  getScreener,
  getScreenerCompanies,
  saveScreener,
} from "../services/api";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import "../styles/documents.css";
import financialStore from "../stores/financialStore";
import Alert from "./Alert";
import LoadingSpinner from "./LoadingSpinner";
import { GrPowerReset } from "react-icons/gr";
import LogoWithMenuToggle from "./LogoWithMenuToggle";
import { observer } from "mobx-react";

const ScreenerSetup = observer(() => {
  // screener set up is very complicated, the default value is set in such a way that displaying would be easiest
  const [screenerType, setScreenerType] = useState("General");
  const [adjustments, setAdjustments] = useState(defaultScreenerAdjustments);
  const [companyResults, setCompanyResults] = useState(null);
  const [screenerTitle, setScreenerTitle] = useState("");
  const [error, setError] = useState("");
  const [type, setType] = useState(""); // types can be create, edit, error, or ""
  const [screenerContainerHeight, setScreenerContainerHeight] = useState(
    window.innerHeight - 142
  );
  const [page, setPage] = useState(0);
  const [fetchingCompanies, setFetchingCompanies] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emptyPage, setEmptyPage] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [navigationBarStore] = useOutletContext();

  useEffect(() => {
    // use effect for existing screener being referenced, EDIT FUNCTIONALITY TO POPULATING DEFAULT ADJUSTMENTS
    if (location?.state?.reference) {
      const populateAdjustments = async () => {
        const screenerAdjustments = await getScreener(location.state.reference);
        if (screenerAdjustments && screenerAdjustments?.status === "success") {
          // instantiate the populated adjustments as the default adjustments but don't point it to the same reference in memory
          const populatedAdjustments = JSON.parse(
            JSON.stringify(defaultScreenerAdjustments)
          );
          Object.entries(screenerAdjustments.data).forEach(([key, value]) => {
            // if referenced screener adjustment is part of the modifiable adjustments
            if (defaultScreenerAdjustments[key.replace(/(_min|_max)$/, "")]) {
              switch (
                populatedAdjustments[key.replace(/(_min|_max)$/, "")].type
              ) {
                // if type is dropdown, update the value
                case "dropdown":
                  populatedAdjustments[key.replace(/(_min|_max)$/, "")].value =
                    value;
                  break;
                default:
                  // Case of having minimum and maximum values
                  // first check if we are looking at min value or a maximum value
                  const isMin = key.includes("_min");
                  // check for suffix
                  const includesSuffix =
                    !!populatedAdjustments[key.replace(/(_min|_max)$/, "")][
                      isMin ? "minValue" : "maxValue"
                    ]?.suffix;
                  const includesSign =
                    !!populatedAdjustments[key.replace(/(_min|_max)$/, "")][
                      isMin ? "minValue" : "maxValue"
                    ]?.sign;
                  // if suffix convert money value to money with suffix else return money value
                  if (includesSuffix) {
                    const { value: newValue, suffix: newSuffix } =
                      convertMoneyToMoneySuffix(value);

                    populatedAdjustments[key.replace(/(_min|_max)$/, "")][
                      isMin ? "minValue" : "maxValue"
                    ].value = includesSign ? Math.abs(newValue) : newValue;
                    populatedAdjustments[key.replace(/(_min|_max)$/, "")][
                      isMin ? "minValue" : "maxValue"
                    ].suffix = newSuffix;
                    if (includesSign) {
                      populatedAdjustments[key.replace(/(_min|_max)$/, "")][
                        isMin ? "minValue" : "maxValue"
                      ].sign = value < 0 ? "-" : "+";
                    }
                  } else {
                    populatedAdjustments[key.replace(/(_min|_max)$/, "")][
                      isMin ? "minValue" : "maxValue"
                    ].value = includesSign ? Math.abs(value) : value;
                    if (includesSign) {
                      populatedAdjustments[key.replace(/(_min|_max)$/, "")][
                        isMin ? "minValue" : "maxValue"
                      ].sign = value < 0 ? "-" : "+";
                    }
                  }
                  break;
              }
            } else if (key === "name") {
              setScreenerTitle(value);
            }
          });
          setAdjustments(populatedAdjustments);
          type !== "edit" && setType("edit");
        } else {
          type !== "error" && setType("error");
        }
      };
      populateAdjustments();
    } else {
      type !== "create" && setType("create");
    }
  }, [location?.state?.reference]);

  const getAdjustmentsFiltered = () => {
    const adjustmentsFiltered = {};
    let adjustmentsFilteredError = false;
    for (const [adjustment, adjustmentValue] of Object.entries(adjustments)) {
      switch (adjustmentValue.type) {
        case "dropdown":
          if (adjustmentValue.value !== "") {
            adjustmentsFiltered[adjustment] = adjustmentValue.value;
          }
          break;
        default:
          let [minValue, maxValue] = [
            adjustmentValue.minValue,
            adjustmentValue.maxValue,
          ];
          if (minValue.value || maxValue.value) {
            if (!isNaN(minValue.value)) {
              adjustmentsFiltered[adjustment + "_min"] = minValue.value
                ? minValue?.suffix
                  ? Number(
                      `${
                        minValue?.sign === "-" ? "-" : ""
                      }${convertMoneySuffixToMoney(
                        minValue.value,
                        minValue.suffix
                      )}`
                    )
                  : Number(
                      `${minValue?.sign === "-" ? "-" : ""}${minValue.value}`
                    )
                : Number(adjustmentValue.min);
            } else {
              adjustmentsFilteredError = `Invalid value for minimum ${adjustmentValue.titleCase}`;
            }
            if (!isNaN(maxValue.value)) {
              adjustmentsFiltered[adjustment + "_max"] = maxValue.value
                ? maxValue?.suffix
                  ? Number(
                      `${
                        maxValue?.sign === "-" ? "-" : ""
                      }${convertMoneySuffixToMoney(
                        maxValue.value,
                        maxValue.suffix
                      )}`
                    )
                  : Number(
                      `${maxValue?.sign === "-" ? "-" : ""}${maxValue.value}`
                    )
                : Number(adjustmentValue.max);
            } else {
              adjustmentsFilteredError = `Invalid value for maximum ${adjustmentValue.titleCase}`;
            }
          }
          break;
      }
    }
    return { adjustmentsFiltered, adjustmentsFilteredError };
  };

  const handleScreenerSave = async () => {
    setIsSubmitting(true);
    if (adjustments === defaultScreenerAdjustments) {
      setError(
        "No changes detected. Please modify the values before creating a screener."
      );
      setIsSubmitting(false);
      return;
    }

    const { adjustmentsFiltered, adjustmentsFilteredError } =
      getAdjustmentsFiltered();

    if (adjustmentsFilteredError) {
      setError(adjustmentsFilteredError);
      setIsSubmitting(false);
      return;
    }

    if (!screenerTitle) {
      setError("Please provide a name for your screener before proceeding.");
      setIsSubmitting(false);
      return;
    }

    const res = await saveScreener({
      ...adjustmentsFiltered,
      new_name: screenerTitle,
      old_name: type === "create" ? null : location.state?.reference,
    });

    if (res?.status === "success") {
      navigate("/screeners");
    } else {
      setError(
        res?.message || "Something unexpected happened. Please try again."
      );
    }
    setIsSubmitting(false);
  };

  const handleCompanyClick = async (cik) => {
    financialStore.updateFinancialData(null);
    financialStore.toggleIsLoading();
    navigate(`/${cik}/financials`);
    const data = await fetchFinancials(cik);
    financialStore.updateFinancialData(data);
    financialStore.toggleIsLoading();
  };

  const handleCompanyFetch = async (reset = false) => {
    reset && setEmptyPage(false);
    if (adjustments === defaultScreenerAdjustments) {
      setError(
        "No changes detected. Please modify the values before displaying results."
      );
      return;
    }
    if (!fetchingCompanies && !emptyPage) {
      setFetchingCompanies(true);
      const { adjustmentsFiltered, adjustmentsFilteredError } =
        getAdjustmentsFiltered();
      if (
        Object.keys(adjustmentsFiltered).length !== 0 &&
        !adjustmentsFilteredError
      ) {
        const companies = await getScreenerCompanies(
          adjustmentsFiltered,
          reset ? 1 : page + 1
        );
        if (Array.isArray(companies) && companies?.length) {
          setCompanyResults((prev) =>
            // if reset is true, update results to be newest response, else if previous company results is array meaning not null append it else replace null with newest response
            reset
              ? companies
              : Array.isArray(prev)
              ? [...prev, ...companies]
              : companies
          );
          setPage((prev) => (reset ? 1 : prev + 1));
        } else {
          // if case of empty array set error message to correlate to empty array else return the error message
          if (companies?.length === 0) {
            setError(
              !companyResults?.length
                ? "No companies found!"
                : "That's it, no more companies match the selected filters."
            );
            // only update company results if user resets results, else keep the previous company results
            reset && setCompanyResults([]);
            setEmptyPage(true);
          } else {
            setError(
              companies?.message ||
                "Something unexpected happened when fetching for companies."
            );
          }
        }
      } else {
        if (adjustmentsFilteredError) {
          setError(adjustmentsFilteredError);
        }
      }
      setFetchingCompanies(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenerContainerHeight(window.innerHeight - 142);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Reload site? Changes you made may not be saved.";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <LogoWithMenuToggle navigationBarStore={navigationBarStore} />
      <div
        className="mt-[31px] medium-large:mt-[78px]  overflow-visible"
        style={{ height: screenerContainerHeight }}
      >
        {type !== "edit" && type !== "create" ? (
          type === "error" ? (
            <div className="loading-page-container flex flex-col">
              <h1 className="text-salmon font-bold text-8xl">404</h1>
              <p>
                The screener you're trying to access may no longer be available.
              </p>
            </div>
          ) : (
            <div className="loading-page-container">
              <LoadingSpinner />
            </div>
          )
        ) : (
          <>
            <h1 className="text-2xl mb-4 font-bold text-start">
              {type === "create" ? "Create a screener" : "Update your screener"}
            </h1>
            <div className="flex flex-col lg:grid grid-cols-10 items-start gap-6 h-screener-full-height-offset">
              <main className="col-span-10 h-fit lg:h-full w-full lg:col-span-6 xl:col-span-7 2xl:col-span-8 flex flex-col gap-6">
                <section className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 items-center gap-x-6 gap-y-4">
                  {error && (
                    <span className="col-span-2 sm:col-span-3 lg:col-span-2 xl:col-span-3 flex justify-center">
                      <Alert
                        type="error"
                        message={error}
                        clearMessage={() => setError("")}
                        duration={2000}
                      />
                    </span>
                  )}
                  <button
                    className={`w-full h-full ${
                      screenerType === "General"
                        ? "bg-lavender-primary font-semibold text-white-primary"
                        : "bg-lavender-smTint"
                    } rounded-2xl px-4 py-2 font-medium font-primary transition-all`}
                    onClick={() => setScreenerType("General")}
                  >
                    General
                  </button>
                  <button
                    className={`w-full h-full ${
                      screenerType === "Statistics"
                        ? "bg-lavender-primary font-semibold text-white-primary"
                        : "bg-lavender-smTint"
                    } rounded-2xl px-4 py-2 font-medium font-primary transition-all`}
                    onClick={() => setScreenerType("Statistics")}
                  >
                    Statistics (TTM)
                  </button>
                  <button
                    className={`w-full h-full col-span-2 sm:col-span-1 lg:col-span-2 xl:col-span-1 ${
                      screenerType === "Fundamentals"
                        ? "bg-lavender-primary font-semibold text-white-primary"
                        : "bg-lavender-smTint"
                    } rounded-2xl px-4 py-2 font-medium font-primary transition-all`}
                    onClick={() => setScreenerType("Fundamentals")}
                  >
                    Fundamentals (TTM)
                  </button>
                </section>
                <article
                  className={`rounded-2xl h-fit p-4 bg-white-xsShade grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-y-4 gap-x-6`}
                >
                  <ScreenerAdjustments
                    adjustments={adjustments}
                    setAdjustments={setAdjustments}
                    screenerType={screenerType}
                  />
                </article>
                <div className="flex xl:flex-row flex-col justify-between items-center">
                  <p className="text-start text-black-mdTint w-full xl:w-fit">
                    All values are in USD.
                  </p>
                  <div className="lg:w-fit w-full ml-auto bg-white-xsShade rounded-2xl  h-fit flex items-center">
                    <input
                      type="text"
                      className="lg:w-fit w-full px-4 py-2 text-base"
                      placeholder="Screener title:"
                      value={screenerTitle}
                      onChange={(e) => setScreenerTitle(e.target.value)}
                    />
                    <button
                      className="mx-auto bg-lavender-primary text-white-primary px-4 py-2 font-primary font-semibold rounded-2xl disabled:bg-lavender-primary/50 disabled:cursor-not-allowed"
                      onClick={handleScreenerSave}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <LoadingSpinner
                          size="size-4"
                          className="text-white-primary border-2"
                        />
                      ) : type === "create" ? (
                        "Create"
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </main>
              <aside className="col-span-10 lg:col-span-4 xl:col-span-3 2xl:col-span-2 h-full w-full bg-white-xsShade rounded-2xl ">
                <div className="flex justify-between items-center mb-b p-4">
                  <h2 className="text-start text-base font-semibold font-primary">
                    Results found:
                  </h2>
                  <button
                    className="text-salmon cursor-pointer disabled:hidden"
                    onClick={() => {
                      handleCompanyFetch(true);
                    }}
                    disabled={fetchingCompanies}
                  >
                    <GrPowerReset />
                  </button>
                </div>
                <div
                  className="overflow-auto px-4 pt-2 pb-4"
                  style={{ height: "calc(100% - 56px)" }}
                  onScroll={(e) => {
                    if (
                      e.target.scrollHeight -
                        (e.target.clientHeight + e.target.scrollTop) <
                        100 &&
                      !fetchingCompanies &&
                      !emptyPage
                    ) {
                      handleCompanyFetch();
                    }
                  }}
                >
                  {companyResults?.length ? (
                    companyResults.map((result, index) => (
                      <article
                        className="p-4 rounded-2xl bg-white-smShade flex items-center gap-3 mb-4 cursor-pointer transition-all duration-300 hover:bg-white-mdShade"
                        key={index}
                        onClick={() => handleCompanyClick(result.cik)}
                      >
                        <img
                          src={result.logo}
                          alt={result.logo}
                          className="h-8 w-8"
                        />
                        <h3 className="text-base font-semibold font-primary">
                          {result.ticker}
                        </h3>
                      </article>
                    ))
                  ) : companyResults?.length === 0 && !fetchingCompanies ? (
                    <p className="text-black-mdTint">No companies found!</p>
                  ) : (
                    !fetchingCompanies && (
                      <p className="text-black-mdTint">
                        Please refresh to display companies
                      </p>
                    )
                  )}
                  {fetchingCompanies && (
                    <span className="w-full">
                      <LoadingSpinner size="size-4" />
                    </span>
                  )}
                </div>
              </aside>
            </div>
          </>
        )}
      </div>
    </>
  );
});

export default ScreenerSetup;
