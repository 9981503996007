// src/components/UserSettings.js
import React from "react";
import "../styles/usersettings.css";
import { useNavigate } from "react-router-dom";

const UserSettings = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/login");
  };

  return (
    <div className="user-settings-container">
      <h2 className="user-title">User Settings</h2>

      <div className="content-container">
        <div className="content-box-1">
          <div className="button-row">My Profile</div>
          <div className="button-row">Subscriptions</div>
          <div className="button-row">Delete Account</div>
        </div>
        <div className="content-box-2">
          <span>User Name</span>
          <span>First Joined: mm/dd/yyyy</span>
          <div className="button-row logout" onClick={handleLogout}>
            Logout
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
