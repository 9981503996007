import React, { useEffect, useState } from "react";
import { fetchFinancials, getRecentFiles } from "../services/api";
import LoadingSpinner from "./LoadingSpinner";
import { getHexCode } from "../constants/cikHexcodes";
import LogoWithMenuToggle from "./LogoWithMenuToggle";
import { useNavigate, useOutletContext } from "react-router-dom";
import { observer } from "mobx-react";
import financialStore from "../stores/financialStore";

const RecentlyViewed = observer(() => {
  const [recentlyViewed, setRecentlyViewed] = useState(null);
  const [navigationBarStore] = useOutletContext();
  const navigate = useNavigate();

  const handleRecentlyViewedClick = async (recentlyViewedFiling) => {
    let baseUrl = "https://app.unlevered.ai";
    financialStore.updateFinancialData(null);
    financialStore.toggleIsLoading();
    navigate(recentlyViewedFiling?.url?.replace(baseUrl, "") || "#");
    const data = await fetchFinancials(recentlyViewedFiling.cik);
    financialStore.updateFinancialData(data);
    financialStore.toggleIsLoading();
  };

  useEffect(() => {
    const fetchData = async () => {
      const recentlyViewedData = await getRecentFiles();
      if (recentlyViewedData?.recent_files?.length) {
        setRecentlyViewed(recentlyViewedData.recent_files);
      } else {
        setRecentlyViewed([]);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <LogoWithMenuToggle navigationBarStore={navigationBarStore} />
      <div className="mt-[31px] medium-large:mt-[78px] mb-2">
        <h1 className="text-2xl font-bold text-start mb-2">Recently Viewed</h1>
        <div>
          {!recentlyViewed ? (
            <div className="loading-page-container">
              <LoadingSpinner />
            </div>
          ) : !recentlyViewed.length ? (
            <div className="loading-page-container">
              <h3 className="text-black-mdTint">No recently viewed filings.</h3>
            </div>
          ) : (
            <div className="w-full h-full overflow-auto">
              <table>
                <tbody>
                  {recentlyViewed.map((filing, index) => (
                    <tr
                      className="hover:bg-lavender-smTint"
                      key={index}
                      onClick={() => handleRecentlyViewedClick(filing)}
                    >
                      <td
                        className={`py-3 px-4 cursor-pointer gap-4 space-x-4`}
                      >
                        <span
                          className="table-pill text-center"
                          style={{
                            backgroundColor: getHexCode(filing?.cik),
                          }}
                        >
                          {filing?.ticker || "-"}
                        </span>
                      </td>
                      <td className={`py-3 px-4 cursor-pointer`}>
                        {filing?.title || "-"}
                      </td>
                      <td className={`py-3 px-4 cursor-pointer`}>
                        {filing?.date || "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default RecentlyViewed;
