import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchEarningsTranscript,
  fetchEarningsTranscriptSummary,
  fetchFinancials,
} from "../services/api";
import HeaderNew from "./HeaderNew";
import SideBar from "./SideBar";
import "../styles/documents.css";
import EarningsDetailContent from "./EarningsDetailContent";
import { observer } from "mobx-react";
import financialStore from "../stores/financialStore";
import navigationBarStore from "../stores/navigationBarStore";

const EarningsDetailNew = observer(() => {
  const { cik, accessionNo } = useParams();
  const [earningsTranscript, setEarningsTranscript] = useState(null);
  const [earningsTranscriptSummary, setEarningsTranscriptSummary] =
    useState(null);
  const navRef = useRef();

  useEffect(() => {
    const getEarningsTranscript = async () => {
      const earningsTranscriptResponse = await fetchEarningsTranscript(
        accessionNo
      );
      if (earningsTranscriptResponse) {
        setEarningsTranscript(earningsTranscriptResponse);
        if (!financialStore.financialData) {
          financialStore.toggleIsLoading();
          const data = await fetchFinancials(earningsTranscriptResponse.cik);
          financialStore.updateFinancialData(data);
          financialStore.toggleIsLoading();
        }
        let earningsTranscriptSummaryResponse;
        for (let i = 0; i < 5; i++) {
          earningsTranscriptSummaryResponse =
            await fetchEarningsTranscriptSummary(accessionNo);
          if (earningsTranscriptSummaryResponse.data) {
            break;
          }
          await new Promise((resolve) => setTimeout(resolve, 5000));
        }
        setEarningsTranscriptSummary(earningsTranscriptSummaryResponse);
      }
    };
    getEarningsTranscript();
  }, [accessionNo]);

  const refetchEarningsTranscript = async () => {
    setEarningsTranscript(null);
    const earningsTranscriptResponse = await fetchEarningsTranscript(
      accessionNo
    );
    setEarningsTranscript(earningsTranscriptResponse);
  };

  const refetchEarningsTranscriptSummary = async () => {
    setEarningsTranscriptSummary(null);
    let earningsTranscriptSummaryResponse;
    for (let i = 0; i < 5; i++) {
      earningsTranscriptSummaryResponse = await fetchEarningsTranscriptSummary(
        accessionNo
      );
      if (earningsTranscriptSummaryResponse.data) {
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
    setEarningsTranscriptSummary(earningsTranscriptSummaryResponse);
  };

  useEffect(() => {
    // Handle clicking outside the dropdown contents to close dropdown
    const handleClickOutsideDropdown = (e) => {
      if (navRef.current && !navRef.current.contains(e.target)) {
        navigationBarStore.setIsOpen(false); // Close the dropdown
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDropdown);

    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  return (
    <>
      <nav
        ref={navRef}
        className={`fixed h-[100dvh] top-0 z-40 flex transition-all duration-300 medium-large:left-0 ${
          !navigationBarStore.isOpen ? "-left-full" : "left-0"
        }`}
      >
        <HeaderNew active="filings" />
        <SideBar
          navigationLinks={
            earningsTranscript
              ? earningsTranscript?.content?.map((transcript, index) => ({
                  label: transcript.speaker
                    .split(" ")
                    .map((part, index) => (index === 1 ? part[0] + "." : part))
                    .join(" "),
                  link: `/${cik}/earnings/${earningsTranscript.accessionNo}#${index}`,
                  isFragment: true,
                  subLinks: [],
                  exact: true,
                  prefix: (
                    <div
                      className={`size-6 text-[10px] p-2 rounded-lg ${
                        transcript.speaker === "Operator"
                          ? "bg-peach"
                          : "bg-coral"
                      } flex items-center justify-center font-medium`}
                    >
                      {transcript.speaker
                        .split(" ")
                        .map((word) => word[0])
                        .slice(0, 2)
                        .join("")}
                    </div>
                  ),
                }))
              : []
          }
          financialStore={financialStore ? financialStore : null}
          isFragment={true}
        />
      </nav>
      <div className={`medium-large:ml-[320px] p-8 overflow-hidden h-[100dvh]`}>
        <EarningsDetailContent
          earningsTranscript={earningsTranscript}
          earningsTranscriptSummary={earningsTranscriptSummary}
          refetchEarningsTranscript={refetchEarningsTranscript}
          refetchEarningsTranscriptSummary={refetchEarningsTranscriptSummary}
        />
      </div>
    </>
  );
});

export default EarningsDetailNew;
