import { makeAutoObservable } from "mobx";
import { fetchFilings } from "../services/api";

class ProxiesStore {
  proxiesData = [];
  page = 1;
  total = 0;
  filter = { year: "", month: "", formType: "" };
  appliedFilter = { year: "", month: "", formType: "" };
  loading = false;
  paginating = false;
  hasMoreData = true;
  cik = "";
  currentlyOpenedFilter = ""; // Track which filter dropdown is open

  constructor() {
    makeAutoObservable(this);
  }

  setCik(cik) {
    this.cik = cik;
    this.resetState();
  }

  resetState() {
    this.proxiesData = [];
    this.page = 1;
    this.total = 0;
    this.filter = { year: "", month: "", formType: "" };
    this.appliedFilter = { year: "", month: "", formType: "" };
    this.currentlyOpenedFilter = "";
    this.hasMoreData = true;
    this.fetchDefaultData();
  }

  setCurrentlyOpenedFilter(name) {
    this.currentlyOpenedFilter = name;
  }

  async fetchDefaultData() {
    this.loading = true;
    try {
      const result = await fetchFilings(this.cik, "proxies", 1, "", "", "");
      const { data, total } = result || { data: [], total: 0 };
      this.proxiesData = data;
      this.total = total;
      this.hasMoreData = data.length < total;
    } catch (error) {
      console.error("Error fetching default proxies data:", error);
      this.proxiesData = [];
      this.total = 0;
      this.hasMoreData = false;
    } finally {
      this.loading = false;
    }
  }

  async handleFilterChange(name, value) {
    this.loading = true;
    const updatedFilter = { ...this.filter, [name]: value };
    if (name === "year" && value === "") {
      updatedFilter.month = ""; // Reset month if year is cleared
    }
    this.filter = updatedFilter;
    this.page = 1;
    this.appliedFilter = updatedFilter;

    try {
      const proxies = await fetchFilings(
        this.cik,
        "proxies",
        1,
        updatedFilter.year,
        updatedFilter.month,
        updatedFilter.formType
      );
      if (proxies.data) {
        this.proxiesData = proxies.data;
        this.total = proxies.total;
        this.hasMoreData = proxies.data.length < proxies.total;
      } else {
        this.proxiesData = [];
        this.total = 0;
        this.hasMoreData = false;
      }
    } catch (error) {
      console.error("Error fetching filtered proxies data:", error);
      this.proxiesData = [];
      this.total = 0;
      this.hasMoreData = false;
    } finally {
      this.loading = false;
    }
  }

  async loadMore() {
    this.paginating = true;
    const nextPage = this.page + 1;
    const { year, month, formType } = this.appliedFilter;
    try {
      const proxies = await fetchFilings(
        this.cik,
        "proxies",
        nextPage,
        year,
        month,
        formType
      );
      if (proxies.data && proxies.data.length > 0) {
        this.proxiesData = [...this.proxiesData, ...proxies.data];
        this.page = nextPage;
        this.hasMoreData = proxies.data.length < proxies.total;
      } else {
        this.hasMoreData = false;
      }
    } catch (error) {
      console.error("Error loading more proxies data:", error);
      this.hasMoreData = false;
    } finally {
      this.paginating = false;
    }
  }
}

const proxiesStore = new ProxiesStore();
export default proxiesStore;
