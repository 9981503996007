import { makeAutoObservable } from "mobx";
import { fetchFilings } from "../services/api";

class FinancialsStore {
  filingsData = [];
  page = 1;
  total = 0;
  filter = { year: "", month: "", formType: "" };
  appliedFilter = { year: "", month: "", formType: "" };
  loading = false;
  paginating = false;
  hasMoreData = true;
  currentlyOpenedFilter = "";
  cik = "";

  constructor() {
    makeAutoObservable(this);
  }

  setCik(cik) {
    this.cik = cik;
    this.resetState();
  }

  resetState() {
    this.filingsData = [];
    this.page = 1;
    this.total = 0;
    this.filter = { year: "", month: "", formType: "" };
    this.appliedFilter = { year: "", month: "", formType: "" };
    this.currentlyOpenedFilter = "";
    this.hasMoreData = true;
    this.fetchFilingsData();
  }

  setCurrentlyOpenedFilter(name) {
    this.currentlyOpenedFilter = name;
  }

  async fetchFilingsData() {
    this.loading = true;
    let combinedData = [];
    let total = 0; // Define total outside the loop
    try {
      for (let i = 1; i <= this.page; i++) {
        const filings = await fetchFilings(
          this.cik,
          "financials",
          i,
          this.appliedFilter.year,
          this.appliedFilter.month,
          this.appliedFilter.formType
        );
        if (filings && filings.data) {
          combinedData = [...combinedData, ...filings.data];
          total = filings.total; // Track the total number of filings
        }
      }
      this.filingsData = combinedData;
      this.total = combinedData.length;
      this.hasMoreData = combinedData.length < total; // Use the tracked total
    } catch (error) {
      console.error("Error fetching filings data:", error);
      this.filingsData = [];
      this.total = 0;
      this.hasMoreData = false;
    } finally {
      this.loading = false;
    }
  }

  async handleFilterChange(name, value) {
    this.loading = true;
    const updatedFilter = { ...this.filter, [name]: value };
    if (name === "year" && value === "") {
      updatedFilter.month = ""; // Reset month if year is cleared
    }
    this.filter = updatedFilter;
    this.page = 1;
    this.appliedFilter = updatedFilter;

    try {
      const filings = await fetchFilings(
        this.cik,
        "financials",
        1,
        this.appliedFilter.year,
        this.appliedFilter.month,
        this.appliedFilter.formType
      );
      if (filings && filings.data) {
        this.filingsData = filings.data;
        this.total = filings.total;
        this.hasMoreData = filings.data.length < filings.total;
      } else {
        this.filingsData = [];
        this.total = 0;
        this.hasMoreData = false;
      }
    } catch (error) {
      console.error("Error fetching filtered filings data:", error);
      this.filingsData = [];
      this.total = 0;
      this.hasMoreData = false;
    } finally {
      this.loading = false;
    }
  }

  async loadMore() {
    this.paginating = true;
    const nextPage = this.page + 1;
    try {
      const filings = await fetchFilings(
        this.cik,
        "financials",
        nextPage,
        this.appliedFilter.year,
        this.appliedFilter.month,
        this.appliedFilter.formType
      );
      if (filings && filings.data.length > 0) {
        this.filingsData = [...this.filingsData, ...filings.data];
        this.page = nextPage;
        this.hasMoreData = filings.data.length < filings.total;
      } else {
        this.hasMoreData = false;
      }
    } catch (error) {
      console.error("Error loading more filings data:", error);
      this.hasMoreData = false;
    } finally {
      this.paginating = false;
    }
  }
}

const financialsStore = new FinancialsStore();
export default financialsStore;
