import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import allFilingsStore from "../stores/allFilingsStore";
import FilterButton from "./FilterButton";
import SearchableFilterButton from "./SearchableFilterButton";
import "../styles/allfinancials.css"; // Assuming this CSS file has the styles
import LoadingSpinner from "./LoadingSpinner";
import Table from "./Table";
import { formTypes } from "../constants";
import { getHexCode } from "../constants/filingHexcodes";
import { observer } from "mobx-react";

const AllFinancials = observer(({ financialData }) => {
  const { cik } = useParams();
  const dropdownsRef = useRef(null);
  const [showPrompt, setShowPrompt] = useState(false); // Add state for showing prompt

  // Handle CIK changes
  useEffect(() => {
    if (allFilingsStore.cik !== cik) {
      allFilingsStore.setCik(cik);
    }

    const handleClickOutsideDropdown = (e) => {
      if (dropdownsRef.current && !dropdownsRef.current.contains(e.target)) {
        allFilingsStore.setCurrentlyOpenedFilter(""); // Close filter dropdown
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, [cik]);

  const handleFilterChange = async ({ name, value }) => {
    await allFilingsStore.handleFilterChange(name, value);
  };

  const loadMore = async () => {
    await allFilingsStore.loadMore();
  };

  const years = Array.from(
    { length: 2025 - financialData?.start_year },
    (_, i) => financialData?.start_year + i
  );
  const months = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  ).slice(
    allFilingsStore.filter.year === financialData?.start_year
      ? financialData?.start_month - 1
      : 0,
    allFilingsStore.filter.year === new Date().getFullYear()
      ? new Date().getMonth() + 1
      : 12
  );

  // Function to toggle the currently opened filter
  const toggleCurrentlyOpenedFilter = (filterName) => {
    if (allFilingsStore.currentlyOpenedFilter === filterName) {
      allFilingsStore.setCurrentlyOpenedFilter(""); // Close the dropdown if it's already open
    } else {
      allFilingsStore.setCurrentlyOpenedFilter(filterName); // Open the dropdown
    }
  };

  return (
    <>
      <div className="flex items-center justify-between mt-[31px] mb-2 flex-wrap gap-1">
        <h3 className="text-black-primary text-lg md:text-2xl font-semibold text-left">
          All Filings
        </h3>
        <div
          className="flex items-start md:gap-4 gap-2 flex-wrap"
          ref={dropdownsRef}
        >
          <FilterButton
            name="year"
            values={years.reverse()}
            value={allFilingsStore.filter.year}
            clearValue={async (e) =>
              handleFilterChange({ name: "year", value: "" })
            }
            handleFilterChange={handleFilterChange}
            currentlyOpenedFilter={allFilingsStore.currentlyOpenedFilter}
            setCurrentlyOpenedFilter={
              () => toggleCurrentlyOpenedFilter("year") // Use toggle function
            }
          />
          <FilterButton
            name="month"
            values={months.reverse()}
            value={allFilingsStore.filter.month}
            clearValue={async (e) =>
              handleFilterChange({ name: "month", value: "" })
            }
            handleFilterChange={handleFilterChange}
            currentlyOpenedFilter={allFilingsStore.currentlyOpenedFilter}
            setCurrentlyOpenedFilter={
              () => toggleCurrentlyOpenedFilter("month") // Use toggle function
            }
            disabled={!allFilingsStore.filter.year} // Disable if no year selected
            showPrompt={showPrompt} // Pass showPrompt
            setShowPrompt={setShowPrompt} // Pass setShowPrompt
          />
          <SearchableFilterButton
            name="formType"
            values={formTypes}
            value={allFilingsStore.filter.formType}
            clearValue={async (e) =>
              handleFilterChange({ name: "formType", value: "" })
            }
            handleFilterChange={handleFilterChange}
            currentlyOpenedFilter={allFilingsStore.currentlyOpenedFilter}
            setCurrentlyOpenedFilter={
              () => toggleCurrentlyOpenedFilter("formType") // Use toggle function
            }
          />
        </div>
      </div>
      {allFilingsStore.loading ? (
        <div className="loading-page-container">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Table
            data={allFilingsStore.allFilingsData.map((filingData) => ({
              ...filingData,
              url: `/${cik}/documents/${filingData.accessionNo}?filing=${filingData.form_type}`,
              pillCN: getHexCode(filingData.form_type) || "#ebf38c",
              title:
                filingData.title.split(" ").length > 8
                  ? filingData.title.split(" ").slice(0, 8).join(" ") + "..."
                  : filingData.title.split(" ").slice(0, 8).join(" "),
            }))}
            references={[
              {
                reference: "form_type",
                pill: true,
              },
              { reference: "title" },
              { reference: "filingDate", additionalCN: "text-right" },
            ]}
          />
          {allFilingsStore.hasMoreData && (
            <button
              className="all-financials-load-more cursor-pointer hover:bg-lavender-smTint transition-all disabled:cursor-not-allowed"
              onClick={loadMore}
              disabled={allFilingsStore.paginating}
            >
              <span
                className={`all-financials-load-more-btn ${
                  allFilingsStore.paginating ? "loading" : ""
                }`}
              >
                {allFilingsStore.paginating ? (
                  <LoadingSpinner size="w-4 h-4" className="border-2" />
                ) : (
                  "+ Load More"
                )}
              </span>
            </button>
          )}
        </>
      )}
    </>
  );
});

export default AllFinancials;
