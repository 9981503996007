import React from "react";
import { useOutletContext, useParams } from "react-router-dom";
import "../styles/financialData.css";
import SearchBar from "./SearchBar";
import AllFinancials from "./AllFinancials";
import LogoWithMenuToggle from "./LogoWithMenuToggle";
import { observer } from "mobx-react";

const AllFilingsPage = observer(() => {
  const { cik } = useParams(); // Get CIK from URL
  const [financialData, navigationBarStore] = useOutletContext();

  return (
    <div>
      <span className="flex items-center gap-4 mb-2">
        <LogoWithMenuToggle navigationBarStore={navigationBarStore} />
        <div className="search-bar-container medium-large:flex block justify-end w-full">
          <SearchBar />
        </div>
      </span>
      <AllFinancials cik={cik} financialData={financialData} />
    </div>
  );
});

export default AllFilingsPage;
