import { makeAutoObservable, runInAction } from "mobx";
import { fetchEarningsTranscriptsList } from "../services/api";

class EarningsStore {
  transcriptsData = [];
  year = "2024"; // default year
  loading = false;
  currentlyOpenedFilter = ""; // Proper initialization of currentlyOpenedFilter
  cik = "";
  prevCik = ""; // Store the previous cik to detect changes

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // Ensures "this" is properly bound
  }

  setCik(cik) {
    runInAction(() => {
      if (this.cik !== cik) {
        this.prevCik = this.cik; // Save the current cik to prevCik
        this.cik = cik; // Set the new cik
        this.resetState(); // Reset state when a new cik is set
        this.fetchTranscriptsData();
      } else {
        this.cik = cik; // If the cik hasn't changed, just set it
      }
    });
  }

  setYear(value) {
    runInAction(() => {
      this.year = value;
      this.fetchTranscriptsData();
    });
  }

  setCurrentlyOpenedFilter(value) {
    runInAction(() => {
      this.currentlyOpenedFilter = value;
    });
  }

  toggleFilter(name) {
    runInAction(() => {
      this.currentlyOpenedFilter =
        this.currentlyOpenedFilter === name ? "" : name;
    });
  }

  resetState() {
    runInAction(() => {
      this.transcriptsData = [];
      this.year = "2024"; // Reset to default year
      this.currentlyOpenedFilter = ""; // Clear any filter that's open
    });
  }

  async fetchTranscriptsData() {
    if (!this.cik) return;
    this.loading = true;

    try {
      const data = await fetchEarningsTranscriptsList(this.cik, this.year);
      runInAction(() => {
        this.transcriptsData = data ? Object.values(data) : [];
      });
    } catch (error) {
      console.error("Fetching earnings transcripts failed:", error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

const earningsStore = new EarningsStore();
export default earningsStore;
