import React from "react";

export default function ComparePopup() {
  return (
    <div className="bg-white-primary drop-shadow-subtle-soft rounded-2xl p-4 absolute top-full sm:w-[420px] w-fit right-0">
      <h2 className="font-primary text-lg font-semibold mb-1">
        Compare any two filings, effortlessly:
      </h2>
      <p className="text-start">
        Americas net sales <mark className="green">were relatively flat</mark>{" "}
        <mark className="red">increased</mark> during the{" "}
        <mark className="green">second</mark> <mark className="red">third</mark>{" "}
        quarter of 2024 compared to the <mark className="green">second</mark>{" "}
        <mark className="red">third</mark> quarter of{" "}
        <mark className="red">
          2023, with lower net sales of iPhone and iPad offset by
        </mark>{" "}
        <mark className="green">2023 due primarily to</mark> higher net sales of{" "}
        <mark className="red">Services.</mark>{" "}
        <mark className="green">Services and iPad.</mark>
      </p>
      <h2 className="font-primary text-lg font-semibold mb-1">
        Coming November 2024
      </h2>
      <button className="bg-lavender-primary text-white-primary font-primary py-2 px-4 font-medium rounded-2xl hover:bg-lavender-primary/90 text-sm m-auto">
        Learn More
      </button>
    </div>
  );
}
