import React, { useEffect, useState } from "react";
import { getRecentFiles } from "../services/api";
import { Navigate } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";

export default function MostRecentFiling() {
  const [redirect, setRedirect] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const recentlyViewedData = await getRecentFiles();
      if (recentlyViewedData?.recent_files?.length) {
        setRedirect(
          `/${recentlyViewedData.recent_files[0].cik || "1045810"}/financials`
        );
      } else {
        setRedirect("/1045810/financials");
      }
    };
    fetchData();
  }, []);
  return redirect ? (
    <Navigate to={redirect} replace />
  ) : (
    <div className="flex justify-center items-center h-[96dvh]">
      <LoadingSpinner />
    </div>
  );
}
