import React from "react";
import SearchBar from "./SearchBar";
import RegistrationsCard from "./RegistrationsCard";
import { useOutletContext, useParams } from "react-router-dom";
import LogoWithMenuToggle from "./LogoWithMenuToggle";
import { observer } from "mobx-react";

const RegistrationsPage = observer(() => {
  const { cik } = useParams();
  const [financialData, navigationBarStore] = useOutletContext();

  return (
    <>
      <div>
        <span className="flex items-center gap-4 mb-2">
          <LogoWithMenuToggle navigationBarStore={navigationBarStore} />
          <div className="search-bar-container medium-large:flex block justify-end w-full">
            <SearchBar />
          </div>
        </span>
        <RegistrationsCard cik={cik} financialData={financialData} />
      </div>
    </>
  );
});

export default RegistrationsPage;
